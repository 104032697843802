import React, { useState, useLayoutEffect, useCallback } from "react";
import {
  FormHelperText,
  TextField,
  Autocomplete,
  Button,
  Box,
  Tooltip,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { parseFieldError } from "../../util/helpers";
import Icon from "../atoms/Icon";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "+ .options-list-wrap": {
    borderRadius: 8,
    overflow: "hidden",
    marginTop: theme.spacing(-1.5),
    marginBottom: theme.spacing(2),
    wordBreak: "break-all",

    ".MuiPaper-root": {
      background: "#F5F5F5",
    },

    ".MuiAutocomplete-option": {
      display: "flex",
      justifyContent: "space-between",
      color: "rgba(0, 0, 0, 0.6)",
    },
  },

  ".MuiOutlinedInput-input": {
    fontSize: 14,
  },

  ".MuiChip-filledSecondary": {
    "&:hover": {
      backgroundColor: "rgba(180, 181, 174, 0.9)",
    },
    backgroundColor: "rgba(180, 181, 174, 0.8)",
    color: "rgba(0, 0, 0, 0.87)",
  },

  ".MuiInputLabel-root": {
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)",
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },

  ".MuiOutlinedInput-root": {
    borderRadius: 12,
  },

  "&:not(.solo)": {
    ".MuiOutlinedInput-root": {
      "&:not(.Mui-focused):not(:hover) .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    },
  },
}));

const filter = createFilterOptions();

const FilterMultipleText = ({
  handleInputChange,
  required,
  label,
  name,
  disabled,
  options = [],
  type = "text",
  autoFocus,
  soloInput,
}) => {
  const EMPTY_STRING = "";

  const [open, setOpen] = useState(true);
  const [selectedChipValue, setSelectedChipValue] = useState(EMPTY_STRING);
  const [selectedChipIndex, setSelectedChipIndex] = useState(null);

  useLayoutEffect(() => {
    const input = document.getElementById(name);
    autoFocus && input && input.focus();
  }, []);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const {
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext();

  const inputError = parseFieldError(name, errors);

  if (inputError) {
    document
      .getElementById(`${name}`)
      .scrollIntoView({ block: "center", behavior: "smooth" });
  }

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);
    const { inputValue } = params;

    // Suggest the creation of a new value
    const isExisting = options.some((option) => inputValue === option);

    if (inputValue !== "" && !isExisting) {
      if (type === "number" && !/^\d+$/.test(inputValue)) {
        return false;
      } else {
        filtered.unshift({
          inputValue,
          label: `${inputValue}`,
        });
      }
    }

    return filtered;
  };

  const renderOption = (props, option) => {
    return (
      <li {...props}>
        {option.label}{" "}
        <Button
          data-testid={`${name}-add-button`}
          onClick={toggleOpen}
          size="small"
          variant="contained"
        >
          Add
        </Button>
      </li>
    );
  };

  const isEditMode = () => {
    return selectedChipIndex !== null;
  };

  const resetEditMode = () => {
    setSelectedChipValue(EMPTY_STRING);
    setSelectedChipIndex(null);
  };

  const toggleEditMode = (text, index) => {
    if (isEditMode() && index === selectedChipIndex) {
      resetEditMode();
    } else {
      setSelectedChipValue(text);
      setSelectedChipIndex(index);
    }
  };

  const isEnterKeyPressed = (event) => event.key === "Enter";
  const isEscapeKeyPressed = (event) => event.key === "Escape";
  const isSelectedChipEmpty = () => selectedChipValue === EMPTY_STRING;
  const hasInputError = () => !!inputError;

  const handleChange = (e, newValue, reason, onChange) => {
    if (isSelectedChipEmpty() && isEditMode() && e.key === "Backspace") {
      return;
    }

    if (isEditMode() && reason !== "removeOption") {
      newValue[selectedChipIndex] = selectedChipValue;
      newValue.pop();
    }

    const values = [];
    newValue.forEach((item) => {
      if (item.inputValue) {
        values.push(item.inputValue);
      } else {
        values.push(item);
      }
    });

    resetEditMode();
    onChange(values);
    handleInputChange(name, values);
  };

  const onTextFieldKeyDown = (e) => {
    if (isEnterKeyPressed(e)) {
      if (hasInputError()) {
        e.stopPropagation();
      } else if (isSelectedChipEmpty() && isEditMode()) {
        setSelectedChipIndex(null);
        e.preventDefault();
      }
    } else if (isEscapeKeyPressed(e) && isEditMode()) {
      resetEditMode();
      e.preventDefault();
    }
  };

  const CustomPopper = useCallback(
    ({ children }) => {
      return (
        open && (
          <Box className="options-list-wrap">
            <Box>{children}</Box>
          </Box>
        )
      );
    },
    [open]
  );

  const handleInput = (e, target) => {
    if (type === "number" && target.length) {
      if (!/^\d+$/.test(target)) {
        setError(name, { type: "custom", message: "Should be number" });
        return;
      }
    }
    setSelectedChipValue(e.target.value);
    inputError && clearErrors(name);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
      }}
      render={({ field: { ref, onChange, ...field } }) => (
        <>
          <StyledAutocomplete
            {...field}
            className={soloInput ? "solo" : ""}
            id={name}
            freeSolo={true}
            options={options}
            fullWidth={true}
            open={open}
            inputValue={selectedChipValue}
            onOpen={toggleOpen}
            isOptionEqualToValue={() => false}
            disablePortal={true}
            disabled={disabled}
            disableClearable
            value={field.value || []}
            multiple={true}
            filterOptions={filterOptions}
            renderOption={renderOption}
            onInputChange={handleInput}
            onChange={(e, newValue, reason) =>
              handleChange(e, newValue, reason, onChange)
            }
            PopperComponent={CustomPopper}
            componentsProps={{
              paper: {
                elevation: 0,
              },
            }}
            renderInput={(params) => {
              return (
                <>
                  <TextField
                    {...params}
                    inputRef={ref}
                    fullWidth
                    sx={{ mb: 2 }}
                    required={!!required}
                    type={"text"}
                    inputMode={type === "number" ? "numeric" : "text"}
                    label={label}
                    error={!!inputError}
                    placeholder={"Type..."}
                    InputProps={{
                      ...params.InputProps,
                      onKeyDown: (e) => {
                        onTextFieldKeyDown(e);
                      },
                      "data-testid": `${name}-input`,
                    }}
                  />
                  {inputError && (
                    <FormHelperText
                      error={!!inputError}
                      sx={{ mt: -1.5, mx: 0 }}
                    >
                      {inputError}
                    </FormHelperText>
                  )}
                </>
              );
            }}
            ListboxProps={{
              style: {
                padding: 0,
              },
            }}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((text, index) => (
                <Tooltip
                  key={index}
                  title={"Click to edit"}
                  placement={"top"}
                  arrow
                >
                  <Chip
                    label={
                      <Typography
                        style={{
                          whiteSpace: "normal",
                          overflowWrap: "anywhere",
                        }}
                      >
                        {text}
                      </Typography>
                    }
                    {...getTagProps({ index })}
                    onClick={() => toggleEditMode(text, index)}
                    color={
                      index === selectedChipIndex ? "secondary" : "default"
                    }
                    variant={"filled"}
                    is-selected={(index === selectedChipIndex).toString()}
                    style={{ height: "100%" }}
                    data-testid={`${text}-chip`}
                    deleteIcon=<Icon width="20px" name="closeSmall" />
                  />
                </Tooltip>
              ))
            }
          />
        </>
      )}
    />
  );
};
export default FilterMultipleText;
