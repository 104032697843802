import React from "react";
import { IconButton, Tooltip, ClickAwayListener } from "@mui/material";
import Icon from "../atoms/Icon";

const TooltipButton = ({ title, sx }) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        open={open}
        title={title}
      >
        <IconButton
          onClick={handleTooltipOpen}
          sx={{
            mt: 1.25,
            ml: 1,
            mr: -1,
            svg: {
              width: 20,
              height: 20,
            },
            ...sx,
          }}
        >
          <Icon name="infoHollow" />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default TooltipButton;
