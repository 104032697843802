import React, { useState, useLayoutEffect } from "react";
import { Box, Typography, Link } from "@mui/material";

import sanitizeHtml from "sanitize-html";

const sanitizeHTMLKeepParagraphs = (rawHTML) => {
  return sanitizeHtml(rawHTML, { allowedTags: ["p"] });
};

const PreviewTextContent = ({ summary, text, postId }) => {
  const [showMore, setShowMore] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  const sanitizedSummary = sanitizeHTMLKeepParagraphs(summary);
  const sanitizedText = sanitizeHTMLKeepParagraphs(text);

  const readMore = () => {
    setShowMore(!showMore);
  };

  useLayoutEffect(() => {
    const text = !sanitizedSummary && document.getElementById(postId);

    if (text?.offsetHeight > 96) {
      setCollapsed(true);
    }
  }, []);

  return (
    <>
      {sanitizedSummary ? (
        <Box>
          <Typography
            display="inline"
            dangerouslySetInnerHTML={{ __html: sanitizedSummary }}
          />
          {showMore && sanitizedText && (
            <Typography dangerouslySetInnerHTML={{ __html: sanitizedText }} />
          )}
          <Typography>
            <Link
              sx={{ cursor: "pointer" }}
              underline="hover"
              onClick={readMore}
            >
              {showMore ? "Read Less" : "Read More"}
            </Link>
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{
              maxHeight: collapsed ? (showMore ? "99em" : 96) : "99em",
              overflow: "hidden",
              transition: "all .2s ease",
            }}
            dangerouslySetInnerHTML={{ __html: sanitizedText }}
            id={postId}
          />
          {collapsed && (
            <Typography>
              <Link
                sx={{ cursor: "pointer" }}
                underline="hover"
                onClick={readMore}
              >
                {showMore ? "Read Less" : "Read More"}
              </Link>
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default PreviewTextContent;
