import React from "react";
import { FormControlLabel, Switch, FormHelperText, Box } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

const FilterToggle = ({
  handleInputChange,
  required,
  label,
  name,
  hintText,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
      }}
      render={({ field: { onChange, ...field } }) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <FormControlLabel
              control={
                <Switch
                  {...field}
                  checked={field.value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleInputChange(name, e.target.checked);
                  }}
                  color="primary"
                  id={name}
                />
              }
              label={label}
              labelPlacement="top"
              htmlFor={name}
              sx={{
                mb: 2,
                ml: { xs: 0.75, md: 1 },
                alignItems: "flex-start",
                ".MuiTypography-root": {
                  pl: 1,
                  fontSize: 12,
                  fontWeight: 500,
                },
              }}
            />
            {hintText && (
              <FormHelperText
                component="div"
                sx={{ mb: 2, ml: { xs: 1.5, md: 1.5 }, mt: -1.5 }}
              >
                {hintText}
              </FormHelperText>
            )}
          </Box>
        );
      }}
    />
  );
};
export default FilterToggle;
