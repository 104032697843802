export default {
  defaultValues: {
    query: "",
  },
  // Additional values that will be added to the upstream query, but won't show up in the query builder or raw query.
  additionalHiddenQueryValues: {
    // The default sort order of the Dark Web Search API is by crawl date, ascending.
    // These parameters will ensure we show the most recent results in the dashboard.
    sort: "published",
    order: "desc",
  },
  additionalFormDefaultValues: {
    search: [],
    author: [],
    site_domain: [],
    site_type: [],
    site_name: [],
    enriched_domain_value: [],
    language: [],
    crawled_since: null,
    crawled_until: null,
    enriched_email_value: [],
    extended_network: [],
    enriched_credit_card_value: [],
    enriched_wallet_id_value: [],
    enriched_organization_value: [],
  },
  inputMap: {
    search: "text",
    author: "text",
    site_domain: "text",
    site_type: "select",
    site_name: "text",
    enriched_domain_value: "text",
    language: "text",
    crawled: "time",
    enriched_email_value: "text",
    extended_network: "select",
    enriched_credit_card_value: "text",
    enriched_wallet_id_value: "text",
    enriched_organization_value: "text",
  },
  querySettings: {
    dateInputs: [],
    selectInputs: [],
    singleDropdown: [],
    replaceKeyName: {},
    nestedObjectFields: {},
    numberInputs: [],
  },
  site_type: [
    { label: "News", value: "news" },
    { label: "Blogs", value: "blogs" },
    { label: "Discussions", value: "discussions" },
    { label: "Chat", value: "chat" },
    { label: "Market", value: "market" },
    { label: "Paste", value: "paste" },
    { label: "Datastore", value: "datastore" },
    { label: "Social Media", value: "social_media" },
    { label: "Advanced Persistent Threat", value: "ransomware_blog" },
  ],
  extended_network: [
    { label: "tor", value: "tor" },
    { label: "zeronet", value: "zeronet" },
    { label: "i2P", value: "i2P" },
    { label: "openbazaar", value: "openbazaar" },
    { label: "telegram", value: "telegram" },
    { label: "discord", value: "discord" },
    { label: "openweb", value: "openweb" },
  ],
};
