import React from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Link, Button, Card, CardActions } from "@mui/material";
import Icon from "../atoms/Icon";
import ApiCardHeader from "../molecules/ApiCardHeader";
import ActiveApiRequests from "../molecules/ActiveApiRequests";
import features from "../../constants/enabledApiFeatures";
import getApiMetadata from "../../constants/nameConstants";

const EnabledAPICard = ({ item }) => {
  let navigate = useNavigate();

  const cardData = features[item.apiName];

  const handleClick = () => {
    navigate(`/${getApiMetadata(item.apiName).productDataType}?tab=builder`);
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card
        data-testid={`${item.apiName}-enabled`}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 320,
          px: 2,
          pt: 1,
          pb: 2,
          borderRadius: "12px",
          boxShadow: "0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
          border: "4px solid rgba(114, 122, 0, 0.3)",
        }}
      >
        <ApiCardHeader name={item.apiName} />
        <ActiveApiRequests
          requestsNumber={item.requestsNumber}
          requestLimit={item.requestLimit}
          isSoftLimit={item.isSoftLimit}
          isTrial={item.isTrial}
          apiName={item.apiName}
          languages={item.languages}
          locations={item.locations}
        />

        <Link
          underline="none"
          href={`${getApiMetadata(item.apiName).productDataType}?tab=${
            getApiMetadata(item.apiName).docPath
          }`}
        >
          Documentation
        </Link>

        {!cardData.disableButton && (
          <CardActions sx={{ mt: "auto", p: 0 }}>
            <Button
              onClick={handleClick}
              sx={{ minHeight: 40, pl: 3, pr: 2.25, svg: { ml: 1 } }}
              variant="contained"
            >
              {"Search Data"}
              <Icon name="arrowForward" />
            </Button>
          </CardActions>
        )}
      </Card>
    </Grid>
  );
};

export default EnabledAPICard;
