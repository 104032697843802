import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import Sidebar from "./Sidebar";

const StyledPageLayout = styled(Box)(({ theme }) => ({
  marginLeft: 280,
  [theme.breakpoints.down("lg")]: {
    marginLeft: 0,
  },
}));

const PageLayout = ({ children }) => {
  return (
    <StyledPageLayout>
      <Sidebar />
      {children}
    </StyledPageLayout>
  );
};

export default PageLayout;
