import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Alert,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  useForm,
  useFieldArray,
  FormProvider,
  Controller,
} from "react-hook-form";
import TextInput from "../molecules/TextInput";
import request from "../../util/request";
import { DevTool } from "@hookform/devtools";
import Icon from "../atoms/Icon";
import UrlInput from "../molecules/UrlInput";
import FileInput from "../molecules/FileInput";

const SupportForm = () => {
  const defaultValues = {
    name: "",
    email: "",
    "technical-support-subject": "",
    "source-support-subject": "",
    "source-support-subject-other": "",
    message: "",
    urls: [{ url: "" }],
    attachments: "",
  };

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const {
    control,
    setValue,
    handleSubmit,
    formState,
    resetField,
    getValues,
    watch,
  } = formMethods;
  const [formSubmission, setFormSubmission] = useState({ success: null });

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "urls",
    rules: { minLength: 1 },
  });

  useEffect(() => {
    request("/api/v1/user_accounts/show").then((res) => {
      const user = res.data.data;

      setValue("name", user?.name);
      setValue("email", user?.email);
    });
  }, []);

  const resetTechnicalSupportFields = () => {
    resetField("technical-support-subject");
    resetField("message");
    resetField("attachments");
  };

  const resetSourceSupportFields = () => {
    resetField("source-support-subject");
    resetField("source-support-subject-other");
    resetField("message");
    resetField("attachments");
    remove();
    append({ url: "" });
  };

  const onSubmit = (data, e) => {
    delete data.attachments;

    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      if (key === "urls") {
        for (const url of value) {
          formData.append("urls[]", url.url);
        }
      } else {
        formData.append(key, value);
      }
    }

    for (const file of e.target.attachments.files) {
      formData.append("attachments[]", file);
    }

    request({
      method: "post",
      url: "/api/v1/support",
      data: formData,
    })
      .then((res) => {
        if (res.status == 204) {
          setFormSubmission({ success: true });
          resetTechnicalSupportFields();
          resetSourceSupportFields();
        } else {
          setFormSubmission({
            success: false,
            message: "Something went wrong. Please try again.",
          });
        }
      })
      .catch((err) => {
        const error_message =
          err.response.data.message ||
          "Something went wrong. Please try again.";

        setFormSubmission({ success: false, message: error_message });
      });
  };

  const watchSupportType = watch("support-type");
  const watchSourceSupportSubject = watch("source-support-subject");

  return (
    <FormProvider {...formMethods}>
      {formSubmission.success === true && (
        <Alert
          sx={{
            ".MuiAlert-action": {
              alignItems: "center",
            },
            mb: 4,
          }}
          action={
            <IconButton
              aria-label="close"
              size="small"
              variant="outlined"
              onClick={() => {
                setFormSubmission({ success: null });
              }}
            >
              <Icon name="close" />
            </IconButton>
          }
          severity="success"
        >
          Thank you for your message!
        </Alert>
      )}
      {formSubmission.success === false && (
        <Alert
          sx={{
            ".MuiAlert-action": {
              alignItems: "center",
            },
            mb: 4,
          }}
          action={
            <IconButton
              aria-label="close"
              size="small"
              variant="outlined"
              onClick={() => {
                setFormSubmission({ success: null });
              }}
            >
              <Icon name="close" />
            </IconButton>
          }
          severity="error"
        >
          {formSubmission.message}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)} data-testid={"support-form"}>
        <TextInput type="text" name="name" required label="Name" />
        <TextInput type="email" name="email" required label="Email" />
        <FormControl fullWidth>
          <InputLabel id={"support-type-label"} required>
            Support type
          </InputLabel>
          <Controller
            name="support-type"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="support-type"
                labelId={"support-type-label"}
                label="Support type"
                sx={{ mb: 3 }}
                value={getValues("support-type") || ""}
                defaultValue=""
                required
              >
                <MenuItem value="Technical Support">Technical Support</MenuItem>
                <MenuItem value="Source Support">Source Support</MenuItem>
              </Select>
            )}
          />
        </FormControl>
        {watchSupportType === "Technical Support" && (
          <>
            <TextInput
              type="text"
              name="technical-support-subject"
              required
              label="Subject"
            />
            <TextInput
              type="text"
              name="message"
              required
              multiline
              label="Message"
              rows="5"
            />
          </>
        )}
        {watchSupportType === "Source Support" && (
          <>
            <FormControl fullWidth>
              <InputLabel id={"source-support-subject-label"} required>
                Subject
              </InputLabel>
              <Controller
                name="source-support-subject"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    id="source-support-subject"
                    labelId={"source-support-subject-label"}
                    label="Subject"
                    sx={{ mb: 3 }}
                    value={getValues("source-support-subject") || ""}
                    defaultValue=""
                    required
                  >
                    <MenuItem value="Incomplete content">
                      Incomplete content
                    </MenuItem>
                    <MenuItem value="Too much content">
                      Too much content
                    </MenuItem>
                    <MenuItem value="Missing article/post">
                      Missing article/post
                    </MenuItem>
                    <MenuItem value="Report spam">Report spam</MenuItem>
                    <MenuItem value="Incorrect date">Incorrect date</MenuItem>
                    <MenuItem value="Other">Other...</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            {watchSourceSupportSubject == "Other" && (
              <TextInput
                type="text"
                name="source-support-subject-other"
                label="Subject"
                required
              />
            )}
            <TextInput
              type="text"
              name="message"
              required
              multiline
              label="Message"
              rows="5"
            />
            <UrlInput
              control={control}
              fields={fields}
              append={append}
              update={update}
              remove={remove}
              required={false}
            />
          </>
        )}
        {watchSupportType !== undefined && <FileInput />}
        <Typography paragraph>
          We will reply to the email stated in the form.
        </Typography>
        <Box>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            size="large"
            disabled={!formState.isValid}
          >
            Send
          </Button>
        </Box>
      </form>
      <DevTool control={control} /> {/* react-hook-form browser dev tool */}
    </FormProvider>
  );
};
export default SupportForm;
