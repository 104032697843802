import React from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, GlobalStyles } from "@mui/material";

import theme from "./util/theme";
import AuthRedirect from "./components/_functional/AuthRedirect";

import NotFound from "./pages/NotFound";

import Login from "./pages/Login";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import { AppProvider } from "./components/_functional/appContext";
import { IntercomUpdater } from "./components/_functional/intercom";

import Home from "./pages/Home";
import Profile from "./pages/Profile";
import HelpAndSupport from "./pages/HelpAndSupport";
import RequestSources from "./pages/RequestSources";
import ContactSales from "./pages/ContactSales";

import BlogsPage from "./pages/api/Blogs";
import NewsPage from "./pages/api/News";
import ForumsPage from "./pages/api/Forums";
import ReviewsPage from "./pages/api/Reviews";
import DarkWebPage from "./pages/api/DarkWeb";
import VKPage from "./pages/api/VK";

import PublicDocumentation from "./pages/PublicDocumentation";

import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";

if (typeof window !== "undefined") {
  injectStyle();
}

const routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/register",
    element: <Register />,
  },
  {
    path: "/signup",
    element: <Navigate to="/register" replace />,
  },
  {
    path: "/support",
    element: (
      <AuthRedirect>
        <HelpAndSupport />
      </AuthRedirect>
    ),
  },
  {
    path: "/",
    element: (
      <AuthRedirect>
        <Home />
      </AuthRedirect>
    ),
  },
  {
    path: "/profile",
    element: (
      <AuthRedirect>
        <Profile />
      </AuthRedirect>
    ),
  },
  {
    path: "/blogs",
    element: (
      <AuthRedirect>
        <BlogsPage />
      </AuthRedirect>
    ),
  },
  {
    path: "/news",
    element: (
      <AuthRedirect>
        <NewsPage />
      </AuthRedirect>
    ),
  },
  {
    path: "/forums",
    element: (
      <AuthRedirect>
        <ForumsPage />
      </AuthRedirect>
    ),
  },
  {
    path: "/reviews",
    element: (
      <AuthRedirect>
        <ReviewsPage />
      </AuthRedirect>
    ),
  },
  {
    path: "/dark_web",
    element: (
      <AuthRedirect>
        <DarkWebPage />
      </AuthRedirect>
    ),
  },
  {
    path: "/vk",
    element: (
      <AuthRedirect>
        <VKPage />
      </AuthRedirect>
    ),
  },
  {
    path: "/public-docs/*",
    element: <PublicDocumentation />,
  },
  {
    path: "/request-sources",
    element: (
      <AuthRedirect>
        <RequestSources />
      </AuthRedirect>
    ),
  },
  {
    path: "/contact-sales",
    element: (
      <AuthRedirect>
        <ContactSales />
      </AuthRedirect>
    ),
  },
  {
    path: "*",
    element: (
      <AuthRedirect>
        <NotFound />
      </AuthRedirect>
    ),
  },
];

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles
          styles={{
            html: {
              scrollBehavior: "smooth",
            },
          }}
        />
        <AppProvider>
          <BrowserRouter>
            <IntercomUpdater />
            <Routes>
              {routes.map((route) => (
                <Route
                  key={route.key}
                  path={route.path}
                  element={route.element}
                />
              ))}
            </Routes>
          </BrowserRouter>
        </AppProvider>
      </ThemeProvider>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
}

export default App;
