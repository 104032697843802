import axios from "axios";
import { getCookieByName } from "./cookieProvider";
const token = getCookieByName("twingly_token");

const client = axios.create({
  headers: {
    Authorization: token,
  },
});

export default client;
