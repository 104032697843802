import React from "react";
import { Paper, Box, Typography, Alert } from "@mui/material";
import PageLayout from "../components/templates/PageLayout";
import RequestSourcesForm from "../components/templates/RequestSourcesForm";
import PageHeader from "../components/templates/PageHeader";
import PageTitleUpdater from "../components/_functional/PageTitleUpdater";

const RequestSources = () => {
  return (
    <PageLayout>
      <PageHeader pageHeader={"Menu"} pageTabs={false} titleHeader={false} />
      <PageTitleUpdater pageTitle="Request Sources" />

      <Box
        sx={{
          maxWidth: 760,
          m: { xs: 1, md: 3 },
        }}
      >
        <Paper elevation={0} sx={{ mt: 2, p: 3, borderRadius: "8px" }}>
          <h2>Request Sources</h2>
          <Typography paragraph>
            On this page you can ask us to add new sources to our monitoring. A
            source could be a news site, a blog, a forum, etc. that you wish to
            be delivered through our APIs.
          </Typography>
          <Typography paragraph>
            If you experience problems with sources that we already deliver, we
            ask you to report these through our{" "}
            <a href="/support">support form</a> instead.
          </Typography>
          <Alert severity="info" sx={{ mb: 2 }}>
            Please note that there might be additional charges to add sources
            for monitoring. Contact{" "}
            <a href="mailto:sales@twingly.com">sales@twingly.com</a> for more
            information.
          </Alert>
          <RequestSourcesForm />
        </Paper>
      </Box>
    </PageLayout>
  );
};

export default RequestSources;
