import React from "react";
import { IconButton, useMediaQuery } from "@mui/material";
import Icon from "../atoms/Icon";

const ToTopButton = () => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const onClick = () =>
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  return (
    <IconButton
      onClick={onClick}
      sx={{
        background: "white",
        position: matches ? "fixed" : "sticky",
        bottom: 20,
        right: 20,
        width: 40,
        ml: "auto",
        height: 40,
        mr: 8,
        zIndex: 10,
        boxShadow:
          "0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 10px 30px 5px rgba(0, 0, 0, 0.12)",
        "&:hover": {
          background: "white",
        },
        svg: {
          transform: "rotate(-90deg)",
        },
      }}
      variant="contained"
    >
      <Icon name="arrowRight" />
    </IconButton>
  );
};

export default ToTopButton;
