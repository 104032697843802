import React, { useState, useLayoutEffect, useCallback } from "react";
import { Paper, useMediaQuery } from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactMarkdown from "react-markdown";
import {
  List,
  ListItemButton,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import { generateHeadingValue, generateId } from "../../util/helpers";
import rehypeRaw from "rehype-raw";

const Wrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  width: 320,
  order: 2,
  transition: "all .2s ease",
  borderRadius: "8px",
  top: 24,
  overflowY: "auto",
  a: {
    color: theme.palette.primary.main,
    textDecoration: "none",
  },
  ul: {
    m: 0,
    pl: 2,
  },
  code: {
    wordWrap: "break-word",
    color: "rgb(125, 151, 38)",
    padding: "2px 4px",
    backgroundColor: "rgb(244, 243, 236)",
    borderRadius: "4px",
    fontSize: "0.875em",
  },
  [theme.breakpoints.down(1400)]: {
    order: 1,
    width: "100%",
  },
}));

const DocumentationToc = ({ docs }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down(1400));

  const [scrolled, setScrolled] = useState(false);
  const handleNavigation = useCallback((e) => {
    const window = e.currentTarget;
    if (window.scrollY > 128) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("scroll", (e) => handleNavigation(e));

    return () => {
      window.removeEventListener("scroll", (e) => handleNavigation(e));
    };
  }, [handleNavigation]);

  const createTocButton = (htmlElement) => {
    const headingValue = generateHeadingValue(htmlElement.children);
    const headingId = generateId(htmlElement.children);
    const headingLevel = htmlElement.level - 1;
    const buttonHeightScaling = 0.3;
    const indentationScaling = 2;

    return (
      <ListItemButton
        id={headingId}
        href={`#${headingId}`}
        sx={{
          pl: headingLevel * indentationScaling,
          pt: buttonHeightScaling,
          pb: buttonHeightScaling,
        }}
        heading-level={headingLevel}
      >
        <ListItemText primary={headingValue} />
      </ListItemButton>
    );
  };

  return (
    <Wrapper
      elevation={0}
      sx={{
        position: scrolled && !matches ? "sticky" : "static",
        maxHeight: matches
          ? "auto"
          : scrolled
          ? "calc(100vh - 48px)"
          : "calc(100vh - 152px)",
      }}
    >
      <List
        component="nav"
        aria-labelledby="toc-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="toc-subheader"
            sx={{ top: -24, pl: 0 }}
          >
            Table of Contents
          </ListSubheader>
        }
      >
        <ReactMarkdown
          rehypePlugins={[rehypeRaw]}
          allowedElements={["h1", "h2", "h3", "h4", "h5", "h6", "code", "span"]}
          skipHtml={true}
          components={{
            h1: createTocButton,
            h2: createTocButton,
            h3: createTocButton,
            h4: createTocButton,
            h5: createTocButton,
            h6: createTocButton,
            code: () => null,
            span: () => null,
          }}
        >
          {docs}
        </ReactMarkdown>
      </List>
    </Wrapper>
  );
};

export default DocumentationToc;
