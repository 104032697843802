import React from "react";
import { Typography, Box, Paper, Divider, Button } from "@mui/material";
import ApiFeatures from "../molecules/ApiFeatures";
import ActiveApiRequests from "../molecules/ActiveApiRequests";
import getApiMetadata from "../../constants/nameConstants";

const ApiInfo = ({ apiData, apiName }) => {
  return (
    <Paper sx={{ borderRadius: 2 }} elevation={0}>
      <Box
        sx={{
          px: 2,
          py: 1,
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
        }}
      >
        <Typography data-testid={`${apiName}-api-info`} variant="h6">
          API Info
        </Typography>
      </Box>
      <Box sx={{ px: 2, pt: 3 }}>
        <Typography fontWeight={700} mb={3}>
          {getApiMetadata(apiName).productName} API
        </Typography>
        {apiData.enabled && (
          <Box>
            <ActiveApiRequests
              requestsNumber={apiData.numberOfRequests}
              requestLimit={apiData.requestLimit}
              isSoftLimit={apiData.isSoftLimit}
              isTrial={apiData.isTrial}
              apiName={apiName}
              locations={apiData.locations}
              languages={apiData.languages}
            />
          </Box>
        )}
      </Box>
      {apiData.enabled && (
        <Divider
          sx={{
            mt: 4,
            mb: 3,
            borderColor: "rgba(0, 0, 0, 0.08)",
          }}
        />
      )}
      <Box sx={{ px: 2, pb: 3 }}>
        {apiData.enabled && (
          <Typography fontWeight={700} mb={3}>
            Details
          </Typography>
        )}
        <ApiFeatures name={apiName} />
        <Button variant="contained" href="/contact-sales" sx={{ mt: 1 }}>
          Contact sales
        </Button>
      </Box>
    </Paper>
  );
};

export default ApiInfo;
