import React, { useLayoutEffect } from "react";
import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { parseFieldError } from "../../util/helpers";
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)(() => ({
  ".MuiInputLabel-root": {
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)",
    "&.Mui-error": {
      color: "#C62828",
    },
  },
  ".MuiOutlinedInput-input": {
    fontSize: 14,
  },
  ".MuiOutlinedInput-root": {
    borderRadius: 12,
  },

  "&:not(.solo)": {
    ".MuiOutlinedInput-root": {
      "&:not(.Mui-focused):not(:hover) .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    },
  },
}));

const onlyNumberPattern = /^\d+$/;

const FilterTextInput = ({
  handleInputChange,
  required,
  label,
  name,
  multiline,
  disabled,
  type = "text",
  helperText,
  autoFocus,
  soloInput,
  maxNumberInput,
}) => {
  useLayoutEffect(() => {
    const input = document.getElementById(name);
    autoFocus && input && input.focus();
  }, []);

  const {
    control,
    clearErrors,
    setError,
    formState: { errors },
  } = useFormContext();

  const inputError = parseFieldError(name, errors);

  const handleChange = (newValue, onChange) => {
    if (
      type === "number" &&
      !onlyNumberPattern.test(newValue.target.value) &&
      newValue.target.value.length
    ) {
      setError(name, { message: "Should be number" });
    } else if (
      type === "number" &&
      maxNumberInput &&
      newValue.target.value > maxNumberInput
    ) {
      setError(name, { message: `Should not exceed ${maxNumberInput}` });
    } else {
      inputError && clearErrors(name);
    }
    handleInputChange(name, newValue.target.value);
    onChange(newValue);
  };

  const handleInputProps = (type) => {
    if (type === "number") {
      return { inputMode: "numeric", pattern: "[0-9]*" };
    } else if (type === "decimal") {
      return { inputMode: "decimal", pattern: "^(0\\.\\d|1\\.0|0|1)$" };
    } else {
      return {};
    }
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        validate: {
          isNumber: (value) => {
            if (type === "number") {
              return (
                (value && onlyNumberPattern.test(value)) || "Should be number"
              );
            } else {
              return true;
            }
          },
          doesNotExceedMaxNumber: (value) => {
            if (type === "number" && maxNumberInput) {
              return (
                (value && value <= maxNumberInput) ||
                `Should not exceed ${maxNumberInput}`
              );
            } else {
              return true;
            }
          },
        },
      }}
      render={({ field: { onChange, ...field } }) => {
        return (
          <StyledTextField
            {...field}
            className={soloInput ? "solo" : ""}
            onChange={(newValue) => handleChange(newValue, onChange)}
            sx={{
              mb: 2,
            }}
            id={name}
            type={"text"}
            label={label}
            required={!!required}
            disabled={disabled}
            multiline={multiline}
            error={!!inputError}
            placeholder={"Type..."}
            helperText={inputError ? inputError : helperText}
            fullWidth
            inputProps={handleInputProps(type)}
            onWheel={(e) => (type === "number" ? e.target.blur() : null)}
          />
        );
      }}
    />
  );
};
export default FilterTextInput;
