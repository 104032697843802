import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { styled } from "@mui/material/styles";

const ChartWrap = styled(Box)(({ theme }) => ({
  overflow: "auto",
  msOverflowStyle: "none",
  scrollbarWidth: "none",

  "&::-webkit-scrollbar": {
    display: "none",
  },

  ".apexcharts-tooltip.apexcharts-theme-light": {
    background: "white",
    borderRadius: theme.spacing(1),
    boxShadow: "0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
    border: "none",
    ".apexcharts-tooltip-title": {
      fontSize: "14px!important",
      fontWeight: 500,
      background: "white",
      padding: theme.spacing(1, 2),
    },
    ".apexcharts-tooltip-marker": {
      width: theme.spacing(2),
      height: theme.spacing(2),
    },
  },
}));

const UsageChart = ({ graphData }) => {
  const [selectedSeries, setSelectedSeries] = useState(graphData.seriesNames);

  useEffect(() => {
    setSelectedSeries(graphData.seriesNames);
  }, [graphData.seriesNames]);

  const state = {
    options: {
      chart: {
        id: "chart",
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        fontFamily: "Roboto, Helvetica, Arial ,sans-serif",
      },
      yaxis: {
        forceNiceScale: true,
        tickAmount: 10,
        max: function (max) {
          return max < 10 ? 10 : max * 1.1;
        },
        min: 0,
      },
      xaxis: {
        categories: graphData.xAxis,
        labels: {
          rotateAlways: true,
          rotate: -45,
        },
        tooltip: {
          enabled: false,
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: true,
      },
      legend: {
        show: false,
      },
    },
    series: graphData.graphValues,
  };

  const toggleChart = (key, values) => {
    setSelectedSeries(values);
    ApexCharts.exec("chart", "toggleSeries", key.target.value);
  };

  return (
    <ChartWrap>
      <Box
        sx={{
          minWidth: 800,
        }}
      >
        <Chart
          height="368px"
          options={state.options}
          series={state.series}
          type="line"
        />
        <Box display="flex">
          <ToggleButtonGroup
            value={selectedSeries}
            sx={{
              m: "auto",
            }}
            onChange={toggleChart}
            aria-label="chart legend"
          >
            {graphData.graphValues.map((item, index) => (
              <ToggleButton
                size="small"
                sx={{
                  fontWeight: 400,
                  px: 1.25,
                  py: 0.25,
                  textTransform: "initial",
                  color: "text.primary",
                  "&.Mui-selected": {
                    color: "white",
                    backgroundColor: item.color,
                    "&:hover": {
                      color: "text.primary",
                    },
                  },
                }}
                key={index}
                value={item.name}
                aria-label={item.name}
              >
                {item.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
      </Box>
    </ChartWrap>
  );
};

export default UsageChart;
