import React from "react";
import { deleteCookie } from "./cookieProvider";
import {
  format,
  startOfMonth,
  sub,
  setMinutes,
  setSeconds,
  addMinutes,
  addDays,
  endOfMonth,
} from "date-fns";
import { shutdownIntercom } from "../components/_functional/intercom";
import getApiMetadata from "../constants/nameConstants";
import request from "./request";

export function handleLogout() {
  deleteCookie("twingly_token");
  deleteCookie("api_key");
  deleteCookie("user_id");
  deleteCookie("email");
  deleteCookie("intercom_user_hash");
  shutdownIntercom();
}

export function parseFieldError(name, errors, pointer) {
  const fieldName = name.split(".");

  let inputError;

  if (fieldName.length > 1) {
    const [first, second] = fieldName;
    inputError =
      errors[`${first}`] &&
      errors[`${first}`][`${second}`] &&
      errors[`${first}`][`${second}`].message;
  } else if (pointer) {
    inputError = errors[pointer] && errors[pointer][name].message;
  } else {
    inputError = errors[name] && errors[name].message;
  }

  return inputError;
}

export function formatDate(date) {
  let parsedDate;
  if (date instanceof Date) {
    parsedDate = date;
  } else {
    parsedDate = new Date(date);
  }

  return {
    dayMonth: format(parsedDate, "d MMM"),
    monthDay: format(parsedDate, "MMMM d"),
    monthDayYear: format(parsedDate, "MMMM d, yyyy"),
    utc: format(parsedDate, "yyyy-MM-dd HH:mm:ss"),
    utcString: format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'"),
    timestamp: format(parsedDate, "T"),
  };
}

export function formatCompactNumber(number) {
  let compactNumber;
  if (number < 10000) {
    compactNumber = number.toString();
  } else if (number < 1000000) {
    compactNumber = (number / 1000).toPrecision(3).toString() + "K";
  } else {
    compactNumber = (number / 1000000).toPrecision(3).toString() + "M";
  }

  return compactNumber;
}

export function getFirstDayOfMonth(date) {
  return startOfMonth(date);
}

export function getLastDayOfMonth(date) {
  return endOfMonth(date);
}

export function getParamFromUrl(url, param) {
  const regular = new RegExp(`.*[?&]${param}=([^&]+)(&|$)`);

  const match = url.match(regular);
  return match ? match[1] : "";
}

export function subDays(date, subDays) {
  return sub(date, { days: subDays });
}

export function subMonths(date, subMonths) {
  return sub(date, { months: subMonths });
}

export function zeroMinutesSeconds(date) {
  return setMinutes(setSeconds(date, "00"), "00");
}

export function formatIsoDate(date) {
  const offset = date.getTimezoneOffset();

  return addMinutes(date, -offset);
}

export function addDaysToDate(date, days) {
  return addDays(date, days);
}

export function activateProductTrial(apiName) {
  return request({
    method: "post",
    url: "/api/v1/proxy/add_customer",
    data: {
      product_name: apiName,
    },
  });
}

export function generateCurl(apiKey, data, url, isBlogSearchApi) {
  const curlJson = `curl -v -s \\
  --request POST \\
  --header "Authorization: apikey ${apiKey}" \\
  --header "Content-Type: application/json; charset=utf-8" \\
  --header "Accept: application/json; charset=utf-8" \\
  --data '${data}' \\
  "${url}"`;
  const curlSearchLanguage = `curl -s "${url}?apikey=${apiKey}&q=${
    data ? data : ""
  }"`;

  return isBlogSearchApi ? curlSearchLanguage : curlJson;
}

export function generateRulesFromUrl(formValuesFromUrl) {
  const newRules = [];
  Object.keys(formValuesFromUrl).forEach((key) => {
    if (!formValuesFromUrl[key]) {
      return;
    }
    if (
      typeof formValuesFromUrl[key] === "string" &&
      formValuesFromUrl[key].length
    ) {
      newRules.push(key);
    }
    if (typeof formValuesFromUrl[key] === "boolean") {
      newRules.push(key);
    }
    if (typeof formValuesFromUrl[key] === "number") {
      newRules.push(key);
    }
    if (
      Array.isArray(formValuesFromUrl[key]) &&
      formValuesFromUrl[key].length
    ) {
      newRules.push(key);
    }
    if (
      typeof formValuesFromUrl[key] === "object" &&
      !Array.isArray(formValuesFromUrl[key])
    ) {
      Object.keys(formValuesFromUrl[key]).forEach((objectKey) => {
        formValuesFromUrl[key][objectKey].length && newRules.push(key);
      });
    }
  });
  return newRules;
}

export function getTimezoneOffset(value) {
  return value.getTimezoneOffset() * 60000;
}

export function makeLocalAppearUTC(value) {
  const dateTime = new Date(value);
  const utcFromLocal = new Date(
    dateTime.getTime() + getTimezoneOffset(dateTime)
  );
  return utcFromLocal;
}

export function localToUTC(dateTime) {
  const utcFromLocal = new Date(
    dateTime.getTime() - getTimezoneOffset(dateTime)
  );
  return utcFromLocal;
}

export function generateValues(formValues, rules, querySettings) {
  const {
    selectInputs,
    nestedObjectFields,
    dateInputs,
    numberInputs,
    singleDropdown,
    replaceKeyName,
  } = querySettings;

  const newValues = {};
  rules
    ? formValues &&
      rules.forEach((item) => {
        const objectKey = nestedObjectFields[item];
        const replacedKey = replaceKeyName[item] ? replaceKeyName[item] : item;
        let dateInputValue;

        if (formValues[item]) {
          if (dateInputs.includes(item)) {
            dateInputValue = formatDate(
              makeLocalAppearUTC(new Date(formValues[item]))
            ).utcString;
          }

          if (objectKey) {
            const nestedAlreadyExists = !!newValues[objectKey];

            if (nestedAlreadyExists) {
              if (dateInputs.includes(item)) {
                newValues[objectKey][replacedKey] = dateInputValue
                  ? dateInputValue
                  : formValues[item];
              } else if (numberInputs.includes(item)) {
                newValues[objectKey][replacedKey] = Number(formValues[item]);
              }
            } else {
              newValues[objectKey] = {
                [replacedKey]: dateInputValue
                  ? dateInputValue
                  : formValues[item],
              };
            }
          } else if (selectInputs.includes(item)) {
            newValues[item] = formValues[item].map((item) => item.value);
          } else if (singleDropdown.includes(item)) {
            newValues[item] = formValues[item].value;
          } else if (numberInputs.includes(item)) {
            if (Array.isArray(formValues[item])) {
              newValues[item] = formValues[item].map((item) => Number(item));
            } else {
              newValues[item] = Number(formValues[item]);
            }
          } else {
            newValues[item] = formValues[item];
          }
        }
      })
    : formValues;

  return newValues;
}

const convertPhrases = (values) => {
  return values.map((item) => (item.includes(" ") ? `"${item}"` : item));
};

export function delayedScrollIntoView(scrollTo) {
  const waitTime = 10;

  const scrollSmoothly = () => {
    scrollTo?.scrollIntoView({ behavior: "smooth" });
  };

  setTimeout(scrollSmoothly, waitTime);
}

export function generateHeadingValue(headingChildren) {
  return headingChildren
    .map((child, index) => {
      if (typeof child === "string") {
        return child;
      } else if (child.type.name === "code") {
        return <code key={index}>{child.props.children[0]}</code>;
      }
    })
    .filter((item) => item !== undefined);
}

export function generateId(content) {
  return content
    .map((item) => (typeof item === "string" ? item : item.props.children[0]))
    .join("")
    .toLowerCase()
    .replace(/[?'/,().]/g, "")
    .replace(/ /g, "-");
}

export function generateRawQueryLanguage(formValues) {
  let query = "";

  if (formValues) {
    const allExists = formValues.all?.length;
    const anyExists = formValues.any?.length;
    const noneExists = formValues.none?.length;

    if (formValues.search_within) {
      query += `fields:${formValues.search_within
        .map((item) => item.value)
        .join("|")} `;
    }

    if (allExists) {
      query += `${convertPhrases(formValues.all).join(" ")} `;
    }

    if (anyExists) {
      query += `${allExists && anyExists > 1 ? "AND (" : ""}${convertPhrases(
        formValues.any
      ).join(" OR ")}${allExists && anyExists > 1 ? ")" : ""} `;
    }

    if (noneExists) {
      query += `-${convertPhrases(formValues.none).join(" -")} `;
    }

    // Links
    if (formValues.link_all?.length) {
      query += `link:${formValues.link_all.join(",")} `;
    }
    if (formValues.link_any?.length) {
      query += `link:${formValues.link_any.join("|")} `;
    }
    if (formValues.link_none?.length) {
      query += `-link:${formValues.link_none.join(" -link:")} `;
    }

    // Sites
    if (formValues.site_any?.length) {
      query += `site:${formValues.site_any.join("|")} `;
    }
    if (formValues.site_none?.length) {
      query += `-site:${formValues.site_none.join(" -site:")} `;
    }

    // Blogs
    if (formValues.blog_any?.length) {
      query += `blog:${formValues.blog_any.join("|")} `;
    }
    if (formValues.blog_none?.length) {
      query += `-blog:${formValues.blog_none.join(" -blog:")} `;
    }

    if (formValues.languages?.length) {
      query += `lang:${formValues.languages
        .map((item) => item.value)
        .join("|")} `;
    }
    if (formValues.locations?.length) {
      query += `location:${formValues.locations
        .map((item) => item.value)
        .join("|")} `;
    }

    // Tags
    if (formValues.tags_all?.length) {
      query += `tag:${convertPhrases(formValues.tags_all).join(" tag:")} `;
    }
    if (formValues.tags_any?.length) {
      query += `tag:${convertPhrases(formValues.tags_any).join("|")} `;
    }
    if (formValues.tags_none?.length) {
      query += `-tag:${convertPhrases(formValues.tags_none).join(" -tag:")} `;
    }

    // Authors
    if (formValues.author_all?.length) {
      query += `author:${convertPhrases(formValues.author_all).join(",")} `;
    }
    if (formValues.author_any?.length) {
      query += `author:${convertPhrases(formValues.author_any).join("|")} `;
    }

    if (formValues.exclude_author?.length) {
      query += `-author:${convertPhrases(formValues.exclude_author).join(
        " -author:"
      )} `;
    }

    const trimmedISO = (date) => {
      return date.toISOString().slice(0, -5);
    };

    if (formValues.post_id?.length) {
      query += `id:${formValues.post_id} `;
    }

    if (formValues.since) {
      query += `start-date:${trimmedISO(new Date(formValues.since))} `;
    }
    if (formValues.until) {
      query += `end-date:${trimmedISO(new Date(formValues.until))} `;
    }

    if (formValues.sort) {
      query += `sort:${formValues.sort.value} `;
    }
    if (formValues.order) {
      query += `sort-order:${formValues.order.value} `;
    }
    if (formValues.size) {
      query += `page-size:${formValues.size} `;
    } else {
      query += `page-size:10`;
    }
  }

  return query.trim();
}
export function generateDarkWebValues(values) {
  let query = "";

  Object.entries(values).map(([key, value]) => {
    if (key === "search") {
      query += `${convertPhrases(value).join(" ")} `;
    }
    if (key === "author") {
      query += `author:${convertPhrases(value).join(" author:")} `;
    }
    if (key === "site_domain") {
      query += `site.domain:${convertPhrases(value).join(" site.domain:")} `;
    }
    if (key === "site_type") {
      query += `site.type:${value
        .map((item) => item.value)
        .join(" site.type:")} `;
    }
    if (key === "site_name") {
      query += `site.name:${convertPhrases(value).join(" site.name:")} `;
    }
    if (key === "enriched_domain_value") {
      query += `enriched.domain.value:${convertPhrases(value).join(
        " enriched.domain.value:"
      )} `;
    }
    if (key === "language") {
      query += `language:${convertPhrases(value).join(" language:")} `;
    }
    if (key === "crawled") {
      query += `${
        value.crawled_since
          ? `crawled:>${formatDate(value.crawled_since).timestamp} `
          : ""
      }${
        value.crawled_until
          ? `crawled:<${formatDate(value.crawled_until).timestamp} `
          : ""
      }`;
    }
    if (key === "enriched_email_value") {
      query += `enriched.email.value:${convertPhrases(value).join(
        " enriched.email.value:"
      )} `;
    }
    if (key === "extended_network") {
      query += `extended.network:${value
        .map((item) => item.value)
        .join(" extended.network:")} `;
    }
    if (key === "enriched_credit_card_value") {
      query += `enriched.credit_card.value:${convertPhrases(value).join(
        " enriched.credit_card.value:"
      )} `;
    }
    if (key === "enriched_wallet_id_value") {
      query += `enriched.wallet_id.value:${convertPhrases(value).join(
        " enriched.wallet_id.value:"
      )} `;
    }
    if (key === "enriched_organization_value") {
      query += `enriched.organization.value:${convertPhrases(value).join(
        " enriched.organization.value:"
      )} `;
    }
  });
  return query;
}

export function generateDashboardData(data) {
  const sortingOrder = {
    blog_search: 1,
    blog_livefeed: 2,
    news_b_search: 3,
    news_b_livefeed: 4,
    forum_c_search: 5,
    review_c_search: 7,
    dark_web_a_search: 8,
    vk_c_search: 9,
  };

  const apiColors = {
    blog_search: "#388E3C",
    blog_livefeed: "#00897B",
    news_b_search: "#F44336",
    news_b_livefeed: "#D81B60",
    forum_c_search: "#2962FF",
    review_c_search: "#9729FF",
    dark_web_a_search: "#49566E",
    vk_c_search: "#0091EA",
  };

  let graphValues = [];
  let seriesNames = [];
  let active = [];
  let disabled = [];

  Object.keys(data).forEach((item) => {
    // Skip products which has not yet been added to the frontend code
    if (!(item in sortingOrder)) {
      return;
    }

    seriesNames.push(getApiMetadata(item).productName);

    if (data[item].enabled) {
      active.push({
        apiName: item,
        requestsNumber: data[item].number_of_requests_current_period,
        requestLimit: data[item].number_of_requests_limit,
        isSoftLimit: data[item].is_soft_request_limit,
        isTrial: data[item].is_trial,
        languages: data[item].metadata.languages,
        locations: data[item].metadata.locations,
      });
      graphValues.push({
        name: getApiMetadata(item).productName,
        key: item,
        color: apiColors[item],
        data: Object.values(data[`${item}`].number_of_requests_per_day).map(
          (value) => value
        ),
      });
    } else {
      disabled.push({
        apiName: item,
      });
    }
  });

  seriesNames.sort((a, b) => {
    return sortingOrder[a] - sortingOrder[b];
  });

  graphValues.sort((a, b) => {
    return sortingOrder[a.key] - sortingOrder[b.key];
  });

  active.sort((a, b) => {
    return sortingOrder[a.apiName] - sortingOrder[b.apiName];
  });
  disabled.sort((a, b) => {
    return sortingOrder[a] - sortingOrder[b];
  });

  return { graphValues, seriesNames, active, disabled };
}
