import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { FormControlLabel, Checkbox, Box } from "@mui/material";

const CheckboxInput = ({ name, className, label, required, onChange }) => {
  const { control, getValues } = useFormContext();

  const handleCheck = () => !getValues(`${name}`);

  return (
    <Box className={className}>
      <Controller
        name={name}
        control={control}
        rules={{
          required: required,
        }}
        render={(props) => (
          <FormControlLabel
            sx={{
              mb: 3,
            }}
            control={
              <Checkbox
                required={required}
                onChange={() => {
                  props.field.onChange(handleCheck());
                  onChange && onChange();
                }}
                color={"primary"}
              />
            }
            label={label}
          />
        )}
      />
    </Box>
  );
};

export default CheckboxInput;
