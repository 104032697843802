import React from "react";
import { Box, Typography } from "@mui/material";
import FilterMultipleText from "../../molecules/FilterMultipleText";
import FilterTextInput from "../../molecules/FilterTextInput";
import FilterSelectAutocomplete from "../../molecules/FilterSelectAutocomplete";
import FilterDateInput from "../../molecules/FilterDateInput";
import DeleteRuleButton from "../../molecules/DeleteRuleButton";
import TooltipButton from "../../molecules/TooltipButton";
import blogSearchSettings from "../../../constants/queryBuilder/blogSearch";

const BlogSearchFields = ({
  watch,
  rules,
  handleChange,
  removeRule,
  apiData,
}) => {
  return (
    <>
      {rules.includes("all") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="all"
              label="Match All"
            />
          </Box>
          <DeleteRuleButton removeRule={removeRule} rulesToRemove={["all"]} />
        </Box>
      )}
      {rules.includes("any") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="any"
              label="Match Any"
            />
          </Box>
          <DeleteRuleButton removeRule={removeRule} rulesToRemove={["any"]} />
        </Box>
      )}
      {rules.includes("none") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="none"
              label="Match None"
            />
          </Box>
          <DeleteRuleButton removeRule={removeRule} rulesToRemove={["none"]} />
        </Box>
      )}

      {rules.includes("search_within") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterSelectAutocomplete
              autoFocus
              handleInputChange={handleChange}
              options={blogSearchSettings.search_within}
              name="search_within"
              label="Search Fields"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["search_within"]}
          />
        </Box>
      )}
      {rules.includes("author_all") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="author_all"
              label="Match All Authors"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["author_all"]}
          />
        </Box>
      )}
      {rules.includes("author_any") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="author_any"
              label="Match Any Authors"
            />
          </Box>
          <TooltipButton
            title={
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                }}
                color="inherit"
              >
                It’s only possible to search for single words, not phrases here.
              </Typography>
            }
          />
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["author_any"]}
          />
        </Box>
      )}
      {rules.includes("exclude_author") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="exclude_author"
              label="Exclude Author"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["exclude_author"]}
          />
        </Box>
      )}

      {rules.includes("tags_all") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="tags_all"
              label="Match All Tags"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["tags_all"]}
          />
        </Box>
      )}
      {rules.includes("tags_any") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="tags_any"
              label="Match Any Tags"
            />
          </Box>
          <TooltipButton
            title={
              <Typography
                sx={{
                  fontSize: 12,
                  fontWeight: 500,
                }}
                color="inherit"
              >
                It’s only possible to search for single words, not phrases here.
              </Typography>
            }
          />
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["tags_any"]}
          />
        </Box>
      )}
      {rules.includes("tags_none") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="tags_none"
              label="Exclude Tags"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["tags_none"]}
          />
        </Box>
      )}

      {rules.includes("link_any") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="link_any"
              label="Links to Any"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["link_any"]}
          />
        </Box>
      )}
      {rules.includes("link_all") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="link_all"
              label="Links to All"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["link_all"]}
          />
        </Box>
      )}
      {rules.includes("link_none") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="link_none"
              label="Exclude Links to"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["link_none"]}
          />
        </Box>
      )}
      {rules.includes("site_any") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="site_any"
              label="Match URL"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["site_any"]}
          />
        </Box>
      )}
      {rules.includes("site_none") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="site_none"
              label="Exclude URL"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["site_none"]}
          />
        </Box>
      )}

      {rules.includes("languages") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterSelectAutocomplete
              autoFocus
              handleInputChange={handleChange}
              options={blogSearchSettings.languages}
              name="languages"
              label="Languages"
              enabledOptions={apiData.enabledLanguages}
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["languages"]}
          />
        </Box>
      )}
      {rules.includes("locations") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterSelectAutocomplete
              autoFocus
              handleInputChange={handleChange}
              options={blogSearchSettings.locations}
              name="locations"
              label="Locations"
              enabledOptions={apiData.enabledLocations}
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["locations"]}
          />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Box
          gap={{ xs: 0, md: 2 }}
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {rules.includes("since") && (
            <Box sx={{ flex: { md: "0 1 240px" } }}>
              <FilterDateInput
                handleInputChange={handleChange}
                label="Created Since"
                name="since"
              />
            </Box>
          )}
          {rules.includes("until") && (
            <Box sx={{ flex: { md: "0 1 240px" } }}>
              <FilterDateInput
                handleInputChange={handleChange}
                minDate={new Date(watch("since"))}
                label="Created Until"
                name="until"
              />
            </Box>
          )}
        </Box>
        {(rules.includes("since") || rules.includes("until")) && (
          <DeleteRuleButton
            forTwoFields
            removeRule={removeRule}
            rulesToRemove={["since", "until"]}
          />
        )}
      </Box>
      <Box
        gap={{ xs: 0, md: 2 }}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        {rules.includes("size") && (
          <Box display="flex" alignItems="flex-start">
            <Box sx={{ flex: "0 0 160px" }}>
              <FilterTextInput
                autoFocus
                handleInputChange={handleChange}
                name="size"
                type="number"
                label="Number of Results"
                maxNumberInput={500}
              />
            </Box>
            <TooltipButton
              title={
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                  color="inherit"
                >
                  This filter has been limited in the query builder, use the API
                  directly to get more than 500 results.
                </Typography>
              }
            />
            <DeleteRuleButton
              removeRule={removeRule}
              rulesToRemove={["size"]}
            />
          </Box>
        )}
        {rules.includes("sort") && (
          <Box display="flex" flex="1" alignItems="flex-start">
            <Box sx={{ flex: "0 0 160px" }}>
              <FilterSelectAutocomplete
                handleInputChange={handleChange}
                multiple={false}
                options={blogSearchSettings.sort}
                name="sort"
                label="Sort by"
              />
            </Box>
            <DeleteRuleButton
              removeRule={removeRule}
              rulesToRemove={["sort"]}
            />
          </Box>
        )}
        {rules.includes("order") && (
          <Box display="flex" flex="1" alignItems="flex-start">
            <Box sx={{ flex: "0 0 160px" }}>
              <FilterSelectAutocomplete
                multiple={false}
                handleInputChange={handleChange}
                options={blogSearchSettings.order}
                name="order"
                label="Order"
              />
            </Box>
            <DeleteRuleButton
              removeRule={removeRule}
              rulesToRemove={["order"]}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default BlogSearchFields;
