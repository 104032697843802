import React from "react";

import Logo from "../../assests/icons/logo.svg";
import Blog from "../../assests/icons/blog.svg";
import News from "../../assests/icons/news.svg";
import Forum from "../../assests/icons/forum.svg";
import Review from "../../assests/icons/review.svg";
import Darkweb from "../../assests/icons/darkweb.svg";
import Vk from "../../assests/icons/vk.svg";
import Profile from "../../assests/icons/profile.svg";
import Info from "../../assests/icons/info.svg";
import BlogGray from "../../assests/icons/blog_gray.svg";
import Support from "../../assests/icons/support.svg";
import Logout from "../../assests/icons/logout.svg";
import StatusIndicator from "../../assests/icons/status_indicator.svg";
import Back from "../../assests/icons/back.svg";
import Email from "../../assests/icons/email.svg";
import EmailGray from "../../assests/icons/email_gray.svg";
import Password from "../../assests/icons/password.svg";
import Account from "../../assests/icons/account.svg";
import Company from "../../assests/icons/company.svg";
import Key from "../../assests/icons/key.svg";
import Copy from "../../assests/icons/copy.svg";
import Close from "../../assests/icons/close.svg";
import RealTimeStream from "../../assests/icons/real-time-stream.svg";
import Tor from "../../assests/icons/tor.svg";
import ArrowForward from "../../assests/icons/arrow_forward.svg";
import User from "../../assests/icons/user.svg";
import Dashboard from "../../assests/icons/dashboard.svg";
import Drawer from "../../assests/icons/drawer.svg";
import Plus from "../../assests/icons/plus.svg";
import ArrowRight from "../../assests/icons/arrow_right.svg";
import CloseSmall from "../../assests/icons/close_small.svg";
import LimitedAccess from "../../assests/icons/limited_access.svg";
import InfoHollow from "../../assests/icons/info_hollow.svg";
import Play from "../../assests/icons/play.svg";
import Delete from "../../assests/icons/delete.svg";
import InfoError from "../../assests/icons/info_error.svg";
import CheckSuccess from "../../assests/icons/check_success.svg";
import CloseMedium from "../../assests/icons/close_medium.svg";
import SearchIcon from "../../assests/icons/search_icon.svg";
import ForumGray from "../../assests/icons/forum_gray.svg";
import Marketplace from "../../assests/icons/marketplace.svg";
import Text from "../../assests/icons/text.svg";
import Add from "../../assests/icons/add.svg";
import ExpandLess from "../../assests/icons/expand_less.svg";
import ExpandMore from "../../assests/icons/expand_more.svg";
import HelpCenter from "../../assests/icons/help_center.svg";
import Settings from "../../assests/icons/settings.svg";
import Home from "../../assests/icons/home.svg";

const iconName = {
  logo: Logo,
  blog_search: Blog,
  news_b_search: News,
  forum_c_search: Forum,
  review_c_search: Review,
  dark_web_a_search: Darkweb,
  vk_c_search: Vk,
  info: Info,
  profile: Profile,
  blogGray: BlogGray,
  support: Support,
  logout: Logout,
  statusIndicator: StatusIndicator,
  back: Back,
  email: Email,
  emailGray: EmailGray,
  password: Password,
  account: Account,
  company: Company,
  key: Key,
  copy: Copy,
  close: Close,
  realTimeStream: RealTimeStream,
  tor: Tor,
  arrowForward: ArrowForward,
  user: User,
  dashboard: Dashboard,
  drawer: Drawer,
  plus: Plus,
  arrowRight: ArrowRight,
  closeSmall: CloseSmall,
  limitedAccess: LimitedAccess,
  infoHollow: InfoHollow,
  play: Play,
  delete: Delete,
  infoError: InfoError,
  checkSuccess: CheckSuccess,
  closeMedium: CloseMedium,
  searchIcon: SearchIcon,
  forum: ForumGray,
  marketplace: Marketplace,
  text: Text,
  add: Add,
  expandLess: ExpandLess,
  expandMore: ExpandMore,
  helpCenter: HelpCenter,
  settings: Settings,
  home: Home,
};

export default function Icon(props) {
  const Icon = iconName[props.name];
  if (Icon) {
    return <Icon {...props} />;
  } else {
    return null;
  }
}
