import React, { useEffect, useState } from "react";
import {
  Typography,
  Button,
  Popover,
  TextField,
  InputAdornment,
  IconButton,
  Fade,
} from "@mui/material";
import { getCookieByName } from "../../util/cookieProvider";
import Icon from "../atoms/Icon";

const Home = () => {
  const key = getCookieByName("api_key");

  const [copied, setCopied] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1500);
    }
  }, [copied]);

  const handleCopy = () => {
    navigator.clipboard.writeText(key);
    setCopied(true);
  };

  return (
    <>
      <Button
        size="small"
        aria-describedby={id}
        onClick={handleClick}
        variant="outlined"
      >
        Api key
      </Button>
      <Popover
        id={id}
        elevation={4}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
            borderRadius: "16px",
            width: 472,
            p: 2,
            position: "relative",
            top: "76px !important",
          },
        }}
      >
        <Fade in={copied}>
          <Typography
            sx={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
              py: 0.5,
              px: 2,
              color: "white",
              borderRadius: "6px",
              background: "rgba(0, 0, 0, 0.7)",
              zIndex: 1,
            }}
          >
            API Key Copied!
          </Typography>
        </Fade>
        <TextField
          fullWidth
          disabled
          defaultValue={key}
          label="Twingly API Key"
          sx={{
            ".MuiOutlinedInput-input.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.87) !important",
              WebkitTextFillColor: "rgba(0, 0, 0, 0.87) !important",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon name="key" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">
                <IconButton
                  onClick={handleCopy}
                  sx={{
                    mr: -1.75,
                    border: "1px solid rgba(0, 0, 0, 0.12);",
                    borderRadius: "4px",
                  }}
                >
                  <Icon name="copy" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Popover>
    </>
  );
};

export default Home;
