import React, { useState, useLayoutEffect, useCallback } from "react";
import {
  FormHelperText,
  TextField,
  Autocomplete,
  Button,
  Box,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { parseFieldError } from "../../util/helpers";
import Icon from "../atoms/Icon";
import { styled } from "@mui/material/styles";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "+ .options-list-wrap": {
    borderRadius: 8,
    background: "#F5F5F5",
    overflow: "hidden",
    marginTop: theme.spacing(-1.5),
    marginBottom: theme.spacing(2),

    ".MuiPaper-root": {
      background: "#F5F5F5",
    },
  },

  ".MuiOutlinedInput-input": {
    fontSize: 14,
  },

  ".MuiAutocomplete-popupIndicator": {
    display: "none",
  },

  ".MuiInputLabel-root": {
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)",
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
  ".MuiOutlinedInput-root": {
    borderRadius: 12,
  },

  "&:not(.solo)": {
    ".MuiOutlinedInput-root": {
      "&:not(.Mui-focused):not(:hover) .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    },
  },
}));

const FilterSelectAutocomplete = ({
  handleInputChange,
  required,
  label,
  name,
  disabled,
  options,
  autoFocus,
  sortAlphabetically = true,
  multiple = true,
  enabledOptions,
  soloInput,
}) => {
  const [open, setOpen] = useState(false);

  useLayoutEffect(() => {
    const input = document.getElementById(name);
    autoFocus && input && input.focus();
    soloInput && toggleOpen();
  }, []);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const sortedOptions = sortAlphabetically
    ? options.sort((a, b) => a.label.localeCompare(b.label))
    : options;

  const enabled = [];
  const disabledOptions = sortedOptions.filter((item) => {
    if (enabledOptions?.includes(item.value)) {
      enabled.push(item);
      return false;
    } else {
      return true;
    }
  });

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const inputError = parseFieldError(name, errors);

  if (inputError) {
    document
      .getElementById(`${name}`)
      .scrollIntoView({ block: "center", behavior: "smooth" });
  }

  const CustomPopper = useCallback(
    ({ children }) => {
      return (
        open && (
          <Box className="options-list-wrap">
            <Box
              sx={{
                maxHeight: 190,
                overflowY: "auto",
                ".MuiAutocomplete-listbox": { maxHeight: "100%" },
              }}
            >
              {children}
            </Box>
            {!soloInput && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  px: 2,
                  py: 1,
                  borderTop: "1px solid rgba(0, 0, 0, 0.08)",
                }}
              >
                <Button onClick={toggleOpen} size="small" variant="contained">
                  Done
                </Button>
              </Box>
            )}
          </Box>
        )
      );
    },
    [open]
  );

  const handleChange = (newValue, onChange) => {
    handleInputChange(name, newValue);
    onChange(newValue);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
      }}
      render={({ field: { ref, onChange, ...field } }) => (
        <>
          <StyledAutocomplete
            className={soloInput ? "solo" : ""}
            autoHighlight={true}
            {...field}
            sx={{ mb: 2 }}
            id={name}
            onOpen={toggleOpen}
            clearOnBlur
            open={open}
            disabled={disabled}
            disableClearable={true}
            multiple={multiple}
            options={
              enabledOptions ? [...enabled, ...disabledOptions] : sortedOptions
            }
            disableCloseOnSelect={false}
            getOptionDisabled={(option) =>
              enabledOptions ? !enabledOptions?.includes(option.value) : false
            }
            isOptionEqualToValue={(option, value) => {
              return option.value === value.value;
            }}
            fullWidth={true}
            PopperComponent={CustomPopper}
            componentsProps={{
              paper: {
                elevation: 0,
              },
            }}
            onChange={(e, newValue) => handleChange(newValue, onChange)}
            ChipProps={{
              deleteIcon: <Icon name="closeSmall" />,
              sx: {
                ".MuiChip-deleteIcon": {
                  ml: 0,
                  mr: 0,
                  "&:hover": {
                    rect: {
                      fill: "#000",
                    },
                  },
                },
              },
            }}
            renderInput={(params) => {
              return (
                <>
                  <TextField
                    {...params}
                    inputRef={ref}
                    fullWidth
                    autoFocus
                    required={!!required}
                    label={label}
                    error={!!inputError}
                    placeholder={"Type..."}
                    InputProps={{
                      ...params.InputProps,
                    }}
                  />
                  {inputError && (
                    <FormHelperText error={!!inputError} sx={{ mx: 0 }}>
                      {inputError}
                    </FormHelperText>
                  )}
                </>
              );
            }}
            ListboxProps={{
              style: {
                padding: 0,
              },
            }}
          />
        </>
      )}
    />
  );
};
export default FilterSelectAutocomplete;
