import React, { useState } from "react";
import {
  Typography,
  Box,
  Card,
  Divider,
  Link,
  Modal,
  List,
  ListItem,
  ListItemText,
  CardHeader,
  IconButton,
  Tooltip,
} from "@mui/material";
import features from "../../constants/enabledApiFeatures";
import {
  formatCompactNumber,
  formatDate,
  getFirstDayOfMonth,
  getLastDayOfMonth,
} from "../../util/helpers";
import getApiMetadata from "../../constants/nameConstants";
import Icon from "../atoms/Icon";

const ActiveApiRequests = ({
  apiName,
  requestsNumber,
  requestLimit,
  isSoftLimit,
  isTrial,
  locations,
  languages,
}) => {
  const date = Date.now();
  const firstDayOfMonth = formatDate(getFirstDayOfMonth(date)).monthDay;
  const lastDayOfMonth = formatDate(getLastDayOfMonth(date)).monthDayYear;

  const cardData = features[apiName];

  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const [locationModalOpen, setLocationModalOpen] = useState(false);

  const handleOpenLanguageModal = () => {
    setLanguageModalOpen(true);
  };
  const handleOpenLocationModal = () => {
    setLocationModalOpen(true);
  };
  const handleCloseModals = () => {
    setLanguageModalOpen(false);
    setLocationModalOpen(false);
  };

  const modalStyle = {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxWidth: "90%",
    px: 2,
    pt: 1,
    pb: 2,
    borderRadius: "12px",
    boxShadow: "0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
    border: "4px solid rgba(114, 122, 0, 0.3)",
    outline: "none",
  };

  const showRequestLimit = requestLimit && !isSoftLimit;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          mb: 2,
        }}
        gap={1}
      >
        <Tooltip
          title={
            showRequestLimit
              ? Math.max(requestLimit - requestsNumber, 0).toString() +
                " requests left"
              : "No request limit"
          }
          placement={"top"}
          arrow
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
            }}
            gap={1}
          >
            <Typography fontWeight={600} lineHeight="48px" fontSize={40}>
              {requestsNumber}
            </Typography>
            {showRequestLimit && (
              <Typography color="textSecondary">
                /{formatCompactNumber(requestLimit)}
              </Typography>
            )}
            <Typography>Requests made</Typography>
          </Box>
        </Tooltip>
      </Box>

      {!isTrial && (
        <Typography color="textSecondary" fontSize={14}>
          {firstDayOfMonth} - {lastDayOfMonth}
        </Typography>
      )}

      <Box display="flex" flexDirection="column" mb={2}>
        {cardData.maxLanguages && (
          <>
            <Link
              underline="none"
              onClick={handleOpenLanguageModal}
              sx={{ cursor: "pointer" }}
            >
              {languages
                ? `${languages.length} of ${cardData.maxLanguages} Languages`
                : `All ${cardData.maxLanguages} Languages Enabled`}
            </Link>
            <Modal open={languageModalOpen} onClose={handleCloseModals}>
              <Card sx={modalStyle}>
                <CardHeader
                  title={
                    <Typography variant="h6" component="h2">
                      Enabled languages
                    </Typography>
                  }
                  subheader={
                    <Typography variant="caption" component="p">
                      {getApiMetadata(apiName).productName}
                    </Typography>
                  }
                  action={
                    <IconButton onClick={handleCloseModals}>
                      <Icon name="close" />
                    </IconButton>
                  }
                />
                {languages ? (
                  <List sx={{ overflow: "auto", maxHeight: 400 }}>
                    {languages.map((language) => (
                      <ListItem key={language}>
                        <ListItemText primary={language} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography my={2} ml={2}>
                    All languages are enabled for this product.
                  </Typography>
                )}
                <Divider
                  sx={{
                    mb: 2,
                    borderColor: "rgba(0, 0, 0, 0.08)",
                  }}
                />
                <Link
                  href={`/${getApiMetadata(apiName).productDataType}?tab=${
                    getApiMetadata(apiName).docPath
                  }#${cardData.langAnchor}`}
                  underline="none"
                  sx={{ ml: 2 }}
                >
                  Show available languages
                </Link>
              </Card>
            </Modal>
          </>
        )}
        {cardData.maxCountries && (
          <>
            <Link
              underline="none"
              onClick={handleOpenLocationModal}
              sx={{ cursor: "pointer" }}
            >
              {locations
                ? `${locations.length} of ${cardData.maxCountries} Locations`
                : `All ${cardData.maxCountries} Locations Enabled`}
            </Link>
            <Modal open={locationModalOpen} onClose={handleCloseModals}>
              <Card sx={modalStyle}>
                <CardHeader
                  title={
                    <Typography variant="h6" component="h2">
                      Enabled locations
                    </Typography>
                  }
                  action={
                    <IconButton onClick={handleCloseModals}>
                      <Icon name="close" />
                    </IconButton>
                  }
                />
                {locations ? (
                  <List sx={{ overflow: "auto", maxHeight: 400 }}>
                    {locations.map((location) => (
                      <ListItem key={location}>
                        <ListItemText primary={location} />
                      </ListItem>
                    ))}
                  </List>
                ) : (
                  <Typography my={2} ml={2}>
                    All locations are enabled for this product.
                  </Typography>
                )}
                <Divider
                  sx={{
                    mb: 2,
                    borderColor: "rgba(0, 0, 0, 0.08)",
                  }}
                />
                <Link
                  href={`/${getApiMetadata(apiName).productDataType}?tab=${
                    getApiMetadata(apiName).docPath
                  }#${cardData.locAnchor}`}
                  underline="none"
                  sx={{ ml: 2 }}
                >
                  Show available locations
                </Link>
              </Card>
            </Modal>
          </>
        )}
      </Box>
    </>
  );
};

export default ActiveApiRequests;
