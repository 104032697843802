import React, { useState, useContext, useEffect } from "react";
import {
  useNavigate,
  useLocation,
  Link as ReactRouterLink,
} from "react-router-dom";
import {
  Box,
  Drawer,
  List,
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Menu,
  MenuItem,
  Link,
  Divider,
  Typography,
  useMediaQuery,
  IconButton,
  Collapse,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "../atoms/Icon";
import { handleLogout } from "../../util/helpers";
import { AppContext } from "../_functional/appContext";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: 0,
  svg: {
    marginRight: theme.spacing(2),
  },
  a: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    color: theme.palette.text.primary,
    padding: theme.spacing(1.5, 4),
  },
  "&.condensed": {
    a: {
      padding: theme.spacing(0.75, 4),
      fontSize: 14,
      lineHeight: "24px",
    },
  },
}));

const SidebarDrawer = styled(Drawer)(({ theme }) => ({
  ".MuiDrawer-paper": {
    background: "#212631",
    width: 280,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
}));

const StyledListItem = styled(ListItemButton)(({ theme }) => ({
  color: "#fff",
  padding: theme.spacing(1, 3),
  transition: "0.4s",
  "&.Mui-selected": {
    background: "rgba(190, 204, 0, 0.16) !important",
  },
  "&:hover": {
    background: "rgba(190, 204, 0, 0.08)",
  },
}));

const Sidebar = () => {
  let navigate = useNavigate();
  let location = useLocation();

  const matches = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const { state, dispatch } = useContext(AppContext);

  const [moreInfoEl, setMoreInfoEl] = useState(null);
  const [helpCenterOpen, setHelpCenterOpen] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setMoreInfoEl(null);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const toggleHelpCenter = () => {
    setHelpCenterOpen(!helpCenterOpen);
  };

  const toggleProfile = () => {
    setProfileOpen(!profileOpen);
  };

  const handleMoreInfoClick = (event) => {
    setMoreInfoEl(event.currentTarget);
  };

  const handleClose = () => {
    setMoreInfoEl(null);
  };

  const apiNav = [
    {
      name: "Blogs",
      api_types: "Search, LiveFeed",
      link: "blogs?tab=builder",
      key: "blog_search",
    },
    {
      name: "News",
      api_types: "Search, LiveFeed",
      link: "news?tab=builder",
      key: "news_b_search",
    },
    {
      name: "Forums",
      api_types: "Search",
      link: "forums?tab=builder",
      key: "forum_c_search",
    },
    {
      name: "Reviews",
      api_types: "Search",
      link: "reviews?tab=builder",
      key: "review_c_search",
    },
    {
      name: "Dark Web",
      api_types: "Search",
      link: "dark_web?tab=builder",
      key: "dark_web_a_search",
    },
    {
      name: "VK",
      api_types: "Search",
      link: "vk?tab=builder",
      key: "vk_c_search",
    },
  ];

  const handleLogoutClick = () => {
    handleLogout();
    navigate("/login");
  };

  const handleHideSidebar = () => {
    dispatch({
      type: "HIDE_SIDEBAR",
    });
  };

  return (
    <SidebarDrawer
      open={matches ? state.sidebarOpen : true}
      variant={matches ? "temporary" : "permanent"}
      onClose={handleHideSidebar}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pt: 3,
          pl: 4,
          pr: 2,
          mb: 3,
        }}
      >
        <Link
          sx={{
            svg: {
              height: 48,
              width: "auto",
            },
          }}
          href="/"
          data-testid="sidebar-link-logo"
        >
          <Icon name="logo" />
        </Link>
        {matches && (
          <IconButton
            onClick={handleHideSidebar}
            sx={{
              background: "#313949",
              "svg path": {
                fill: "white",
                fillOpacity: 1,
              },
            }}
          >
            <Icon name="close" />
          </IconButton>
        )}
      </Box>

      <List disablePadding>
        <StyledListItem
          selected={location.pathname === "/"}
          component={ReactRouterLink}
          to="/"
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <Icon name="dashboard" />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </StyledListItem>
      </List>
      <Typography
        sx={{
          textTransform: "uppercase",
          color: "rgba(255, 255, 255, 0.5)",
          px: 3,
          mt: 4,
          mb: 1,
        }}
        variant="caption"
      >
        Api Products
      </Typography>
      <List disablePadding>
        {apiNav.map((navItem) => (
          <StyledListItem
            key={navItem.key}
            component={ReactRouterLink}
            to={`/${navItem.link}`}
            selected={location.pathname.includes(navItem.key)}
            sx={{ pt: 0, pb: 0 }}
          >
            <ListItemIcon sx={{ minWidth: 40 }}>
              <Icon name={navItem.key} />
            </ListItemIcon>
            <ListItemText
              primary={navItem.name}
              secondary={navItem.api_types}
              secondaryTypographyProps={{
                sx: {
                  color: "rgba(255, 255, 255, 0.5)",
                },
              }}
            />
          </StyledListItem>
        ))}
      </List>
      <Box mt={{ xs: 0, md: "auto" }}>
        <Divider
          sx={{
            borderColor: "rgba(60,60,60,0.4)",
            mt: 1,
            mb: 1,
          }}
        />
        <List disablePadding>
          <StyledListItem onClick={toggleHelpCenter}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <Icon name="helpCenter" />
            </ListItemIcon>
            <ListItemText primary="Help Center" />
            {helpCenterOpen ? (
              <Icon name="expandLess" />
            ) : (
              <Icon name="expandMore" />
            )}
          </StyledListItem>
          <Collapse in={helpCenterOpen} timeout="auto">
            <List disablePadding>
              <StyledListItem
                selected={location.pathname === "/contact-sales"}
                component={ReactRouterLink}
                to="/contact-sales"
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Icon name="emailGray" />
                </ListItemIcon>
                <ListItemText primary="Contact Sales" />
              </StyledListItem>
              <StyledListItem
                selected={location.pathname === "/request-sources"}
                component={ReactRouterLink}
                to="/request-sources"
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Icon name="add" />
                </ListItemIcon>
                <ListItemText primary="Request Sources" />
              </StyledListItem>
              <StyledListItem
                selected={location.pathname === "/support"}
                component={ReactRouterLink}
                to="/support"
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Icon name="support" />
                </ListItemIcon>
                <ListItemText primary="Support" />
              </StyledListItem>
            </List>
            <Divider
              sx={{
                borderColor: "rgba(60,60,60,0.4)",
                mt: 1,
                mb: 1,
              }}
            />
          </Collapse>
          <StyledListItem onClick={handleMoreInfoClick}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <Icon name="info" />
            </ListItemIcon>
            <ListItemText primary="More Info" />
          </StyledListItem>
          <StyledListItem onClick={toggleProfile}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <Icon name="profile" />
            </ListItemIcon>
            <ListItemText primary="Profile" />
            {profileOpen ? (
              <Icon name="expandLess" />
            ) : (
              <Icon name="expandMore" />
            )}
          </StyledListItem>
          <Collapse in={profileOpen} timeout="auto">
            <List disablePadding>
              <StyledListItem
                selected={location.pathname === "/profile"}
                component={ReactRouterLink}
                to="/profile"
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Icon name="settings" />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </StyledListItem>
              <StyledListItem onClick={handleLogoutClick}>
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <Icon name="logout" />
                </ListItemIcon>
                <ListItemText primary="Log Out" />
              </StyledListItem>
            </List>
          </Collapse>
        </List>
      </Box>

      <Menu
        elevation={1}
        id="more-info-menu"
        anchorEl={moreInfoEl}
        keepMounted
        open={Boolean(moreInfoEl)}
        onClose={handleClose}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          ".MuiMenu-list": {
            py: 2,
          },
          ".MuiMenu-paper": {
            width: 264,
            borderRadius: 4,
            left: "8px !important",
          },
        }}
      >
        <StyledMenuItem>
          <Link
            color="text"
            underline="none"
            target="_blank"
            href="https://www.twingly.com/"
          >
            <Icon name="home" />
            {"Twingly.com"}
          </Link>
        </StyledMenuItem>
        <StyledMenuItem>
          <Link
            color="text"
            underline="none"
            target="_blank"
            href="https://www.twingly.com/blog"
          >
            <Icon
              name="blog_search"
              style={{ fillOpacity: "0.56", fill: "black" }}
            />
            {"Our Blog"}
          </Link>
        </StyledMenuItem>
        <StyledMenuItem>
          <Link
            color="text"
            underline="none"
            target="_blank"
            href="/public-docs"
          >
            <Icon name="blogGray" />
            {"Public Docs"}
          </Link>
        </StyledMenuItem>
        <Divider component="li" />
        <StyledMenuItem className="condensed">
          <Link
            color="text"
            underline="none"
            target="_blank"
            href="https://www.twingly.com/privacy-policy"
          >
            {"Privacy Policy"}
          </Link>
        </StyledMenuItem>
        <StyledMenuItem className="condensed">
          <Link
            color="text"
            underline="none"
            target="_blank"
            href="https://www.twingly.com/terms"
          >
            {"Terms of Service"}
          </Link>
        </StyledMenuItem>
      </Menu>
    </SidebarDrawer>
  );
};

export default Sidebar;
