import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import AuthImage from "../organisms/AuthImage";

const LoginWrapper = styled(Box)(() => ({
  display: "flex",
  minHeight: "100vh",
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  flex: "0 0 50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("lg")]: {
    flex: "1 1 100%",
  },
}));

const AuthPageLayout = ({ children, form: Form }) => {
  return (
    <LoginWrapper>
      <FormWrapper>
        <Form />
      </FormWrapper>
      <AuthImage>{children}</AuthImage>
    </LoginWrapper>
  );
};

export default AuthPageLayout;
