import React from "react";
import { Box, Typography, Link, Fade } from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "../atoms/Icon";

const StatusIndicator = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  padding: theme.spacing(0.5, 1.5, 0.5, 1.5),
  transition: "all .2s ease",
  borderRadius: "4px",
  "&:hover": {
    backgroundColor: "rgba(95, 102, 0, 0.04)",
  },
  ".MuiLink-root": {
    display: "flex",
    alignItems: "center",
  },
  ".text": {
    fontSize: 14,
    marginLeft: theme.spacing(1),
  },

  "&.none": {
    ".text": {
      color: "#2E7D32",
    },
    "svg > *": {
      fill: "#4CAF50",
    },
  },
  "&.maintenance": {
    ".text": {
      color: "#0288D1",
    },
    "svg > *": {
      fill: "#03A9F4",
    },
  },
  "&.minor": {
    ".text": {
      color: "#ED6C02",
    },
    "svg > *": {
      fill: "#FF9800",
    },
  },
  "&.major": {
    ".text": {
      color: "#ED6C02",
    },
    "svg > *": {
      fill: "#FF9800",
    },
  },
  "&.critical": {
    ".text": {
      color: "#D32F2F",
    },
    "svg > *": {
      fill: "#EF5350",
    },
  },
  [theme.breakpoints.down("lg")]: {
    marginLeft: 0,
    paddingLeft: 0,
  },
}));

const SystemStatusIndicator = ({ systemStatus }) => {
  return (
    <Fade in={!!systemStatus?.text || !!systemStatus?.text}>
      <StatusIndicator className={systemStatus?.color}>
        <Link
          href="https://status.twingly.com"
          target="_blank"
          underline="none"
        >
          <Icon name="statusIndicator" />
          <Typography className="text">{systemStatus?.text}</Typography>
        </Link>
      </StatusIndicator>
    </Fade>
  );
};

export default SystemStatusIndicator;
