// Entry point for the build script in your package.json
import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import { initLinkedInTracking } from "./components/_functional/LinkedInTracker";
import TagManager from "react-gtm-module";

Sentry.init({
  dsn: sentryDsn,
  release:
    herokuSlugCommit === "unknown_heroku_slug_commit" ? null : herokuSlugCommit,
});

const tagManagerArgs = {
  gtmId: googleTagManagerId,
  auth: googleTagManagerAuth,
  preview: googleTagManagerPreview,
};

TagManager.initialize(tagManagerArgs);

initLinkedInTracking();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
