import React, { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import FilterMultipleText from "../../molecules/FilterMultipleText";
import FilterTextInput from "../../molecules/FilterTextInput";
import FilterSelectAutocomplete from "../../molecules/FilterSelectAutocomplete";
import FilterDateInput from "../../molecules/FilterDateInput";
import DeleteRuleButton from "../../molecules/DeleteRuleButton";
import TooltipButton from "../../molecules/TooltipButton";
import reviewSearchSettings from "../../../constants/queryBuilder/reviewSearch";

const ReviewSearchFields = ({
  watch,
  setValue,
  rules,
  handleChange,
  removeRule,
  apiData,
}) => {
  const sortByRelevance = watch("sort").value === "relevance";

  useEffect(() => {
    if (sortByRelevance) {
      setValue("order", { value: "desc", label: "Desc" });
      handleChange("order", { value: "desc", label: "Desc" });
    }
  }, [sortByRelevance]);

  return (
    <>
      {rules.includes("all") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="all"
              label="Match All"
            />
          </Box>
          <DeleteRuleButton removeRule={removeRule} rulesToRemove={["all"]} />
        </Box>
      )}
      {rules.includes("any") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="any"
              label="Match Any"
            />
          </Box>
          <DeleteRuleButton removeRule={removeRule} rulesToRemove={["any"]} />
        </Box>
      )}
      {rules.includes("none") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="none"
              label="Match None"
            />
          </Box>
          <DeleteRuleButton removeRule={removeRule} rulesToRemove={["none"]} />
        </Box>
      )}
      {rules.includes("domain") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterTextInput
              autoFocus
              handleInputChange={handleChange}
              name="domain"
              label="Search Domain"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["domain"]}
          />
        </Box>
      )}
      {rules.includes("exclude_domain") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterTextInput
              autoFocus
              handleInputChange={handleChange}
              name="exclude_domain"
              label="Exclude Domain"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["exclude_domain"]}
          />
        </Box>
      )}
      {rules.includes("item_url") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterTextInput
              autoFocus
              handleInputChange={handleChange}
              name="item_url"
              label="Item URL"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["item_url"]}
          />
        </Box>
      )}
      {rules.includes("item_name") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterTextInput
              autoFocus
              handleInputChange={handleChange}
              name="item_name"
              label="Item Name"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["item_name"]}
          />
        </Box>
      )}
      {rules.includes("item_address") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterTextInput
              autoFocus
              handleInputChange={handleChange}
              name="item_address"
              label="Item Address"
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["item_address"]}
          />
        </Box>
      )}
      {rules.includes("languages") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterSelectAutocomplete
              autoFocus
              handleInputChange={handleChange}
              options={reviewSearchSettings.languages}
              name="languages"
              label="Languages"
              enabledOptions={apiData.enabledLanguages}
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["languages"]}
          />
        </Box>
      )}
      {rules.includes("locations") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterSelectAutocomplete
              autoFocus
              handleInputChange={handleChange}
              options={reviewSearchSettings.locations}
              name="locations"
              label="Locations"
              enabledOptions={apiData.enabledLocations}
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["locations"]}
          />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Box
          gap={{ xs: 0, md: 2 }}
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {rules.includes("publishedSince") && (
            <Box sx={{ flex: { md: "0 1 240px" } }}>
              <FilterDateInput
                handleInputChange={handleChange}
                label="Published Since"
                name="publishedSince"
              />
            </Box>
          )}
          {rules.includes("publishedUntil") && (
            <Box sx={{ flex: { md: "0 1 240px" } }}>
              <FilterDateInput
                handleInputChange={handleChange}
                minDate={new Date(watch("publishedSince"))}
                label="Published Until"
                name="publishedUntil"
              />
            </Box>
          )}
        </Box>
        {(rules.includes("publishedSince") ||
          rules.includes("publishedUntil")) && (
          <DeleteRuleButton
            forTwoFields
            removeRule={removeRule}
            rulesToRemove={["publishedSince", "publishedUntil"]}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Box
          gap={{ xs: 0, md: 2 }}
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {rules.includes("sort") && (
            <Box sx={{ flex: { md: "0 1 240px" } }}>
              <FilterSelectAutocomplete
                sortAlphabetically={false}
                handleInputChange={handleChange}
                multiple={false}
                options={[
                  { label: "Published Time", value: "published_time" },
                  { label: "Inserted Time", value: "inserted_time" },
                  { label: "Relevance", value: "relevance" },
                ]}
                name="sort"
                label="Sort by"
              />
            </Box>
          )}
          {rules.includes("order") && (
            <Box sx={{ flex: { md: "0 1 240px" } }}>
              <FilterSelectAutocomplete
                sortAlphabetically={false}
                multiple={false}
                handleInputChange={handleChange}
                disabled={sortByRelevance}
                options={
                  sortByRelevance
                    ? [{ label: "Desc", value: "desc" }]
                    : [
                        { label: "Desc", value: "desc" },
                        { label: "Asc", value: "asc" },
                      ]
                }
                name="order"
                label="Order"
              />
            </Box>
          )}
        </Box>
        {(rules.includes("order") || rules.includes("sort")) && (
          <DeleteRuleButton
            forTwoFields
            removeRule={removeRule}
            rulesToRemove={["order", "sort"]}
          />
        )}
      </Box>

      <Box
        gap={{ xs: 0, md: 6 }}
        sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
      >
        {rules.includes("size") && (
          <Box display="flex" alignItems="flex-start">
            <Box sx={{ width: 172 }}>
              <FilterTextInput
                autoFocus
                handleInputChange={handleChange}
                name="size"
                type="number"
                label="Number of Results"
                maxNumberInput={100}
              />
            </Box>
            <DeleteRuleButton
              removeRule={removeRule}
              rulesToRemove={["size"]}
            />
          </Box>
        )}
        {rules.includes("offset") && (
          <Box display="flex" alignItems="flex-start">
            <Box sx={{ width: 172 }}>
              <FilterTextInput
                autoFocus
                handleInputChange={handleChange}
                name="offset"
                type="number"
                label="Offset"
              />
            </Box>
            <TooltipButton
              title={
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                  color="inherit"
                >
                  The number of documents to offset (skip), can be used to
                  retrieve additional results for a search query. Can only be
                  used for getting up to 10,000 matches.
                </Typography>
              }
            />
            <DeleteRuleButton
              removeRule={removeRule}
              rulesToRemove={["offset"]}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ReviewSearchFields;
