import React, { useEffect, useState } from "react";
import request from "../util/request";
import {
  Box,
  Typography,
  Paper,
  List,
  ListItem,
  Divider,
  Button,
  Link,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PageLayout from "../components/templates/PageLayout";
import PageHeader from "../components/templates/PageHeader";
import PageTitleUpdater from "../components/_functional/PageTitleUpdater";

const HomeWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 3),
  ".title": {
    flex: "0 0 40%",
  },
  ".value": {
    flex: "1 0 30%",
  },
  ".MuiListItem-root": {
    padding: theme.spacing(1.5, 2),
  },
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(2, 1),
  },
  [theme.breakpoints.down("md")]: {
    ".MuiListItem-root": {
      display: "block",
    },
  },
}));

const Profile = () => {
  const [user, setUser] = useState();

  const [changePassword, setChangePassword] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);

  useEffect(() => {
    request("/api/v1/user_accounts/show").then((res) => {
      setUser(res.data.data);
    });
  }, []);

  const toggleChangePassword = () => {
    setChangePassword(!changePassword);
  };

  const handleChangePassword = () => {
    request({
      method: "post",
      url: "/api/v1/user_accounts/reset_password",
      data: { email: user.email },
    }).then(() => {
      toggleChangePassword();
      setChangePasswordSuccess(true);
    });
  };

  return (
    <PageLayout>
      <PageHeader pageHeader={"Menu"} pageTabs={false} titleHeader={false} />
      <PageTitleUpdater pageTitle="Profile Settings" />
      <HomeWrapper>
        <Typography component="h1" variant="h5" fontWeight={700}>
          Profile Settings
        </Typography>

        <Paper
          elevation={0}
          sx={{
            borderRadius: "8px",
            p: { xs: 1, lg: 4 },
            mt: 4,
            maxWidth: 640,
          }}
        >
          <List>
            <ListItem>
              <Typography className="title">Name</Typography>
              <Typography className="value" color="textSecondary">
                {user?.name}
              </Typography>
            </ListItem>
            <Divider />
            <ListItem>
              <Typography className="title">Company</Typography>
              <Typography className="value" color="textSecondary">
                {user?.company}
              </Typography>
            </ListItem>
            <Divider />
            <ListItem>
              <Typography className="title">Email</Typography>
              <Typography className="value" color="textSecondary">
                {user?.email}
              </Typography>
            </ListItem>
            <Divider />
            <ListItem sx={{ height: { md: 48 } }}>
              <Typography className="title">Password</Typography>
              <Typography className="value" color="textSecondary">
                ************
              </Typography>
              {!changePassword && !changePasswordSuccess && (
                <Button
                  size="small"
                  onClick={toggleChangePassword}
                  ml="auto"
                  sx={{
                    ml: -0.5,
                  }}
                  variant="text"
                >
                  Change password
                </Button>
              )}
            </ListItem>
          </List>
          {changePassword && (
            <>
              <Typography pl={2} pt={1}>
                Send instructions to reset my password to
                <Link sx={{ ml: "0.5ch" }} underline="none">
                  {user?.email}
                </Link>
              </Typography>
              <Box pl={2} pt={3} pb={{ md: 0, xs: 3 }} display="flex" gap={1}>
                <Button
                  onClick={handleChangePassword}
                  size="small"
                  variant="contained"
                >
                  Reset password
                </Button>
                <Button
                  onClick={toggleChangePassword}
                  size="small"
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Box>
            </>
          )}
          {changePasswordSuccess && (
            <Alert
              sx={{
                ".MuiAlert-action": {
                  alignItems: "center",
                },
              }}
              action={
                <Button
                  aria-label="close"
                  size="small"
                  variant="outlined"
                  onClick={() => {
                    setChangePasswordSuccess(false);
                  }}
                >
                  Done
                </Button>
              }
              severity="success"
            >
              We&rsquo;ve just sent you an email to reset your password.
            </Alert>
          )}
        </Paper>
      </HomeWrapper>
    </PageLayout>
  );
};

export default Profile;
