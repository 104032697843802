import React from "react";
import {
  Box,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import Icon from "../atoms/Icon";
import addRuleOptions from "../../constants/queryBuilder/addRuleOptions";

const NestedListItem = ({
  rules,
  label,
  nestedItems,
  addRule,
  closePopover,
  openModal,
}) => {
  const [anchorElNested, setAnchorElNested] = React.useState(null);

  const handleNestedClose = () => {
    setAnchorElNested(null);
  };

  const handleNestedClick = (event) => {
    setAnchorElNested(event.currentTarget);
  };

  const openNested = Boolean(anchorElNested);
  const idNested = openNested ? "nested-rules" : undefined;

  const handleAddRule = (key) => {
    addRule(key);
    handleNestedClose();
    closePopover();
  };

  return (
    <>
      <ListItem selected={openNested} disablePadding>
        <ListItemButton
          onClick={handleNestedClick}
          sx={{
            py: 0.75,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {label} <Icon name="arrowRight" />
        </ListItemButton>
      </ListItem>
      <Popover
        id={idNested}
        open={openNested}
        anchorEl={anchorElNested}
        onClose={handleNestedClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <List
          data-testid="add-rule-nested-popover"
          sx={{
            minWidth: 160,
          }}
        >
          {nestedItems.map((itemNested) => (
            <ListItem
              sx={{ whiteSpace: "nowrap" }}
              key={itemNested.key}
              disablePadding
            >
              <ListItemButton
                data-testid={`add-filter-${itemNested.key}`}
                disabled={
                  rules &&
                  (rules.includes(itemNested.key) ||
                    rules.includes(itemNested.disableIfExists))
                }
                onClick={() =>
                  handleAddRule(
                    openModal
                      ? itemNested
                      : itemNested.keys
                      ? [...itemNested.keys]
                      : itemNested.key
                  )
                }
                sx={{
                  py: 0.75,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {itemNested.label}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

const AddRuleButton = ({ rules, queryName, addRule, openModal }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "add-rule" : undefined;

  const handleAddRule = (rule) => {
    addRule(rule);
    handleClose();
  };

  const options = addRuleOptions[queryName];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          cursor: "pointer",
          alignItems: "center",

          ".icon": {
            display: "flex",
            transition: "all .2s ease",
            p: 0.5,
            borderRadius: "50%",
            background: "#F5F5F5",
            svg: {
              transition: "all .2s ease",
            },
            "&.open": {
              background: "rgba(114, 122, 0, 0.08)",
              svg: {
                path: {
                  fill: "#727A00",
                },
              },
            },
          },
          "&:hover": {
            ".name": {
              color: "#727A00",
            },
            ".icon": { background: "rgba(114, 122, 0, 0.08)" },
            svg: {
              path: {
                fill: "#727A00",
              },
            },
          },
        }}
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <Box className={`icon ${open ? "open" : ""}`}>
          <Icon name="plus" />
        </Box>
        <Typography className="name" fontWeight={500} ml={1}>
          Add Filter
        </Typography>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <List
          data-testid="add-rule-popover"
          sx={{
            minWidth: 160,
          }}
        >
          {options?.map((item, index) =>
            item.nested ? (
              <NestedListItem
                nestedItems={item.nested}
                key={`nested-${index}`}
                label={item.label}
                addRule={addRule}
                closePopover={handleClose}
                rules={rules}
                openModal={openModal}
              />
            ) : (
              <ListItem key={`${item.key || "nested"}-${index}`} disablePadding>
                <ListItemButton
                  data-testid={`add-filter-${item.key}`}
                  disabled={
                    rules &&
                    (rules.includes(item.key) ||
                      rules.includes(item.disableIfExists) ||
                      (item.keys &&
                        rules.some((rule) => item.keys.indexOf(rule) >= 0)))
                  }
                  onClick={() =>
                    handleAddRule(
                      openModal ? item : item.keys ? [...item.keys] : item.key
                    )
                  }
                  sx={{
                    py: 0.75,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {item.label} {item.nested && <Icon name="arrowRight" />}
                </ListItemButton>
              </ListItem>
            )
          )}
        </List>
      </Popover>
    </>
  );
};

export default AddRuleButton;
