import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const ImageWrapper = styled(Box)(({ theme }) => ({
  position: "fixed",
  left: "50%",
  top: 0,
  right: 0,
  bottom: 0,
  overflow: "hidden",
  minHeight: "100vh",
  ".img-wrap": {
    "&:after": {
      zIndex: 2,
      content: `""`,
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background:
        "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%)",
    },
  },
  img: {
    zIndex: 1,
    position: "absolute",
    minHeight: "100%",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
  },
  ".text": {
    maxWidth: 400,
    zIndex: 2,
    position: "absolute",
    bottom: 80,
    left: 80,
  },

  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
}));

const AuthImage = ({ children }) => {
  return (
    <ImageWrapper>
      <Box className="img-wrap">
        <img src={"/front_page_image.jpeg"} alt={"login"} />
      </Box>
      <Box className="text">{children}</Box>
    </ImageWrapper>
  );
};

export default AuthImage;
