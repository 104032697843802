import React from "react";
import { Box, Typography } from "@mui/material";
import Icon from "../atoms/Icon";

const ResultsNoResults = () => {
  return (
    <Box>
      <Box
        sx={{
          px: 2,
          py: 3,
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          svg: {
            width: 24,
            height: 24,
          },
        }}
      >
        <Icon name="infoHollow" />
        <Typography ml={1} fontWeight={500}>
          No Results
        </Typography>
      </Box>
      <Box px={2} py={3}>
        <Typography>Create a query by adding filters above</Typography>
      </Box>
    </Box>
  );
};

export default ResultsNoResults;
