import React, { useContext } from "react";
import { IconButton } from "@mui/material";
import Icon from "../atoms/Icon";
import { AppContext } from "../_functional/appContext";

const ToggleSidebarButton = () => {
  const { state, dispatch } = useContext(AppContext);
  const toggleDrawer = () => {
    dispatch({
      type: state.sidebarOpen ? "HIDE_SIDEBAR" : "SHOW_SIDEBAR",
    });
  };
  return (
    <IconButton sx={{ display: { lg: "none" } }} onClick={toggleDrawer}>
      <Icon name="drawer" />
    </IconButton>
  );
};

export default ToggleSidebarButton;
