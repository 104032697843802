import React, { useState, useEffect } from "react";
import { Button, Fade, Typography, Box } from "@mui/material";

const CopyCodeButton = ({ handleCopy, title }) => {
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 1500);
    }
  }, [copied]);

  const handleCopyClick = () => {
    handleCopy();
    setCopied(true);
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Button sx={{ mt: 2 }} variant="outlined" onClick={handleCopyClick}>
        {title}
      </Button>
      <Fade in={copied}>
        <Typography
          sx={{
            position: "absolute",
            bottom: "110%",
            left: 0,
            display: "inline-block",
            py: 0.5,
            px: 2,
            color: "white",
            borderRadius: "6px",
            background: "rgba(0, 0, 0, 0.7)",
            zIndex: 1,
          }}
        >
          Code Copied!
        </Typography>
      </Fade>
    </Box>
  );
};

export default CopyCodeButton;
