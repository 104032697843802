import React from "react";
import {
  Grid,
  Link,
  Button,
  Card,
  CardActions,
  Typography,
} from "@mui/material";
import ApiCardHeader from "../molecules/ApiCardHeader";
import ApiFeatures from "../molecules/ApiFeatures";
import features from "../../constants/disabledApiFeatures";
import getApiMetadata from "../../constants/nameConstants";
import { toast } from "react-toastify";
import { activateProductTrial } from "../../util/helpers";

const DisabledAPICard = ({ item, setActiveApis, setDisabledApis }) => {
  const cardData = features[item.apiName];

  const handleActivate = () => {
    // add_customer
    activateProductTrial(item.apiName)
      .then(() => {
        setActiveApis((prevActiveApis) => [
          ...prevActiveApis,
          { apiName: item.apiName, requestsNumber: 0 },
        ]);
        setDisabledApis((prevDisabledApis) =>
          prevDisabledApis.filter(
            (disabledApi) => disabledApi.apiName != item.apiName
          )
        );

        toast.success("Trial activated!");
      })
      .catch(() => {
        toast.error("Something went wrong, please try again.");
      });
  };

  const handleClick = () => {
    if (cardData.contactSalesButton) {
      window.location.href = "/contact-sales";
    } else {
      handleActivate();
    }
  };

  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card
        data-testid={`${item.apiName}-disabled`}
        elevation={0}
        sx={{
          display: "flex",
          flexDirection: "column",
          height: 320,
          px: 2,
          pt: 1,
          pb: 2,
          borderRadius: "12px",
        }}
      >
        <ApiCardHeader name={item.apiName} />

        <ApiFeatures name={item.apiName} />

        <Link
          underline="none"
          href={`${getApiMetadata(item.apiName).productDataType}?tab=${
            getApiMetadata(item.apiName).docPath
          }`}
        >
          Documentation
        </Link>

        <Typography
          fontSize={14}
          mt={"auto"}
          mb={1}
          dangerouslySetInnerHTML={{ __html: cardData.description }}
        />

        <CardActions sx={{ p: 0 }}>
          <Button
            onClick={handleClick}
            sx={{ minHeight: 40, px: 3 }}
            variant="contained"
          >
            {cardData.contactSalesButton ? "Contact Sales" : "Activate Trial"}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

export default DisabledAPICard;
