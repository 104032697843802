import React from "react";
import { IconButton } from "@mui/material";
import Icon from "../atoms/Icon";

const DeleteRuleButton = ({ removeRule, rulesToRemove, sx, forTwoFields }) => {
  return (
    <IconButton
      onClick={() => removeRule(rulesToRemove)}
      sx={{
        mt: { xs: forTwoFields ? 0 : 1.25, md: 1.25 },
        ml: 1,
        mr: -1,
        ...sx,
      }}
    >
      <Icon name="closeMedium" />
    </IconButton>
  );
};

export default DeleteRuleButton;
