import React from "react";
import { Box, Typography, Button } from "@mui/material";
import Icon from "../atoms/Icon";
import features from "../../constants/disabledApiFeatures";
import getApiMetadata from "../../constants/nameConstants";
import { toast } from "react-toastify";
import { activateProductTrial } from "../../util/helpers";

const ResultsNoAccess = ({ apiName, setApiData }) => {
  const handleButtonClick = () => {
    if (apiName === "dark_web_a_search") {
      window.location.href = "/contact-sales";
    } else {
      activateProductTrial(apiName)
        .then(() => {
          setApiData((prevApiData) => ({ ...prevApiData, enabled: true }));

          toast.success("Trial activated!");
        })
        .catch(() => {
          toast.error("Something went wrong, please try again.");
        });
    }
  };
  return (
    <Box>
      <Box
        sx={{
          px: 2,
          py: 3,
          borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon name="limitedAccess" />
        <Typography ml={1} fontWeight={500}>
          No Access
        </Typography>
      </Box>
      <Box px={2} py={3}>
        <Typography>
          The {getApiMetadata(apiName).productName} API is Not Active for this
          API key
        </Typography>
        <Typography>{features[apiName].description}</Typography>
      </Box>
      <Box px={2} pb={3}>
        <Button onClick={handleButtonClick} variant="contained">
          {apiName === "dark_web_a_search" ? "Contact Sales" : "Activate Trial"}
        </Button>
      </Box>
    </Box>
  );
};
export default ResultsNoAccess;
