import React from "react";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import DocumentationToc from "./DocumentationToc";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  padding: theme.spacing(3),
  alignItems: "flex-start",
  flexDirection: "row",
  ".wrap": {
    display: "flex",
    flexDirection: "column",
    maxWidth: 768,
    order: 1,
  },
  [theme.breakpoints.down(1400)]: {
    padding: theme.spacing(2, 1),
    flexDirection: "column",
    ".wrap": {
      maxWidth: "100%",
      order: 2,
    },
  },
  [theme.breakpoints.down("md")]: {
    ".wrap": {
      marginBottom: theme.spacing(7),
    },
  },
}));

const DocumentationWrap = ({ children, docs }) => {
  return (
    <Wrapper gap={2}>
      <Box gap={2} className="wrap">
        {children}
      </Box>
      <DocumentationToc docs={docs} />
    </Wrapper>
  );
};

export default DocumentationWrap;
