import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Box, Paper, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import ToTopButton from "../../components/molecules/ToTopButton";
import CollapsedDocsChapter from "../../components/organisms/CollapsedDocsChapter";
import PageLayout from "../../components/templates/PageLayout";
import PageHeader from "../../components/templates/PageHeader";
import QueryBuilderLayout from "../../components/templates/QueryBuilderLayout";
import MarkdownParser from "../../components/templates/MarkdownParser";
import VkSearchFields from "../../components/templates/builder-fields/VkSearchFields";
import SearchDocumentation from "../../documentation/vk_search.md";
import Languages from "../../documentation/vk_languages.md";
import BestPractices from "../../documentation/best_practices.md";
import NormalizedKeywords from "../../documentation/socialgist_keywords.md";
import vkSearchSettings from "../../constants/queryBuilder/vkSearch";
import { getParamFromUrl, delayedScrollIntoView } from "../../util/helpers";
import DocumentationWrap from "../../components/molecules/DocumentationWrap";
import PageTitleUpdater from "../../components/_functional/PageTitleUpdater";

const Wrapper = styled(Box)(() => ({
  background: "#EAEBE0",
  minHeight: "100vh",
}));

const VKPage = () => {
  let location = useLocation();

  const tab = getParamFromUrl(location.search, "tab");

  useEffect(() => {
    const scrollTo = document.getElementById(location.hash.replace("#", ""));
    delayedScrollIntoView(scrollTo);
  }, [location.hash]);

  const [loaded, setLoaded] = useState(false);

  return (
    <PageLayout>
      <Wrapper>
        <PageHeader pageHeader="VK" />
        {tab === "builder" && (
          <>
            <PageTitleUpdater pageTitle="VK Search Query Builder" />
            {!loaded && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <CircularProgress />
              </Box>
            )}
            <QueryBuilderLayout
              setLoaded={setLoaded}
              loaded={loaded}
              fields={VkSearchFields}
              settings={vkSearchSettings}
              curlEndpoint="https://data.twingly.net/vk/c/search/v1/search"
              apiName="vk_c_search"
            />
          </>
        )}
        {tab === "search_documentation" && (
          <DocumentationWrap docs={SearchDocumentation}>
            <PageTitleUpdater pageTitle="VK Search Documentation" />
            <Paper elevation={0} sx={{ p: 3, borderRadius: "8px" }}>
              <MarkdownParser docs={SearchDocumentation} />
            </Paper>
            <CollapsedDocsChapter
              currentHash={location.hash.replace("#", "")}
              name="Languages"
              id="list-languages"
            >
              <MarkdownParser docs={Languages} />
            </CollapsedDocsChapter>
            <CollapsedDocsChapter
              currentHash={location.hash.replace("#", "")}
              name="Best practices"
              id="list-best-practices"
            >
              <MarkdownParser docs={BestPractices} />
            </CollapsedDocsChapter>
            <CollapsedDocsChapter
              currentHash={location.hash.replace("#", "")}
              name="Normalized keywords"
              id="list-normalized-keywords"
            >
              <MarkdownParser docs={NormalizedKeywords} />
            </CollapsedDocsChapter>
            <ToTopButton />
          </DocumentationWrap>
        )}
      </Wrapper>
    </PageLayout>
  );
};

export default VKPage;
