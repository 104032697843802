import React from "react";
import { Box, Typography, Link } from "@mui/material";
import Icon from "../atoms/Icon";
import { styled } from "@mui/material/styles";
import PreviewTextContent from "../molecules/PreviewTextContent";
import formatResultBasedOnApi from "../../util/apiFormatters";

const ItemCard = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
  "&:last-child": {
    borderBottom: "none",
  },
}));

const InfoItem = ({ name, data, capitalize }) => (
  <Typography mr={1} fontSize={14}>
    {name}
    <Typography
      component="span"
      color="text.primary"
      sx={{
        fontSize: 14,
        ml: 1,
        fontWeight: 500,
        textTransform: capitalize ? "uppercase" : "initial",
        wordBreak: "break-all",
      }}
    >
      {data}
    </Typography>
  </Typography>
);

const renderResultItem = (result, index, apiName) => {
  const formattedResult = formatResultBasedOnApi(apiName, result);
  const {
    title,
    titleLink,
    id,
    summary,
    text,
    from,
    fromUrl,
    author,
    date,
    language,
    location,
    idLabel,
    type,
  } = formattedResult;

  return (
    <ItemCard key={index}>
      {title && (
        <Link
          variant="h6"
          target="_blank"
          underline="hover"
          href={titleLink}
          sx={{
            fontWeight: 700,
            mb: 1,
            display: "inline-block",
            wordBreak: "break-all",
          }}
        >
          {title}
        </Link>
      )}
      <PreviewTextContent postId={id} summary={summary} text={text} />
      <Box
        sx={{
          mt: 2,
          color: "text.secondary",
          display: "flex",
          flexWrap: "wrap",
        }}
        gap={1}
      >
        {from && fromUrl && (
          <Typography mr={1} fontSize={14}>
            From
            <Link
              cursor="pointer"
              ml={1}
              underline="hover"
              target="_blank"
              fontWeight={500}
              href={fromUrl}
            >
              {from}
            </Link>
          </Typography>
        )}
        {from && !fromUrl && <InfoItem name="From" data={from} />}
        {author && (
          <InfoItem
            name="Author"
            data={Array.isArray(author) ? author.join(", ") : author}
          />
        )}
        {date && <InfoItem name="Date" data={date} />}
        {language && <InfoItem capitalize name="Language" data={language} />}
        {location && <InfoItem capitalize name="Location" data={location} />}
        {id && <InfoItem name={idLabel} data={id} />}
        {type && <InfoItem name={"Type"} data={type} />}
      </Box>
    </ItemCard>
  );
};

const renderResultsHeader = (resultsFound, totalResults) => (
  <Box
    sx={{
      px: 2,
      py: 3,
      borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
      display: "flex",
      alignItems: "center",
      "&:last-child": {
        borderBottom: "none",
      },
    }}
  >
    <Icon name="checkSuccess" />
    <Typography data-testid="number-of-results" ml={1} fontWeight={500}>
      {`Showing ${resultsFound ? resultsFound : 0} of ${
        totalResults ? totalResults : 0
      } total results`}
    </Typography>
  </Box>
);

const renderResultsList = (results, apiName) =>
  results?.map((result, index) => renderResultItem(result, index, apiName));

const ResultsPreview = ({ rawResults, queryExecuted, apiName }) => {
  const results = rawResults?.documents;

  const resultsFound =
    rawResults?.number_of_documents || rawResults?.number_of_matches_returned;

  const totalResults =
    rawResults?.number_of_documents_estimated_total ||
    rawResults?.number_of_matches_total;

  const resultsList = renderResultsList(results, apiName);
  const resultsHeader = renderResultsHeader(resultsFound, totalResults);

  return (
    queryExecuted && (
      <Box
        sx={{
          fontSize: 14,
          pre: {
            margin: 0,
          },
        }}
      >
        {resultsHeader}
        {resultsList}
      </Box>
    )
  );
};

export default ResultsPreview;
