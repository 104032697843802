import React from "react";
import { Controller } from "react-hook-form";
import { Box, Button, IconButton, TextField } from "@mui/material";
import Icon from "../atoms/Icon";

const UrlInput = ({
  control,
  fields,
  append,
  update,
  remove,
  required,
  validate = true,
}) => {
  const handleUrlChange = (index, e) => {
    if (e.target.value.includes(" ")) {
      const [first_url, ...rest_urls] = prepareStringListOfUrls(e.target.value);
      update(index, { url: first_url });
      addUrlFields(rest_urls);
    } else {
      update(index, { url: e.target.value });
    }
  };

  const prepareStringListOfUrls = (stringOfUrls) => {
    return stringOfUrls.split(" ").filter((e) => e != "");
  };

  const addUrlField = (url = "") => {
    append({ url: url });
  };

  const addUrlFields = (urls) => {
    urls.forEach((url) => {
      addUrlField(url);
    });
  };

  const removeUrlField = (index) => {
    if (fields.length > 1) {
      remove(index);
    } else {
      update(index, { url: "" });
    }
  };

  return (
    <>
      {fields.map((element, index) => (
        <Controller
          key={index}
          name={`urls[${index}]`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id={`urls[${index}]`}
              type={validate ? "url" : "text"}
              label="URL"
              onChange={(e) => handleUrlChange(index, e)}
              onKeyPress={(e) => {
                if (
                  (e.target.getAttribute("type") == "url" ||
                    e.target.getAttribute("type") == "text") &&
                  (e.key === "Enter" || e.key === " ")
                ) {
                  addUrlField();
                  e.preventDefault();
                }
              }}
              autoFocus
              fullWidth
              value={element.url || ""}
              sx={{ mb: 2 }}
              required={required}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => removeUrlField(index)}>
                    <Icon name="delete" />
                  </IconButton>
                ),
              }}
            />
          )}
        />
      ))}

      <Box>
        <Button
          variant="contained"
          sx={{ mt: 2, mb: 3 }}
          onClick={() => addUrlField()}
          color="info"
        >
          Add more
        </Button>
      </Box>
    </>
  );
};

export default UrlInput;
