import React from "react";
import { Button, Modal, Typography, Paper, Box } from "@mui/material";
import FilterMultipleText from "../../molecules/FilterMultipleText";
import FilterSelectAutocomplete from "../../molecules/FilterSelectAutocomplete";
import FilterDateInput from "../../molecules/FilterDateInput";
import { useForm, FormProvider } from "react-hook-form";
import darkWebSettings from "../../../constants/queryBuilder/darkWebSearch";
import { DevTool } from "@hookform/devtools";

const DarkWebSearchFields = ({
  currentRule,
  open,
  handleClose,
  handleAddToQuery,
}) => {
  const formMethods = useForm({
    defaultValues: darkWebSettings.additionalFormDefaultValues,
  });

  const { control, getValues, reset } = formMethods;

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  const handleAddClick = () => {
    handleAddToQuery({
      [currentRule.key]: getValues(currentRule.key),
    });
    reset();
  };
  const handleCloseClick = () => {
    handleClose();
    reset();
  };

  const RenderField = ({ field }) => {
    const props = {
      name: field,
      label: "",
      autoFocus: true,
      handleInputChange: () => {},
    };
    switch (darkWebSettings.inputMap[field]) {
      case "text":
        return <FilterMultipleText soloInput {...props} />;
      case "select":
        return (
          <FilterSelectAutocomplete
            sortAlphabetically={false}
            soloInput
            options={darkWebSettings[field]}
            {...props}
          />
        );
      case "time":
        return (
          <Box
            sx={{
              display: "flex",
            }}
            gap={2}
          >
            <Box sx={{ flex: 1 }}>
              <FilterDateInput
                soloInput
                timestamp
                {...props}
                name="crawled.crawled_since"
                label="Crawled Since"
              />
            </Box>
            <Box sx={{ flex: 1 }}>
              <FilterDateInput
                soloInput
                timestamp
                {...props}
                name="crawled.crawled_until"
                label="Crawled Until"
              />
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="add-rule"
      aria-describedby="add-rule-description"
    >
      <Paper
        data-testid={`${currentRule?.key}-field-popup`}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", md: 688 },
          boxShadow: 24,
          p: 2,
        }}
      >
        <Typography mb={2} id="add-rule" variant="h6" component="h2">
          {currentRule?.label}
        </Typography>

        <Box>
          <FormProvider {...formMethods}>
            <form onSubmit={handleFormSubmit}>
              <RenderField field={currentRule?.key} />
            </form>
            <DevTool control={control} />{" "}
            {/* react-hook-form browser dev tool */}
          </FormProvider>
        </Box>

        <Box
          gap={2}
          sx={{
            mt: 2.5,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button onClick={handleCloseClick}>Cancel</Button>
          <Button
            data-testid={`${currentRule?.key}-add-to-query`}
            onClick={handleAddClick}
            variant="contained"
          >
            Add to Query
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};

export default DarkWebSearchFields;
