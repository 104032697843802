import React, { useEffect, useState } from "react";
import request from "../util/request";
import { Box, Typography, Paper, Link, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import Icon from "../components/atoms/Icon";
import SystemStatusIndicator from "../components/molecules/SystemStatusIndicator";
import ApiKeyButton from "../components/organisms/ApiKeyButton";
import DashboardCards from "../components/templates/DashboardCards";
import ToggleSidebarButton from "../components/organisms/ToggleSidebarButton";
import PageLayout from "../components/templates/PageLayout";
import UsageChart from "../components/templates/UsageChart";
import { formatDate, generateDashboardData } from "../util/helpers";
import PageTitleUpdater from "../components/_functional/PageTitleUpdater";

const HomeWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 3),
  background: "#EAEBE0",
  minHeight: "100vh",
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(2, 1),
  },
}));

const Home = () => {
  const [systemStatus, setSystemStatus] = useState(null);
  const [graphData, setGraphData] = useState({
    xAxis: [],
    graphValues: [],
    seriesNames: [],
  });
  const [activeApis, setActiveApis] = useState([]);
  const [disabledApis, setDisabledApis] = useState([]);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    request.get("/api/v1/proxy/products").then((res) => {
      const xAxis = Object.keys(
        res.data.blog_search.number_of_requests_per_day
      ).map((item) => {
        return formatDate(item).dayMonth;
      });

      const generatedData = generateDashboardData(res.data);

      setActiveApis(generatedData.active);
      setDisabledApis(generatedData.disabled);

      setGraphData({
        xAxis,
        graphValues: generatedData.graphValues,
        seriesNames: generatedData.seriesNames,
      });
      setLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (!systemStatus) {
      const sp = new StatusPage.page({ page: "f5cs578xcjbm" });
      sp.status({
        success: (data) =>
          setSystemStatus({
            text: data.status.description,
            color: data.status.indicator,
          }),
      });
    }
  }, []);

  return (
    <PageLayout>
      <PageTitleUpdater pageTitle="Dashboard" />
      <HomeWrapper>
        <Box
          sx={{
            mx: -1,
            mt: -2,
            mb: 2,
            py: 0.5,
            background: "white",
            display: { xs: "flex", lg: "none" },
            alignItems: "center",
          }}
        >
          <ToggleSidebarButton />
          <Typography ml={1} variant="h6">
            Menu
          </Typography>
          <Link
            href="/"
            sx={{
              ml: "auto",
              height: 32,
              mr: 1.5,
              display: { lg: "none" },
              svg: {
                height: 32,
                width: "auto",
              },
            }}
          >
            <Icon name="logo" />
          </Link>
        </Box>
        <Box sx={{ mb: 4, display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              display: { sx: "block", lg: "flex" },
            }}
          >
            <Typography component="h1" variant="h5" fontWeight={700}>
              Dashboard
            </Typography>
            <SystemStatusIndicator systemStatus={systemStatus} />
          </Box>
          <Box ml="auto">
            <ApiKeyButton />
          </Box>
        </Box>

        {!loaded ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress />
          </Box>
        ) : (
          <Paper
            elevation={0}
            sx={{
              width: "100%",
              borderRadius: "12px",
              pt: 1,
              px: 2,
              pb: 3,
              mb: 3,
            }}
          >
            <Typography variant="h6">Requests Made the last 30 days</Typography>
            <UsageChart graphData={graphData} />
          </Paper>
        )}

        <DashboardCards
          activeApis={activeApis}
          setActiveApis={setActiveApis}
          disabledApis={disabledApis}
          setDisabledApis={setDisabledApis}
        />
      </HomeWrapper>
    </PageLayout>
  );
};

export default Home;
