import React, { useState } from "react";
import { Button, Grid, Typography } from "@mui/material";

const FileInput = () => {
  const [fileOutputText, setFileOutputText] = useState("No file chosen");

  const setFiles = () => {
    const fileInput = document.querySelector("#attachments");
    const files = fileInput.files;

    if (files.length === 0) {
      setFileOutputText("No file chosen");
    } else if (files.length === 1) {
      setFileOutputText(truncateLongName(files[0].name));
    } else {
      setFileOutputText(`${files.length} files`);
    }
  };

  const truncateLongName = (name) => {
    if (name.length <= 30) {
      return name;
    }

    const firstChars = name.substr(0, 10);
    const lastChars = name.substr(-10, 10);

    return firstChars + "..." + lastChars;
  };

  return (
    <Grid container spacing={2} sx={{ alignItems: "center", mb: 2 }}>
      <Grid item xs={"auto"}>
        <Button variant="outlined" component="label">
          Choose files
          <input
            hidden
            name="attachments"
            id="attachments"
            multiple
            type="file"
            onChange={setFiles}
          />
        </Button>
      </Grid>
      <Grid item xs={"auto"}>
        <Typography id="selected-files-output">{fileOutputText}</Typography>
      </Grid>
    </Grid>
  );
};

export default FileInput;
