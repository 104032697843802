import LinkedInTag from "react-linkedin-insight";

const linkedInPartnerId = "5004273";
const linkedInConversionId = "16175473";

function isRunningInProduction() {
  return isProduction !== undefined && isProduction;
}

export function initLinkedInTracking() {
  if (isRunningInProduction()) {
    LinkedInTag.init(linkedInPartnerId);
  }
}

export function trackLinkedInConversion() {
  if (isRunningInProduction()) {
    LinkedInTag.track(linkedInConversionId);
  }
}
