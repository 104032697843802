import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#5F6600",
    },
    background: {
      default: "#F5F5F5",
    },
    whiteColor: {
      main: "#fff",
    },
    error: {
      main: "#C62828",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        icon: {
          alignItems: "center",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          height: 24,
          borderRadius: "12px",
          background: "rgba(114, 122, 0, 0.08)",
          color: "text",
          fontSize: 14,
          p: 0.5,
          ".MuiChip-label": {
            paddingLeft: 10,
            paddingRight: 6,
          },
          ".MuiChip-deleteIcon": {
            marginRight: 4,
            marginLeft: 0,
          },
          "&:hover, &:focus": {
            background: "rgba(114, 122, 0, 0.2)",
            "svg path": {
              opacity: 1,
            },
          },
        },
      },
    },
  },
});

export default theme;
