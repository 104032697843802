import React from "react";

import { Grid, Box, Typography, Paper, Link, Tab, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";

import Icon from "../components/atoms/Icon";
import PageTitleUpdater from "../components/_functional/PageTitleUpdater";

import {
  Route,
  Routes,
  Link as RouterLink,
  useLocation,
} from "react-router-dom";

import MarkdownParser from "../components/templates/MarkdownParser";
import ReconCrawlerDocumentation from "../documentation/blog_recon_crawler.md";
import RpcPingDocumentation from "../documentation/blog_rpc_ping.md";
import PingDocumentation from "../documentation/blog_ping.md";

const HomeWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 3),
}));

const PublicDocumentation = () => {
  const location = useLocation();
  const nonEmptyPathComponents = location.pathname
    .split("/")
    .filter((item) => item);
  const lastPathComponent =
    nonEmptyPathComponents[nonEmptyPathComponents.length - 1];

  return (
    <HomeWrapper>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid item>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ mr: 2 }}>
              <Link
                href="https://www.twingly.com"
                sx={{
                  svg: {
                    height: 50,
                    width: "auto",
                  },
                }}
              >
                <Icon name="logo" />
              </Link>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography component="span" variant="h4" fontWeight={700}>
                Twingly - Public Documentation
              </Typography>
            </Box>
          </Box>

          <Paper
            elevation={0}
            sx={{
              borderRadius: "8px",
              p: { xs: 1, lg: 4 },
              pt: { xs: 0, lg: 0 },
              mt: 2,
              maxWidth: 760,
            }}
          >
            <Tabs value={lastPathComponent} sx={{ mb: 4 }}>
              <Tab
                label="Home"
                value="public-docs"
                component={RouterLink}
                to="."
              />
              <Tab
                label="Recon Crawler"
                value="crawler"
                component={RouterLink}
                to="./crawler"
              />
              <Tab
                label="Blog Ping"
                value="ping"
                component={RouterLink}
                to="./ping"
              />
              <Tab
                label="RPC Ping API"
                value="rpc-ping"
                component={RouterLink}
                to="./rpc-ping"
              />
            </Tabs>

            <Routes>
              <Route
                path="/"
                element={
                  <PageTitleUpdater pageTitle="Public Documentation">
                    <h1>Public Documentation</h1>

                    <p>
                      This page is the home of the documentation for
                      Twingly&rsquo;s public, non-commercial, APIs and services.
                      The APIs and services documented here does not require an
                      account to be used.
                    </p>

                    <p>
                      If you&rsquo;re looking for the documentation for our
                      commercial APIs, you can find it by{" "}
                      <Link href="/login" underline="hover">
                        logging in
                      </Link>
                      .
                    </p>

                    <p>
                      For more information about Twingly and what we do, visit
                      our main site at{" "}
                      <Link href="https://www.twingly.com/" underline="hover">
                        www.twingly.com
                      </Link>
                      .
                    </p>
                  </PageTitleUpdater>
                }
              />

              <Route
                path="/crawler"
                element={
                  <PageTitleUpdater pageTitle="Recon Crawler">
                    <MarkdownParser docs={ReconCrawlerDocumentation} />
                  </PageTitleUpdater>
                }
              />

              <Route
                path="/ping"
                element={
                  <PageTitleUpdater pageTitle="Blog Ping">
                    <MarkdownParser docs={PingDocumentation} />
                  </PageTitleUpdater>
                }
              />

              <Route
                path="/rpc-ping"
                element={
                  <PageTitleUpdater pageTitle="RPC Ping API">
                    <MarkdownParser docs={RpcPingDocumentation} />
                  </PageTitleUpdater>
                }
              />

              <Route
                path="*"
                element={
                  <PageTitleUpdater pageTitle="404 Not Found">
                    <h1>404 Not Found</h1>

                    <p>The page you were looking for could not be found.</p>
                  </PageTitleUpdater>
                }
              />
            </Routes>
          </Paper>

          <Box display="flex" justifyContent="center" gap={2} m={2}>
            <Link href="https://www.twingly.com" underline="none">
              Twingly.com
            </Link>
            |
            <Link href="/register" underline="none">
              Register
            </Link>
            |
            <Link href="/login" underline="none">
              Login
            </Link>
          </Box>
        </Grid>
      </Grid>
    </HomeWrapper>
  );
};

export default PublicDocumentation;
