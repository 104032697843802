import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import {
  Button,
  Link,
  List,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Paper,
  Alert,
  Fade,
} from "@mui/material";
import Icon from "../atoms/Icon";
import TextInput from "../molecules/TextInput";
import CheckboxInput from "../molecules/CheckboxInput";
import request from "../../util/request";
import ReCAPTCHA from "react-google-recaptcha";
import { trackLinkedInConversion } from "../_functional/LinkedInTracker";

const siteKey = recaptchaSiteKey;

const RegisterForm = () => {
  let navigate = useNavigate();

  const recaptchaRef = useRef();

  const [success, setSuccess] = useState(false);
  const [formError, setFormError] = useState("");

  const defaultValues = {
    email: "",
    name: "",
    company: "",
    password: "",
    policies_accepted: false,
    recaptcha_response: "",
  };

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const { control, handleSubmit, setValue, setError } = formMethods;

  const onSubmit = (data) => {
    request({
      method: "post",
      url: "/api/v1/user_accounts",
      data,
    })
      .then(() => {
        setSuccess(true);

        trackLinkedInConversion();
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        if (errors.base) setFormError(errors.base[0]);
        else {
          Object.keys(errors).map((error) => {
            if (error !== "base") {
              setError(error, {
                type: "manual",
                message: error[0],
              });
              setFormError(errors[error][0]);
            }
          });
        }
        recaptchaRef.current.reset();
      });
  };

  const onCaptchChange = (value) => {
    setValue("recaptcha_response", value);
  };

  return (
    <Paper
      elevation={0}
      sx={{
        px: { xs: 2, lg: 8 },
        py: 6,
        borderRadius: "16px",
        maxWidth: 480,
      }}
    >
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            svg: {
              height: 48,
              width: "auto",
            },
          }}
        >
          <Icon name="logo" />
        </Box>
        <Box ml={3} display="flex" flexDirection="column">
          {success && (
            <Typography component="h1" variant="h5" fontWeight={500}>
              Thank you for registering!
            </Typography>
          )}
          {!success && (
            <>
              <Typography component="h1" variant="h5" fontWeight={500}>
                Create Account
              </Typography>
              <Typography color="textSecondary">
                to start a free trial
              </Typography>
            </>
          )}
        </Box>
      </Box>

      {!success && formError && (
        <Fade in={!!formError}>
          <Alert sx={{ mb: 3 }} severity="error">
            {formError}
          </Alert>
        </Fade>
      )}

      {!success && (
        <Box mb={2}>
          <Alert severity="info" sx={{ py: 0, mt: 2, mb: 3 }}>
            <List dense>
              <ListItem disablePadding>
                <ListItemText
                  primary="• Search the data immediately in your browser"
                  sx={{ my: 0 }}
                  primaryTypographyProps={{
                    fontSize: 12,
                  }}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary="• Trusted by leading monitoring companies"
                  sx={{ my: 0 }}
                  primaryTypographyProps={{
                    fontSize: 12,
                  }}
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemText
                  primary="• No credit card required"
                  sx={{ my: 0 }}
                  primaryTypographyProps={{
                    fontSize: 12,
                  }}
                />
              </ListItem>
            </List>
          </Alert>
        </Box>
      )}

      {success && (
        <>
          <Alert sx={{ mb: 3 }} severity="success">
            You will need to verify your email address before logging in: we
            have sent you a confirmation email.
          </Alert>
          <Button onClick={() => navigate("/login")} variant="text">
            Go to Log In
          </Button>
        </>
      )}

      {!success && (
        <>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                inputAdornment="email"
                type="email"
                name="email"
                required
                label="Your Email"
              />
              <TextInput
                name="name"
                required
                label="Full name"
                inputAdornment="user"
              />
              <TextInput
                name="company"
                required
                label="Company"
                inputAdornment="company"
              />
              <TextInput
                type="password"
                name="password"
                required
                label="Password"
                inputAdornment="password"
                helperText="At least 8 characters including a lower-case letter, an upper-case letter, and a number"
              />
              <CheckboxInput
                name="policies_accepted"
                required
                label={
                  <>
                    I have read and agree to Twingly&rsquo;s
                    <br />
                    <Link
                      underline="hover"
                      href="https://www.twingly.com/terms"
                      target="_blank"
                    >
                      Terms of Service
                    </Link>
                    {` and `}
                    <Link
                      underline="hover"
                      href="https://www.twingly.com/privacy-policy"
                      target="_blank"
                    >
                      Privacy Policy
                    </Link>
                  </>
                }
              />
              <Box
                sx={{
                  mb: 3,
                  "div > div >div": {
                    m: "auto",
                  },
                }}
              >
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={siteKey}
                  onChange={onCaptchChange}
                />
              </Box>
              <Box>
                <Button
                  sx={{ width: "100%" }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  size="large"
                >
                  Create account
                </Button>
              </Box>
            </form>
            <DevTool control={control} />{" "}
            {/* react-hook-form browser dev tool */}
          </FormProvider>
          <Box display="flex" mt={3} fontSize={14}>
            <Typography>
              {`Already have an account? `}
              <Link underline="none" href="/login">
                Log in
              </Link>
            </Typography>
          </Box>
        </>
      )}
    </Paper>
  );
};

export default RegisterForm;
