import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import {
  Button,
  Link,
  Box,
  Typography,
  Paper,
  Alert,
  Fade,
} from "@mui/material";
import Icon from "../atoms/Icon";
import TextInput from "../molecules/TextInput";
import request from "../../util/request";
import { getParamFromUrl } from "../../util/helpers";
import {
  setCookie,
  getCookieByName,
  deleteCookie,
} from "../../util/cookieProvider";
import Progress from "../atoms/Progress";
import { useLocation } from "react-router-dom";
import { startIntercom } from "../_functional/intercom";

const LoginForm = () => {
  let location = useLocation();
  const verifyCode = getParamFromUrl(location.search, "code");

  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState("");

  const redirectPage = getCookieByName("twingly_current_path") || "/";

  const defaultValues = {
    email: "",
    password: "",
  };

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const { control, handleSubmit, setError } = formMethods;

  const onSubmit = (data) => {
    setLoading(true);
    request({
      method: "post",
      url: "/api/v1/user_accounts/sign_in",
      data,
    })
      .then((res) => {
        const userData = res?.data?.data;

        setLoading(false);
        setCookie("twingly_token", userData.access_token, { path: "/" });
        setCookie("api_key", userData.api_key, { path: "/" });
        setCookie("user_id", userData.id, { path: "/" });
        setCookie("email", userData.email, { path: "/" });
        setCookie("intercom_user_hash", userData.intercom_user_hash, {
          path: "/",
        });
        deleteCookie("twingly_current_path");

        startIntercom({
          intercom_user_hash: userData.intercom_user_hash,
          user_id: userData.id,
          email: userData.email,
          api_key: userData.api_key,
          name: userData.name,
          company: userData.company,
          policies_accepted: userData.policies_accepted,
          policies_accepted_at: userData.policies_accepted_at,
        });

        window.location.href = redirectPage;
      })
      .catch((err) => {
        setLoading(false);
        const errors = err.response.data.errors;
        if (errors.base && errors.base[0]) {
          setFormError(errors.base[0]);
          setError("email", {
            type: "manual",
            message: errors.base[0],
          });
          setError("password", {
            type: "manual",
            message: errors.base[0],
          });
        } else if (errors.email[0]) {
          setFormError(errors.email[0]);
        }
      });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        px: { xs: 2, lg: 8 },
        py: 6,
        borderRadius: "16px",
        maxWidth: 440,
        my: "auto",
      }}
    >
      <Progress
        sx={{
          position: "fixed",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.3)",
          zIndex: 2,
          display: "flex",
          alignItems: "center",
        }}
        color="whiteColor"
        layoutProps={{
          ml: { lg: "calc(25% - 20px)", xs: "calc(50% - 20px)" },
        }}
        isShown={loading}
      />

      <Box display="flex" alignItems="center" mb={5}>
        <Box
          sx={{
            svg: {
              height: 48,
              width: "auto",
            },
          }}
        >
          <Icon name="logo" />
        </Box>
        <Typography ml={3} component="h1" variant="h5" fontWeight={500}>
          Log In
        </Typography>
      </Box>

      {formError && (
        <Fade in={!!formError}>
          <Alert sx={{ mb: 3 }} severity="error">
            {formError}
          </Alert>
        </Fade>
      )}

      {verifyCode && (
        <Fade in={!!verifyCode}>
          <Alert
            sx={{ mb: 3 }}
            severity={
              verifyCode === "success"
                ? "success"
                : verifyCode === "already-verified" && "error"
            }
          >
            {verifyCode === "success" && "Your email was verified"}
            {verifyCode === "already-verified" &&
              "This account is already verified"}
          </Alert>
        </Fade>
      )}

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextInput
            type="email"
            name="email"
            required
            label="Email"
            inputAdornment="account"
          />
          <TextInput
            type="password"
            name="password"
            required
            label="Password"
            inputAdornment="password"
          />
          <Box>
            <Button
              sx={{ width: "100%" }}
              color="primary"
              variant="contained"
              type="submit"
              size="large"
            >
              Log In
            </Button>
          </Box>
        </form>
        <DevTool control={control} /> {/* react-hook-form browser dev tool */}
      </FormProvider>
      <Box display="flex" mt={3} fontSize={14} gap={2}>
        <Link underline="none" href="/register">
          Create Account
        </Link>
        <Link underline="none" href="/reset-password">
          Forgot Password?
        </Link>
      </Box>
    </Paper>
  );
};

export default LoginForm;
