import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Alert, Box, Button, IconButton } from "@mui/material";
import TextInput from "../molecules/TextInput";
import Icon from "../atoms/Icon";
import request from "../../util/request";
import { DevTool } from "@hookform/devtools";

const ContactSalesForm = () => {
  const defaultValues = {
    name: "",
    email: "",
    message: "",
  };

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const { control, setValue, handleSubmit, formState, resetField } =
    formMethods;
  const [formSubmission, setFormSubmission] = useState({
    success: null,
    message: "",
  });

  useEffect(() => {
    request("/api/v1/user_accounts/show").then((res) => {
      const user = res.data.data;

      setValue("name", user?.name);
      setValue("email", user?.email);
    });
  }, []);

  const onSubmit = (formData) => {
    request({
      method: "post",
      url: "/api/v1/contact_sales",
      data: formData,
    })
      .then(() => {
        setFormSubmission({
          success: true,
          message: `Email sent! We will get back to you shortly.`,
        });
        resetField("message");
      })
      .catch((err) => {
        const error_message =
          err.response.data.message ||
          "Something went wrong. Please try again.";

        setFormSubmission({ success: false, message: error_message });
      });
  };

  return (
    <FormProvider {...formMethods}>
      {formSubmission.success === true && (
        <Alert
          sx={{
            ".MuiAlert-action": {
              alignItems: "center",
            },
            mb: 4,
          }}
          action={
            <IconButton
              aria-label="close"
              size="small"
              variant="outlined"
              onClick={() => {
                setFormSubmission({ success: null, message: "" });
              }}
            >
              <Icon name="close" />
            </IconButton>
          }
          severity="success"
        >
          {formSubmission.message}
        </Alert>
      )}
      {formSubmission.success === false && (
        <Alert
          sx={{
            ".MuiAlert-action": {
              alignItems: "center",
            },
            mb: 4,
          }}
          action={
            <IconButton
              aria-label="close"
              size="small"
              variant="outlined"
              onClick={() => {
                setFormSubmission({ success: null, message: "" });
              }}
            >
              <Icon name="close" />
            </IconButton>
          }
          severity="error"
        >
          {formSubmission.message}
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput id="name" name="name" required label="Name" />
        <TextInput
          id="email"
          type="email"
          name="email"
          required
          label="Email"
        />
        <TextInput
          id="message"
          name="message"
          required
          label="Message"
          multiline
          rows="6"
        />
        <Box>
          <Button
            variant="contained"
            type="submit"
            disabled={!formState.isValid}
          >
            Send message
          </Button>
        </Box>
      </form>
      <DevTool control={control} /> {/* react-hook-form browser dev tool */}
    </FormProvider>
  );
};

export default ContactSalesForm;
