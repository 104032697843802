import React, { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { Paper, Box, Typography } from "@mui/material";
import QueryTooltipButton from "../molecules/QueryTooltipButton";
import FilterTextInput from "../molecules/FilterTextInput";
import { generateDarkWebValues } from "../../util/helpers";
import QueryBuilderFormFooter from "../molecules/QueryBuilderFormFooter";

const SimpleQueryBuilderForm = ({
  queryName,
  apiData,
  handleChange,
  handleSubmitQuery,
  queryExecuting,
  defaultValues,
  fields: Fields,
}) => {
  const [currentRule, setCurrentRule] = useState();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formMethods = useForm({
    defaultValues: defaultValues,
  });

  const { control, watch, setValue, handleSubmit } = formMethods;

  const currentQuery = watch("query");

  const addRule = (rule) => {
    setCurrentRule(rule);
    handleOpen();
  };

  const handleAddToQuery = (values) => {
    const generatedValues = generateDarkWebValues(values).trim();
    const filter = currentQuery
      ? `${currentQuery} ${generatedValues}`
      : generatedValues;
    setValue("query", filter, { shouldTouch: true, shouldDirty: true });
    handleChange("query", filter);
    handleClose();
  };

  const instructions = [
    { title: "AND", tooltip: "Match all the connected terms/filters" },
    { title: "OR", tooltip: "Match any of the connected terms/filters" },
    {
      title: "-",
      tooltip: "Exclude results containing the specified term/filter",
    },
    { title: "()", tooltip: "Group terms/ filters together" },
    { title: '" "', tooltip: "Mark a phrase containing multiple words" },
    { title: "?", tooltip: "Replace a single character" },
    { title: "*", tooltip: "Replace zero or more characters" },
  ];

  return (
    <>
      <Paper
        sx={{
          boxShadow: "0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
          border: currentQuery ? "4px solid rgba(114, 122, 0, 0.3)" : "",
          p: 2,
          pt: currentQuery ? 3 : 2,
          mx: currentQuery ? -0.5 : 0,
          my: currentQuery ? -0.5 : 0,
          borderRadius: "12px",
        }}
        elevation={0}
      >
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(handleSubmitQuery)}>
            <FilterTextInput
              name="query"
              multiline
              autoFocus
              soloInput
              label="Search Query"
              handleInputChange={handleChange}
            />
            <Paper
              elevation={0}
              sx={{
                background: "#F5F5F5",
                borderRadius: "8px",
                p: 1,
                mb: 2,
              }}
            >
              <Typography lineHeight="20px" fontSize={12} color="textSecondary">
                Operators
              </Typography>
              <Box my={0.5} display="flex" gap={0.5}>
                {instructions.map((item, index) => (
                  <QueryTooltipButton
                    key={index}
                    title={item.title}
                    tooltip={item.tooltip}
                  />
                ))}
              </Box>
              <Typography lineHeight="20px" fontSize={12} color="textSecondary">
                Example: language:(spanish OR english) AND
                extended.network:telegram AND ((fullz AND credit) -mastercard)
              </Typography>
            </Paper>
            <QueryBuilderFormFooter
              queryName={queryName}
              openModal
              addRule={addRule}
              apiData={apiData}
              queryExecuting={queryExecuting}
              formFilled={currentQuery}
            />
            <DevTool control={control} />{" "}
            {/* react-hook-form browser dev tool */}
          </form>
        </FormProvider>
      </Paper>
      <Fields
        currentRule={currentRule}
        open={open}
        handleClose={handleClose}
        handleAddToQuery={handleAddToQuery}
      />
    </>
  );
};

export default SimpleQueryBuilderForm;
