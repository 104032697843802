export default {
  blog_search: {
    description: `Free Trial - 100 requests for 2 weeks.`,
    features: [
      {
        icon: false,
        text: "4M",
        description: "Active Blogs",
      },
      {
        icon: "searchIcon",
        text: false,
        description: "Search API",
      },
      {
        icon: false,
        text: "56",
        description: "Languages",
      },
      {
        icon: false,
        text: "100+",
        description: "Countries",
      },
    ],
  },
  blog_livefeed: {
    description: `Free Trial - 100 requests for 2 weeks.`,
    features: [
      {
        icon: false,
        text: "4M",
        description: "Active Blogs",
      },
      {
        icon: "realTimeStream",
        text: false,
        description: "Real-time Stream",
      },
      {
        icon: false,
        text: "56",
        description: "Languages",
      },
      {
        icon: false,
        text: "100+",
        description: "Countries",
      },
    ],
  },
  news_b_search: {
    description: `Free Trial - 100 requests for 2 weeks.`,
    features: [
      {
        icon: false,
        text: "3M",
        description: "Articles per day",
      },
      {
        icon: "searchIcon",
        text: false,
        description: "Search API",
      },
      {
        icon: false,
        text: "8 min",
        description: "From publishing to API",
      },
      {
        icon: false,
        text: "100+",
        description: "Countries",
      },
    ],
  },
  news_b_livefeed: {
    description: `Free Trial - 100 requests for 2 weeks.`,
    features: [
      {
        icon: false,
        text: "3M",
        description: "Articles per day",
      },
      {
        icon: "realTimeStream",
        text: false,
        description: "Real-time Stream",
      },
      {
        icon: false,
        text: "8 min",
        description: "From publishing to API",
      },
      {
        icon: false,
        text: "100+",
        description: "Countries",
      },
    ],
  },
  forum_c_search: {
    description: `Free Trial - 100 requests for 2 weeks.`,
    features: [
      {
        icon: false,
        text: "10M",
        description: "Posts per day",
      },
      {
        icon: "searchIcon",
        text: false,
        description: "Search API",
      },
      {
        icon: false,
        text: "100+",
        description: "Languages",
      },
      {
        icon: false,
        text: "100+",
        description: "Countries",
      },
    ],
  },
  review_c_search: {
    description: `Free Trial - 100 requests for 2 weeks.`,
    features: [
      {
        icon: false,
        text: "15M",
        description: "Reviews per month",
      },
      {
        icon: "searchIcon",
        text: false,
        description: "Search API",
      },
      {
        icon: false,
        text: "100+",
        description: "Languages",
      },
      {
        icon: false,
        text: "12",
        description: "Months of history",
      },
    ],
  },
  dark_web_a_search: {
    description: `Due to the sensitive nature of this data, please contact us for access.`,
    contactSalesButton: true,
    features: [
      {
        icon: false,
        text: "16M",
        description: "Documents per month",
      },
      {
        icon: "tor",
        text: false,
        description: "TOR",
      },
      {
        icon: "forum",
        text: false,
        description: "Forums",
      },
      {
        icon: "marketplace",
        text: false,
        description: "Marketplaces",
      },
    ],
  },
  vk_c_search: {
    description: `Free Trial - 100 requests for 2 weeks.`,
    features: [
      {
        icon: false,
        text: "20M",
        description: "Posts, Comments per day",
      },
      {
        icon: "searchIcon",
        text: false,
        description: "Search API",
      },
      {
        icon: false,
        text: "No. 1",
        description: "Platform in Russia",
      },
      {
        icon: "text",
        text: false,
        description: "Full text",
      },
    ],
  },
};
