import React, { useEffect } from "react";
import { toast } from "react-toastify";
import {
  useForm,
  useFieldArray,
  Controller,
  FormProvider,
  useWatch,
} from "react-hook-form";
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import request from "../../util/request";
import { DevTool } from "@hookform/devtools";
import UrlInput from "../molecules/UrlInput";

const RequestSourcesForm = () => {
  const defaultValues = {
    name: "",
    email: "",
    reference: "",
    urls: [{ url: "" }],
  };

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
  });

  const { control, setValue, getValues, handleSubmit, resetField, register } =
    formMethods;

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "urls",
    rules: { minLength: 1 },
  });

  useEffect(() => {
    request("/api/v1/user_accounts/show").then((res) => {
      const user = res.data.data;

      setValue("name", user?.name);
      setValue("email", user?.email);
    });

    request("/api/v1/proxy/products").then((res) => {
      const allApisTrial = Object.values(res.data).every(
        (api_properties) => api_properties.is_trial
      );

      if (allApisTrial) setValue("allApisTrial", true);
    });
  }, []);

  const onSubmit = (formData) => {
    request({
      method: "post",
      url: "/api/v1/request_sources",
      data: formData,
    })
      .then((res) => {
        if (res.status == 202) {
          toast.success(
            `Successfully requested ${res.data.number_of_sources_requested} sources.`
          );
          resetField("reference");
          resetField("api");
          remove();
          append({ url: "" });
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      })
      .catch((err) => {
        const error_message =
          err.response.data.message ||
          "Something went wrong. Please try again.";

        toast.error(error_message);
      });
  };

  const handleApiChange = (e) => {
    setValue("api", e.target.value);
  };

  const watchAllApisTrial = useWatch({
    control,
    name: "allApisTrial",
    defaultValue: false,
  });

  return (
    <FormProvider {...formMethods}>
      {watchAllApisTrial === true && (
        <Alert severity="error" sx={{ mb: 2 }}>
          This form is only available to paying customers.
        </Alert>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("allApisTrial")}
          name="allApisTrial"
          value={watchAllApisTrial}
          hidden
        />
        <Typography variant="h6" sx={{ mb: 2 }}>
          Contact
        </Typography>
        <Controller
          name={"name"}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="name"
              type="text"
              name="name"
              required
              label="Name"
              sx={{ mb: 2 }}
              fullWidth
              helperText="The name to be assigned to the request."
            />
          )}
        />
        <Controller
          name={"email"}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="email"
              type="email"
              name="email"
              required
              label="Email"
              sx={{ mb: 2 }}
              disabled
              fullWidth
              helperText="Responses will be sent to this email address."
            />
          )}
        />
        <Controller
          name={"reference"}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              id="reference"
              type="text"
              name="reference"
              label="Reference"
              sx={{ mb: 2 }}
              fullWidth
              helperText="An optional reference ID for your own, internal, use."
            />
          )}
        />
        <Typography variant="h6">API</Typography>
        <Typography paragraph>
          The API to which you wish to add sources.
        </Typography>
        <FormControl fullWidth>
          <InputLabel id="api-label" required>
            API
          </InputLabel>
          <Controller
            name={"api"}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id="api"
                labelId="api-label"
                label="API"
                sx={{ mb: 3 }}
                onChange={handleApiChange}
                value={getValues("api") || ""}
                defaultValue=""
                required
              >
                <MenuItem value={"Blogs"}>Blogs</MenuItem>
                <MenuItem value={"News"}>News</MenuItem>
                <MenuItem value={"Forums"}>Forums</MenuItem>
                <MenuItem value={"Reviews"}>Reviews</MenuItem>
                <MenuItem value={"Dark Web"}>Dark Web</MenuItem>
              </Select>
            )}
          />
        </FormControl>

        <Typography variant="h6">URLs</Typography>
        <Typography paragraph>
          The URLs you wish to add. Enter the URLs one by one or by pasting a
          list.
        </Typography>
        <UrlInput
          control={control}
          fields={fields}
          append={append}
          update={update}
          remove={remove}
          required={true}
          validate={false}
        />

        <Box>
          <Button
            variant="contained"
            type="submit"
            sx={{ mt: 3 }}
            disabled={watchAllApisTrial}
          >
            Submit
          </Button>
        </Box>
      </form>
      <DevTool control={control} /> {/* react-hook-form browser dev tool */}
    </FormProvider>
  );
};

export default RequestSourcesForm;
