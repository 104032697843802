import { useEffect } from "react";
import { getCookieByName } from "../../util/cookieProvider";
import { useLocation } from "react-router-dom";

function getUserId() {
  return getCookieByName("user_id");
}

function getEmail() {
  return getCookieByName("email");
}

function getIntercomUserHash() {
  return getCookieByName("intercom_user_hash");
}

// Checks if Intercom has been loaded. Due to ad blockers, Intercom
// might not always be defined
function intercomHasBeenLoaded() {
  return typeof Intercom === "function";
}

export function startIntercom(userData) {
  if (!intercomHasBeenLoaded()) {
    return;
  }

  const userId = getUserId();
  const email = getEmail();

  if (userId && email) {
    Intercom("boot", {
      user_hash: userData.intercom_user_hash,
      user_id: userData.user_id,
      email: userData.email,
      apikey: userData.api_key,
      name: userData.name,
      companyName: userData.company,
      policiesAccepted: userData.policies_accepted,
      policiesAcceptedAt: userData.policies_accepted_at,
    });
  }
}

export function shutdownIntercom() {
  if (!intercomHasBeenLoaded()) {
    return;
  }

  Intercom("shutdown");
}

export function IntercomUpdater() {
  const location = useLocation();

  useEffect(() => {
    if (!intercomHasBeenLoaded()) {
      return;
    }

    const userHash = getIntercomUserHash();
    const userId = getUserId();
    const email = getEmail();

    // Logged in
    if (userId && email) {
      Intercom("update", {
        user_hash: userHash,
        user_id: userId,
        email: email,
      });
    }
  }, [location]);

  return null;
}
