export default {
  blog_search: {
    maxLanguages: 56,
    langAnchor: "list-languages",
  },
  blog_livefeed: {
    maxLanguages: 56,
    langAnchor: "list-languages",
    disableButton: true,
  },
  news_b_search: {
    maxLanguages: 184,
    maxCountries: 238,
    langAnchor: "list-languages",
    locAnchor: "list-locations",
  },
  news_b_livefeed: {
    maxLanguages: 184,
    maxCountries: 238,
    langAnchor: "list-languages",
    locAnchor: "list-locations",
    disableButton: true,
  },
  forum_c_search: {
    maxLanguages: 116,
    maxCountries: 206,
    langAnchor: "list-languages",
    locAnchor: "list-locations",
  },
  review_c_search: {
    maxLanguages: 116,
    maxCountries: 206,
    langAnchor: "list-languages",
    locAnchor: "list-locations",
  },
  dark_web_a_search: {},
  vk_c_search: {},
};
