import { subDays, zeroMinutesSeconds } from "../../util/helpers";

export default {
  defaultValues: {
    any: [],
    all: [],
    none: [],
    languages: [],
    event_types: [],
    attachment_types: [],
    authors: [],
    posts: [],
    post_owners: [],
    publishedSince: zeroMinutesSeconds(subDays(new Date(), 6)),
    publishedUntil: zeroMinutesSeconds(new Date()),
    sort: { value: "published_time", label: "Published Time" },
    order: { value: "desc", label: "Desc" },
    size: 10,
    offset: "",
  },
  querySettings: {
    dateInputs: ["publishedSince", "publishedUntil"],
    selectInputs: ["languages", "event_types", "attachment_types"],
    singleDropdown: ["sort", "order"],
    replaceKeyName: {
      publishedSince: "since",
      publishedUntil: "until",
    },
    nestedObjectFields: {
      publishedSince: "published_time",
      publishedUntil: "published_time",
    },
    numberInputs: ["size", "offset"],
  },
  attachment_types: [
    { label: "Photo", value: "photo" },
    { label: "Video", value: "video" },
    { label: "Link", value: "link" },
    { label: "Audio", value: "audio" },
    { label: "Doc", value: "doc" },
    { label: "Album", value: "album" },
    { label: "Poll", value: "poll" },
    { label: "Market Album", value: "market_album" },
    { label: "Page", value: "page" },
  ],
  event_types: [
    { label: "Post", value: "post" },
    { label: "Share", value: "share" },
    { label: "Comment", value: "comment" },
  ],
  languages: [
    { label: "Afrikaans", value: "af" },
    { label: "Albanian", value: "sq" },
    { label: "Amharic", value: "am" },
    { label: "Arabic", value: "ar" },
    { label: "Armenian", value: "hy" },
    { label: "Azerbaijani", value: "az" },

    { label: "Basque", value: "eu" },
    { label: "Belarusian", value: "be" },
    { label: "Bengali", value: "bn" },
    { label: "Bosnian", value: "bs" },
    { label: "Bulgarian", value: "bg" },
    { label: "Bulgarian (Latin)", value: "bg-latn" },
    { label: "Burmese", value: "my" },

    { label: "Catalan", value: "ca" },
    { label: "Cebuano", value: "ceb" },
    { label: "Chinese", value: "zh" },
    { label: "Chinese (Latin)", value: "zh-latn" },
    { label: "Chinese - Simplified", value: "zh-cn" },
    { label: "Chinese - Traditional", value: "zh-tw" },
    { label: "Corsican", value: "co" },
    { label: "Croatian", value: "hr" },
    { label: "Czech", value: "cs" },

    { label: "Danish", value: "da" },
    { label: "Divehi", value: "dv" },
    { label: "Dutch", value: "nl" },

    { label: "English", value: "en" },
    { label: "Esperanto", value: "eo" },
    { label: "Estonian", value: "et" },

    { label: "Faroese", value: "fo" },
    { label: "Filipino (Tagalog)", value: "tl" },
    { label: "Finnish", value: "fi" },
    { label: "French", value: "fr" },

    { label: "Galician", value: "gl" },
    { label: "Georgian", value: "ka" },
    { label: "German", value: "de" },
    { label: "Greek", value: "el" },
    { label: "Greek (Latin)", value: "el-latn" },
    { label: "Gujarati", value: "gu" },

    { label: "Haitian Creole", value: "ht" },
    { label: "Hausa", value: "ha" },
    { label: "Hawaiian", value: "haw" },
    { label: "Hebrew", value: "he" },
    { label: "Hindi", value: "hi" },
    { label: "Hindi (Latin)", value: "hi-latn" },
    { label: "Hmong", value: "hmn" },
    { label: "Hungarian", value: "hu" },

    { label: "Icelandic", value: "is" },
    { label: "Igbo", value: "ig" },
    { label: "Indonesian", value: "id" },
    { label: "Irish", value: "it" },
    { label: "Italian", value: "it" },

    { label: "Japanese", value: "ja" },
    { label: "Japanese (Latin)", value: "ja-latn" },
    { label: "Javanese", value: "jv" },

    { label: "Kannada", value: "kn" },
    { label: "Kazakh", value: "kk" },
    { label: "Khmer", value: "km" },
    { label: "Korean", value: "ko" },
    { label: "Kurdish", value: "ku" },
    { label: "Kyrgyz", value: "ky" },

    { label: "Lao", value: "lo" },
    { label: "Latin", value: "la" },
    { label: "Latvian", value: "lv" },
    { label: "Lithuanian", value: "lt" },
    { label: "Luxembourgish", value: "lb" },

    { label: "Macedonian", value: "mk" },
    { label: "Malagasy", value: "mg" },
    { label: "Malay", value: "ms" },
    { label: "Malayalam", value: "ml" },
    { label: "Maltese", value: "mt" },
    { label: "Maori", value: "mi" },
    { label: "Marathi", value: "mr" },
    { label: "Moldavian", value: "mo" },
    { label: "Mongolian", value: "mn" },

    { label: "Nepali", value: "ne" },
    { label: "Norwegian", value: "no" },
    { label: "Nyanja", value: "ny" },

    { label: "Oriya", value: "or" },

    { label: "Persian (Farsi)", value: "fa" },
    { label: "Polish", value: "pl" },
    { label: "Portuguese", value: "pt" },
    { label: "Panjabi", value: "pa" },
    { label: "Pushto", value: "ps" },

    { label: "Romanian", value: "ro" },
    { label: "Romansh", value: "rm" },
    { label: "Russian", value: "ru" },
    { label: "Russian (Latin)", value: "ru-latn" },

    { label: "Samoan", value: "sm" },
    { label: "Scottish Gaelic", value: "gd" },
    { label: "Serbian", value: "sr" },
    { label: "Shona", value: "sn" },
    { label: "Sindhi", value: "sd" },
    { label: "Sinhala", value: "si" },
    { label: "Slovak", value: "sk" },
    { label: "Slovenian", value: "sl" },
    { label: "Somali", value: "so" },
    { label: "Southern Sotho", value: "st" },
    { label: "Spanish", value: "es" },
    { label: "Sundanese", value: "se" },
    { label: "Swahili", value: "sw" },
    { label: "Swedish", value: "sv" },

    { label: "Tajik", value: "tg" },
    { label: "Tamil", value: "ta" },
    { label: "Telugu", value: "te" },
    { label: "Thai", value: "th" },
    { label: "Turkish", value: "tr" },

    { label: "Ukrainian", value: "uk" },
    { label: "Urdu", value: "ur" },
    { label: "Uzbek", value: "uz" },

    { label: "Vietnamese", value: "vi" },

    { label: "Welsh", value: "cy" },
    { label: "Western Frisian", value: "fy" },

    { label: "Xhosa", value: "xh" },

    { label: "Yiddish", value: "yi" },
    { label: "Yoruba", value: "yo" },

    { label: "Zulu", value: "zu" },
  ],
};
