import {
  subMonths,
  zeroMinutesSeconds,
  addDaysToDate,
} from "../../util/helpers";

export default {
  defaultValues: {
    all: [],
    any: [],
    none: [],
    languages: [],
    locations: [],
    domains: [],
    exclude_domains: [],
    publishedSince: zeroMinutesSeconds(
      subMonths(addDaysToDate(new Date(), 1), 12)
    ),
    publishedUntil: zeroMinutesSeconds(new Date()),
    sort: { value: "published_time", label: "Published Time" },
    order: { value: "desc", label: "Desc" },
    size: 10,
    offset: "",
  },
  querySettings: {
    dateInputs: ["publishedSince", "publishedUntil"],
    selectInputs: ["languages", "locations"],
    singleDropdown: ["sort", "order"],
    replaceKeyName: {
      publishedSince: "since",
      publishedUntil: "until",
    },
    nestedObjectFields: {
      publishedSince: "published_time",
      publishedUntil: "published_time",
    },
    numberInputs: ["size", "offset"],
  },
  languages: [
    { label: "Afrikaans", value: "af" },
    { label: "Albanian", value: "sq" },
    { label: "Amharic", value: "am" },
    { label: "Arabic", value: "ar" },
    { label: "Armenian", value: "hy" },
    { label: "Azerbaijani", value: "az" },

    { label: "Basque", value: "eu" },
    { label: "Belarusian", value: "be" },
    { label: "Bengali", value: "bn" },
    { label: "Bosnian", value: "bs" },
    { label: "Bulgarian", value: "bg" },
    { label: "Bulgarian (Latin)", value: "bg-latn" },
    { label: "Burmese", value: "my" },

    { label: "Catalan", value: "ca" },
    { label: "Cebuano", value: "ceb" },
    { label: "Chinese", value: "zh" },
    { label: "Chinese (Latin)", value: "zh-latn" },
    { label: "Chinese - Simplified", value: "zh-cn" },
    { label: "Chinese - Traditional", value: "zh-tw" },
    { label: "Corsican", value: "co" },
    { label: "Croatian", value: "hr" },
    { label: "Czech", value: "cs" },

    { label: "Danish", value: "da" },
    { label: "Divehi", value: "dv" },
    { label: "Dutch", value: "nl" },

    { label: "English", value: "en" },
    { label: "Esperanto", value: "eo" },
    { label: "Estonian", value: "et" },

    { label: "Faroese", value: "fo" },
    { label: "Filipino (Tagalog)", value: "tl" },
    { label: "Finnish", value: "fi" },
    { label: "French", value: "fr" },

    { label: "Galician", value: "gl" },
    { label: "Georgian", value: "ka" },
    { label: "German", value: "de" },
    { label: "Greek", value: "el" },
    { label: "Greek (Latin)", value: "el-latn" },
    { label: "Gujarati", value: "gu" },

    { label: "Haitian Creole", value: "ht" },
    { label: "Hausa", value: "ha" },
    { label: "Hawaiian", value: "haw" },
    { label: "Hebrew", value: "he" },
    { label: "Hindi", value: "hi" },
    { label: "Hindi (Latin)", value: "hi-latn" },
    { label: "Hmong", value: "hmn" },
    { label: "Hungarian", value: "hu" },

    { label: "Icelandic", value: "is" },
    { label: "Igbo", value: "ig" },
    { label: "Indonesian", value: "id" },
    { label: "Irish", value: "it" },
    { label: "Italian", value: "it" },

    { label: "Japanese", value: "ja" },
    { label: "Japanese (Latin)", value: "ja-latn" },
    { label: "Javanese", value: "jv" },

    { label: "Kannada", value: "kn" },
    { label: "Kazakh", value: "kk" },
    { label: "Khmer", value: "km" },
    { label: "Korean", value: "ko" },
    { label: "Kurdish", value: "ku" },
    { label: "Kyrgyz", value: "ky" },

    { label: "Lao", value: "lo" },
    { label: "Latin", value: "la" },
    { label: "Latvian", value: "lv" },
    { label: "Lithuanian", value: "lt" },
    { label: "Luxembourgish", value: "lb" },

    { label: "Macedonian", value: "mk" },
    { label: "Malagasy", value: "mg" },
    { label: "Malay", value: "ms" },
    { label: "Malayalam", value: "ml" },
    { label: "Maltese", value: "mt" },
    { label: "Maori", value: "mi" },
    { label: "Marathi", value: "mr" },
    { label: "Moldavian", value: "mo" },
    { label: "Mongolian", value: "mn" },

    { label: "Nepali", value: "ne" },
    { label: "Norwegian", value: "no" },
    { label: "Nyanja", value: "ny" },

    { label: "Oriya", value: "or" },

    { label: "Persian (Farsi)", value: "fa" },
    { label: "Polish", value: "pl" },
    { label: "Portuguese", value: "pt" },
    { label: "Panjabi", value: "pa" },
    { label: "Pushto", value: "ps" },

    { label: "Romanian", value: "ro" },
    { label: "Romansh", value: "rm" },
    { label: "Russian", value: "ru" },
    { label: "Russian (Latin)", value: "ru-latn" },

    { label: "Samoan", value: "sm" },
    { label: "Scottish Gaelic", value: "gd" },
    { label: "Serbian", value: "sr" },
    { label: "Shona", value: "sn" },
    { label: "Sindhi", value: "sd" },
    { label: "Sinhala", value: "si" },
    { label: "Slovak", value: "sk" },
    { label: "Slovenian", value: "sl" },
    { label: "Somali", value: "so" },
    { label: "Southern Sotho", value: "st" },
    { label: "Spanish", value: "es" },
    { label: "Sundanese", value: "se" },
    { label: "Swahili", value: "sw" },
    { label: "Swedish", value: "sv" },

    { label: "Tajik", value: "tg" },
    { label: "Tamil", value: "ta" },
    { label: "Telugu", value: "te" },
    { label: "Thai", value: "th" },
    { label: "Turkish", value: "tr" },

    { label: "Ukrainian", value: "uk" },
    { label: "Urdu", value: "ur" },
    { label: "Uzbek", value: "uz" },

    { label: "Vietnamese", value: "vi" },

    { label: "Welsh", value: "cy" },
    { label: "Western Frisian", value: "fy" },

    { label: "Xhosa", value: "xh" },

    { label: "Yiddish", value: "yi" },
    { label: "Yoruba", value: "yo" },

    { label: "Zulu", value: "zu" },
  ],
  locations: [
    { label: "Afghanistan", value: "af" },
    { label: "Albania", value: "al" },
    { label: "Algeria", value: "dz" },
    { label: "Andorra", value: "ad" },
    { label: "Angola", value: "ao" },
    { label: "Anguilla", value: "ai" },
    { label: "Antigua and Barbuda", value: "ag" },
    { label: "Argentina", value: "ar" },
    { label: "Armenia", value: "am" },
    { label: "Australia", value: "au" },
    { label: "Azerbaidjan", value: "az" },

    { label: "Bahamas", value: "bs" },
    { label: "Bahrain", value: "bh" },
    { label: "Bangladesh", value: "bd" },
    { label: "Barbados", value: "bb" },
    { label: "Belarus", value: "by" },
    { label: "Belgium", value: "be" },
    { label: "Belize", value: "bz" },
    { label: "Benin", value: "bj" },
    { label: "Bermuda", value: "bm" },
    { label: "Bhutan", value: "bt" },
    { label: "Bolivia (Plurinational State of)", value: "bo" },
    { label: "Bosnia-Herzegovina", value: "ba" },
    { label: "Botswana", value: "bw" },
    { label: "Brazil", value: "br" },
    { label: "Brunei Darussalam", value: "bn" },
    { label: "Bulgaria", value: "bg" },
    { label: "Burkina Faso", value: "bf" },

    { label: "Cambodia", value: "kh" },
    { label: "Cameroon", value: "cm" },
    { label: "Canada", value: "ca" },
    { label: "Cabo Verde", value: "cv" },
    { label: "Cayman Island", value: "ky" },
    { label: "Chile", value: "cl" },
    { label: "China", value: "cn" },
    { label: "Cocos (Keeling) Islands", value: "cc" },
    { label: "Colombia", value: "co" },
    { label: "Comoros", value: "km" },
    { label: "Congo", value: "cg" },
    { label: "Congo (Democratic Republic of the)", value: "cd" },
    { label: "Costa Rica", value: "cr" },
    { label: "Côte d’Ivoire", value: "ci" },
    { label: "Croatia", value: "hr" },
    { label: "Cuba", value: "cu" },
    { label: "Cyprus", value: "cy" },
    { label: "Czechia", value: "cz" },

    { label: "Denmark", value: "dk" },
    { label: "Djibouti", value: "dj" },
    { label: "Dominica", value: "dm" },
    { label: "Dominican Republic", value: "do" },

    { label: "Ecuador", value: "ec" },
    { label: "Egypt", value: "eg" },
    { label: "El Salvador", value: "sv" },
    { label: "Equatorial Guinea", value: "gq" },
    { label: "Eritrea", value: "er" },
    { label: "Estonia", value: "ee" },
    { label: "Ethiopia", value: "et" },

    { label: "Falkland Islands (Malvinas)", value: "fk" },
    { label: "Faroe Islands", value: "fo" },
    { label: "Fiji", value: "fj" },
    { label: "Finland", value: "fi" },
    { label: "France", value: "fr" },
    { label: "French Polynesia", value: "pf" },

    { label: "Gambia", value: "gm" },
    { label: "Georgia", value: "ge" },
    { label: "Germany", value: "de" },
    { label: "Ghana", value: "gh" },
    { label: "Gibraltar", value: "gi" },
    { label: "Greece", value: "gr" },
    { label: "Greenland", value: "gl" },
    { label: "Grenada", value: "gd" },
    { label: "Guam", value: "gu" },
    { label: "Guatemala", value: "gt" },
    { label: "Guernsey", value: "gg" },
    { label: "Guinea", value: "gn" },
    { label: "Guinea Bissau", value: "gw" },
    { label: "Guyana", value: "gy" },

    { label: "Haiti", value: "ht" },
    { label: "Honduras", value: "hn" },
    { label: "Hong Kong", value: "hk" },
    { label: "Hungary", value: "hu" },

    { label: "Iceland", value: "is" },
    { label: "India", value: "in" },
    { label: "Indonesia", value: "id" },
    { label: "Iran (Islamic Republic of)", value: "ir" },
    { label: "Iraq", value: "iq" },
    { label: "Ireland", value: "ie" },
    { label: "Isle of Man", value: "im" },
    { label: "Israel", value: "il" },
    { label: "Italy", value: "it" },

    { label: "Jamaica", value: "jm" },
    { label: "Japan", value: "jp" },
    { label: "Jersey", value: "je" },
    { label: "Jordan", value: "jo" },

    { label: "Kazakhstan", value: "kz" },
    { label: "Kenya", value: "ke" },
    { label: "Korea (Republic of)", value: "kr" },
    { label: "Kuwait", value: "kw" },
    { label: "Kyrgyzstan", value: "kg" },

    { label: "Lao People’s Democratic Republic", value: "la" },
    { label: "Latvia", value: "lv" },
    { label: "Lebanon", value: "lb" },
    { label: "Lesotho", value: "ls" },
    { label: "Liberia", value: "lr" },
    { label: "Libya", value: "ly" },
    { label: "Liechtenstein", value: "li" },
    { label: "Lithuania", value: "lt" },
    { label: "Luxembourg", value: "lu" },

    { label: "Macao", value: "mo" },
    { label: "Macedonia (the former Yugoslav Republic of)", value: "mk" },
    { label: "Madagascar", value: "mg" },
    { label: "Malawi", value: "mw" },
    { label: "Malaysia", value: "my" },
    { label: "Maldives", value: "mv" },
    { label: "Mali", value: "ml" },
    { label: "Malta", value: "mt" },
    { label: "Mauritania", value: "mr" },
    { label: "Mauritius", value: "mu" },
    { label: "Mexico", value: "mx" },
    { label: "Micronesia (Federated States of)", value: "fm" },
    { label: "Moldova (Moldova (Republic of)", value: "md" },
    { label: "Monaco", value: "mc" },
    { label: "Mongolia", value: "mn" },
    { label: "Montenegro", value: "me" },
    { label: "Morocco", value: "ma" },
    { label: "Mozambique", value: "mz" },
    { label: "Myanmar", value: "mm" },

    { label: "Namibia", value: "na" },
    { label: "Nepal", value: "np" },
    { label: "Netherlands", value: "nl" },
    { label: "New Zealand", value: "nz" },
    { label: "Nicaragua", value: "ni" },
    { label: "Niger", value: "ne" },
    { label: "Nigeria", value: "ng" },
    { label: "Niue", value: "nu" },
    { label: "Northern Mariana Islands", value: "mp" },
    { label: "Norway", value: "no" },

    { label: "Oman", value: "om" },

    { label: "Palestine", value: "ps" },
    { label: "Pakistan", value: "pk" },
    { label: "Panama", value: "pa" },
    { label: "Papua New Guinea", value: "pg" },
    { label: "Paraguay", value: "py" },
    { label: "Peru", value: "pe" },
    { label: "Philippines", value: "ph" },
    { label: "Poland", value: "pl" },
    { label: "Portugal", value: "pt" },
    { label: "Puerto Rico", value: "pr" },

    { label: "Qatar", value: "qa" },

    { label: "Romania", value: "ro" },
    { label: "Russian Federation", value: "ru" },
    { label: "Rwanda", value: "rw" },

    { label: "Saint Helena", value: "sh" },
    { label: "Saint Kitts and Nevis", value: "kn" },
    { label: "Saint Lucia", value: "lc" },
    { label: "Saint Vincent and the Grenadines", value: "vc" },
    { label: "Samoa", value: "ws" },
    { label: "San Marino", value: "sm" },
    { label: "Sao Tome and Principe", value: "st" },
    { label: "Saudi Arabia", value: "sa" },
    { label: "Senegal", value: "sn" },
    { label: "Serbia", value: "rs" },
    { label: "Seychelles", value: "sc" },
    { label: "Sierra Leone", value: "sl" },
    { label: "Singapore", value: "sg" },
    { label: "Slovakia", value: "sk" },
    { label: "Slovenia", value: "si" },
    { label: "Solomon Islands", value: "sb" },
    { label: "Somalia", value: "so" },
    { label: "South Africa", value: "za" },
    { label: "Spain", value: "es" },
    { label: "Sri Lanka", value: "lk" },
    { label: "Sudan", value: "sd" },
    { label: "Suriname", value: "sr" },
    { label: "Swaziland", value: "sz" },
    { label: "Sweden", value: "se" },
    { label: "Switzerland", value: "ch" },
    { label: "Syrian Arab Republic", value: "sy" },

    { label: "Taiwan", value: "tw" },
    { label: "Tajikistan", value: "tj" },
    { label: "Tanzania", value: "tz" },
    { label: "Thailand", value: "th" },
    { label: "Timor-Leste", value: "tl" },
    { label: "Togo", value: "tg" },
    { label: "Tonga", value: "to" },
    { label: "Trinidad and Tobago", value: "tt" },
    { label: "Tunisia", value: "tn" },
    { label: "Turkey", value: "tr" },
    { label: "Tuvalu", value: "tv" },

    { label: "Uganda", value: "ug" },
    { label: "Ukraine", value: "ua" },
    { label: "United Arab Emirates", value: "ae" },
    {
      label: "United Kingdom of Great Britain and Northern Ireland",
      value: "gb",
    },
    { label: "United States of America", value: "us" },
    { label: "Uruguay", value: "uy" },
    { label: "Uzbekistan", value: "uz" },

    { label: "Vanuatu", value: "vu" },
    { label: "Holy See", value: "va" },
    { label: "Venezuela (Bolivarian Republic of)", value: "ve" },
    { label: "Viet Nam", value: "vn" },
    { label: "Virgin Islands (British)", value: "vg" },

    { label: "Yemen", value: "ye" },

    { label: "Zambia", value: "zm" },
    { label: "Zimbabwe", value: "zw" },
  ],
};
