import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { parseFieldError } from "../../util/helpers";
import Icon from "../atoms/Icon";

const TextInput = ({
  required,
  label,
  id,
  name,
  multiline,
  rows,
  disabled,
  type = "text",
  helperText,
  inputAdornment,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const inputError = parseFieldError(name, errors);

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          sx={{
            mb: 3,
          }}
          id={id}
          name={name}
          type={type}
          label={label}
          required={!!required}
          disabled={disabled}
          multiline={multiline}
          rows={rows}
          error={!!inputError}
          helperText={helperText}
          fullWidth
          onWheel={(e) => (type === "number" ? e.target.blur() : null)}
          InputProps={
            inputAdornment && {
              startAdornment: (
                <InputAdornment position="start">
                  <Icon name={inputAdornment} />
                </InputAdornment>
              ),
            }
          }
        />
      )}
    />
  );
};
export default TextInput;
