import React, { useEffect, useState } from "react";
import { Box, Paper, CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ToTopButton from "../../components/molecules/ToTopButton";
import CollapsedDocsChapter from "../../components/organisms/CollapsedDocsChapter";
import PageLayout from "../../components/templates/PageLayout";
import PageHeader from "../../components/templates/PageHeader";
import QueryBuilderLayout from "../../components/templates/QueryBuilderLayout";
import BlogSearchFields from "../../components/templates/builder-fields/BlogSearchFields";
import MarkdownParser from "../../components/templates/MarkdownParser";
import SearchDocumentation from "../../documentation/blog_search.md";
import LiveFeedDocumentation from "../../documentation/blog_livefeed.md";
import Languages from "../../documentation/blog_languages.md";
import Locations from "../../documentation/blog_locations.md";
import Ranking from "../../documentation/blog_ranking.md";
import Sources from "../../documentation/blog_sources.md";
import blogSearchSettings from "../../constants/queryBuilder/blogSearch";
import { getParamFromUrl, delayedScrollIntoView } from "../../util/helpers";
import DocumentationWrap from "../../components/molecules/DocumentationWrap";
import BestPractices from "../../documentation/best_practices.md";
import BlogSearchBestPractices from "../../documentation/blog_search_best_practices.md";
import BlogLivefeedBestPractices from "../../documentation/blog_livefeed_best_practices.md";
import PageTitleUpdater from "../../components/_functional/PageTitleUpdater";

const Wrapper = styled(Box)(() => ({
  background: "#EAEBE0",
  minHeight: "100vh",
}));

const BlogsPage = () => {
  let location = useLocation();

  const tab = getParamFromUrl(location.search, "tab");

  useEffect(() => {
    const scrollTo = document.getElementById(location.hash.replace("#", ""));
    delayedScrollIntoView(scrollTo);
  }, [location.hash]);

  const [loaded, setLoaded] = useState(false);

  return (
    <PageLayout>
      <Wrapper>
        <PageHeader pageHeader="Blogs" />
        {tab === "builder" && (
          <>
            <PageTitleUpdater pageTitle="Blog Search Query Builder" />
            {!loaded && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
              >
                <CircularProgress />
              </Box>
            )}
            <QueryBuilderLayout
              setLoaded={setLoaded}
              loaded={loaded}
              fields={BlogSearchFields}
              settings={blogSearchSettings}
              curlEndpoint="https://api.twingly.com/blog/search/api/v3/search"
              isBlogSearchApi={true}
              apiName="blog_search"
            />
          </>
        )}
        {tab === "search_documentation" && (
          <DocumentationWrap docs={SearchDocumentation}>
            <PageTitleUpdater pageTitle="Blog Search Documentation" />
            <Paper elevation={0} sx={{ p: 3, borderRadius: "8px" }}>
              <MarkdownParser docs={SearchDocumentation} />
            </Paper>
            <CollapsedDocsChapter
              currentHash={location.hash.replace("#", "")}
              name="Languages"
              id="list-languages"
            >
              <MarkdownParser docs={Languages} />
            </CollapsedDocsChapter>
            <CollapsedDocsChapter
              currentHash={location.hash.replace("#", "")}
              name="Locations"
              id="list-locations"
            >
              <MarkdownParser docs={Locations} />
            </CollapsedDocsChapter>
            <CollapsedDocsChapter
              currentHash={location.hash.replace("#", "")}
              name="Best practices"
              id="list-best-practices"
            >
              <MarkdownParser docs={BlogSearchBestPractices + BestPractices} />
            </CollapsedDocsChapter>
            <CollapsedDocsChapter
              currentHash={location.hash.replace("#", "")}
              name="Sources"
              id="list-sources"
            >
              <MarkdownParser docs={Sources} />
            </CollapsedDocsChapter>
            <CollapsedDocsChapter
              currentHash={location.hash.replace("#", "")}
              name="Ranking"
              id="list-ranking"
            >
              <MarkdownParser docs={Ranking} />
            </CollapsedDocsChapter>
            <ToTopButton />
          </DocumentationWrap>
        )}
        {tab === "livefeed_documentation" && (
          <DocumentationWrap docs={LiveFeedDocumentation}>
            <PageTitleUpdater pageTitle="Blog Livefeed Documentation" />
            <Paper elevation={0} sx={{ p: 3, borderRadius: "8px" }}>
              <MarkdownParser docs={LiveFeedDocumentation} />
            </Paper>
            <CollapsedDocsChapter
              currentHash={location.hash.replace("#", "")}
              name="Languages"
              id="list-languages"
            >
              <MarkdownParser docs={Languages} />
            </CollapsedDocsChapter>
            <CollapsedDocsChapter
              currentHash={location.hash.replace("#", "")}
              name="Best practices"
              id="list-best-practices"
            >
              <MarkdownParser
                docs={BlogLivefeedBestPractices + BestPractices}
              />
            </CollapsedDocsChapter>
            <ToTopButton />
          </DocumentationWrap>
        )}
      </Wrapper>
    </PageLayout>
  );
};

export default BlogsPage;
