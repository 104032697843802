const apiMetadata = {
  blog_search: {
    productName: "Blog Search",
    productDataType: "blogs",
    apiEndpoint: "blog_search",
    docPath: "search_documentation",
  },
  blog_livefeed: {
    productName: "Blog LiveFeed",
    productDataType: "blogs",
    apiEndpoint: null,
    docPath: "livefeed_documentation",
  },
  news_b_search: {
    productName: "News Search",
    productDataType: "news",
    apiEndpoint: "news_search",
    docPath: "search_documentation",
  },
  news_b_livefeed: {
    productName: "News LiveFeed",
    productDataType: "news",
    apiEndpoint: null,
    docPath: "livefeed_documentation",
  },
  forum_c_search: {
    productName: "Forum Search",
    productDataType: "forums",
    apiEndpoint: "forum_search",
    docPath: "search_documentation",
  },
  review_c_search: {
    productName: "Review Search",
    productDataType: "reviews",
    apiEndpoint: "review_search",
    docPath: "search_documentation",
  },
  dark_web_a_search: {
    productName: "Dark Web Search",
    productDataType: "dark_web",
    apiEndpoint: "dark_web_search",
    docPath: "search_documentation",
  },
  vk_c_search: {
    productName: "VK Search",
    productDataType: "vk",
    apiEndpoint: "vk_search",
    docPath: "search_documentation",
  },
};

const getApiMetadata = (apiName) => {
  return apiMetadata[apiName];
};

export default getApiMetadata;
