import React from "react";
import { Paper, Box } from "@mui/material";
import MarkdownParser from "../components/templates/MarkdownParser";
import SupportForm from "../components/templates/SupportForm";
import HelpSupport from "../documentation/help_and_support.md";
import PageLayout from "../components/templates/PageLayout";
import PageHeader from "../components/templates/PageHeader";
import PageTitleUpdater from "../components/_functional/PageTitleUpdater";

const HelpAndSupport = () => {
  return (
    <PageLayout>
      <PageHeader pageHeader={"Menu"} pageTabs={false} titleHeader={false} />
      <PageTitleUpdater pageTitle="Help & Support" />

      <Box
        sx={{
          maxWidth: 760,
          m: { xs: 1, md: 3 },
        }}
      >
        <Paper elevation={0} sx={{ mb: 2, p: 3, borderRadius: "8px" }}>
          <MarkdownParser docs={HelpSupport} />
        </Paper>
        <Paper elevation={0} sx={{ mt: 2, p: 3, borderRadius: "8px" }}>
          <h2>Contact us</h2>
          <SupportForm />
        </Paper>
      </Box>
    </PageLayout>
  );
};

export default HelpAndSupport;
