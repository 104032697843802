import React, { useState, useEffect } from "react";
import { Box, Paper, IconButton, Typography } from "@mui/material";
import Icon from "../atoms/Icon";

const CollapsedDocsChapter = ({ name, id, children, currentHash }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    currentHash === id && setOpen(true);
  }, [currentHash]);

  return (
    <Paper elevation={0} sx={{ p: 3, borderRadius: "8px" }}>
      <Box
        onClick={toggleOpen}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          svg: {
            transform: open ? "rotate(90deg)" : "rotate(180deg)",
          },
        }}
      >
        <Typography id={id} fontWeight={700} variant="h5">
          {name}
        </Typography>
        <IconButton>
          <Icon name="arrowRight" />
        </IconButton>
      </Box>
      {open && children}
    </Paper>
  );
};

export default CollapsedDocsChapter;
