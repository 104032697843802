import React from "react";
import { getCookieByName, setCookie } from "../../util/cookieProvider";
import { useLocation } from "react-router-dom";

const AuthRedirect = (props) => {
  const isUserAuthenticated = getCookieByName("twingly_token");
  let location = useLocation();

  if (isUserAuthenticated) {
    return <>{props.children}</>;
  }

  setCookie("twingly_current_path", `${location.pathname}${location.search}`, {
    path: "/",
  });
  window.location.href = "/login";
  return null;
};

export default AuthRedirect;
