import React from "react";
import { Typography, Box } from "@mui/material";
import Icon from "../atoms/Icon";
import features from "../../constants/disabledApiFeatures";

const ApiFeatures = ({ name }) => {
  const cardData = features[name];

  return (
    <>
      <Box
        sx={{
          display: "flex",
          mb: 2,
        }}
        gap={0.5}
      >
        {cardData.features.map((item, index) => (
          <Box
            key={index}
            sx={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              background: "#F5F5F5",
              borderRadius: "8px",
              alignItems: "center",
              py: 1,
              px: 0.5,
            }}
          >
            <Box
              sx={{
                height: 24,
                mb: 0.5,
              }}
            >
              {item.icon && <Icon name={item.icon} />}
              {item.text && (
                <Typography
                  color="textSecondary"
                  fontWeight={600}
                  fontSize={18}
                  letterSpacing="-0.5px"
                >
                  {item.text}
                </Typography>
              )}
            </Box>
            <Box height={36} display="flex" alignItems="center">
              <Typography
                fontSize={12}
                textAlign="center"
                letterSpacing="-0.5px"
                color="textSecondary"
              >
                {item.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default ApiFeatures;
