export default {
  blog_search: [
    {
      label: "Search Text",
      nested: [
        { label: "Match All", key: "all" },
        { label: "Match Any", key: "any" },
        { label: "Match None", key: "none" },
        {
          label: "Search Fields",
          key: "search_within",
        },
      ],
    },
    {
      label: "Search Author",
      nested: [
        { label: "Match All Authors", key: "author_all" },
        { label: "Match Any Authors", key: "author_any" },
        { label: "Exclude Author", key: "exclude_author" },
      ],
    },
    {
      label: "Tags",
      nested: [
        { label: "Match All Tags", key: "tags_all" },
        { label: "Match Any Tags", key: "tags_any" },
        { label: "Exclude Tags", key: "tags_none" },
      ],
    },
    {
      label: "Links",
      nested: [
        { label: "Links to All", key: "link_all", disableIfExists: "link_any" },
        { label: "Links to Any", key: "link_any", disableIfExists: "link_all" },
        { label: "Exclude Links to", key: "link_none" },
      ],
    },
    {
      label: "Search URLs",
      nested: [
        {
          label: "Match URL",
          key: "site_any",
        },
        { label: "Exclude URL", key: "site_none" },
      ],
    },
    {
      label: "Languages",
      key: "languages",
    },
    {
      label: "Locations",
      key: "locations",
    },

    {
      label: "Published Time",
      key: "timestamp",
      keys: ["since", "until"],
    },
    {
      label: "Sort Order",
      key: "order",
      keys: ["sort", "order"],
    },
    {
      label: "Number of Results",
      key: "size",
    },
  ],
  news_b_search: [
    {
      label: "Search Text",
      nested: [
        { label: "Match All", key: "all" },
        { label: "Match Any", key: "any" },
        { label: "Match None", key: "none" },
      ],
    },
    {
      label: "Search in URL",
      key: "url",
    },
    {
      label: "Languages",
      key: "languages",
    },
    {
      label: "Locations",
      key: "locations",
    },
    {
      label: "Time Period",
      key: "timestamp",
      keys: ["since", "until"],
    },
    {
      label: "Site and Article ID's",
      key: "site_article_ids",
      keys: ["site_id", "article_ids"],
    },
    { label: "Number of Results", key: "size" },
    { label: "Number of Readers", key: "article_readership" },
    {
      label: "Article Topics and Entities",
      key: "article_topics_and_entities",
    },
  ],
  forum_c_search: [
    {
      label: "Search Text",
      nested: [
        { label: "Match All", key: "all" },
        { label: "Match Any", key: "any" },
        { label: "Match None", key: "none" },
      ],
    },
    {
      label: "Search Domains",
      key: "domains",
      disableIfExists: "exclude_domains",
    },
    {
      label: "Exclude Domains",
      key: "exclude_domains",
      disableIfExists: "domains",
    },
    {
      label: "Languages",
      key: "languages",
    },
    {
      label: "Locations",
      key: "locations",
    },
    {
      label: "Published Time",
      key: "published_time",
      keys: ["publishedSince", "publishedUntil"],
    },
    {
      label: "Sort Order",
      key: "sort",
      keys: ["sort", "order"],
    },
    { label: "Number of Results", key: "size" },
    { label: "Offset", key: "offset" },
  ],
  review_c_search: [
    {
      label: "Search Text",
      nested: [
        { label: "Match All", key: "all" },
        { label: "Match Any", key: "any" },
        { label: "Match None", key: "none" },
      ],
    },
    {
      label: "Search Domain",
      key: "domain",
      disableIfExists: "exclude_domain",
    },
    {
      label: "Exclude Domain",
      key: "exclude_domain",
      disableIfExists: "domain",
    },
    {
      label: "Item URL",
      key: "item_url",
    },
    {
      label: "Item Name",
      key: "item_name",
    },
    {
      label: "Item Address",
      key: "item_address",
    },
    {
      label: "Languages",
      key: "languages",
    },
    {
      label: "Locations",
      key: "locations",
    },
    {
      label: "Published Time",
      key: "published_time",
      keys: ["publishedSince", "publishedUntil"],
    },
    {
      label: "Sort Order",
      key: "sort",
      keys: ["sort", "order"],
    },
    { label: "Number of Results", key: "size" },
    { label: "Offset", key: "offset" },
  ],
  dark_web_a_search: [
    {
      label: "Search Text",
      key: "search",
    },
    {
      label: "Author",
      key: "author",
    },
    {
      label: "Site (document origin)",
      nested: [
        { label: "Match Site Domain", key: "site_domain" },
        { label: "Match Site Type", key: "site_type" },
        { label: "Match Site Name", key: "site_name" },
      ],
    },
    {
      label: "Languages",
      key: "language",
    },
    {
      label: "Crawled Time",
      key: "crawled",
      keys: ["crawled_since", "crawled_until"],
    },
    {
      label: "Match Network",
      key: "extended_network",
    },
    {
      label: "Match Email Address",
      key: "enriched_email_value",
    },
    {
      label: "Match Credit Card Number",
      key: "enriched_credit_card_value",
    },
    {
      label: "Match Crypto Wallet ID",
      key: "enriched_wallet_id_value",
    },
    {
      label: "Match Organisation Name",
      key: "enriched_organization_value",
    },
    {
      label: "Match Domain",
      key: "enriched_domain_value",
    },
  ],
  vk_c_search: [
    {
      label: "Search Text",
      nested: [
        { label: "Match All", key: "all" },
        { label: "Match Any", key: "any" },
        { label: "Match None", key: "none" },
      ],
    },
    {
      label: "Author ID",
      key: "authors",
    },
    {
      label: "Languages",
      key: "languages",
    },
    {
      label: "Published Time",
      key: "published_time",
      keys: ["publishedSince", "publishedUntil"],
    },
    {
      label: "Event Types",
      key: "event_types",
    },
    {
      label: "Attachement Types",
      key: "attachment_types",
    },
    {
      label: "Post ID",
      key: "posts",
    },
    {
      label: "Post Owner ID",
      key: "post_owners",
    },
    {
      label: "Sort Order",
      key: "sort",
      keys: ["sort", "order"],
    },
    { label: "Number of Results", key: "size" },
    { label: "Offset", key: "offset" },
  ],
};
