import React from "react";

import { Box, Typography, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import PageLayout from "../components/templates/PageLayout";
import PageHeader from "../components/templates/PageHeader";

const HomeWrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 3),
  ".title": {
    flex: "0 0 40%",
  },
  ".value": {
    flex: "1 0 30%",
  },
  ".MuiListItem-root": {
    padding: theme.spacing(1.5, 2),
  },
  [theme.breakpoints.down("lg")]: {
    padding: theme.spacing(2, 1),
  },
  [theme.breakpoints.down("md")]: {
    ".MuiListItem-root": {
      display: "block",
    },
  },
}));

const NotFound = () => {
  return (
    <PageLayout>
      <PageHeader pageHeader={"Menu"} pageTabs={false} titleHeader={false} />
      <HomeWrapper>
        <Typography component="h1" variant="h5" fontWeight={700}>
          404 Not Found
        </Typography>

        <Paper
          elevation={0}
          sx={{
            borderRadius: "8px",
            p: { xs: 1, lg: 4 },
            mt: 4,
            maxWidth: 640,
          }}
        >
          The page you were looking for could not be found.
        </Paper>
      </HomeWrapper>
    </PageLayout>
  );
};

export default NotFound;
