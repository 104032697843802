import React from "react";
import { Box, Typography, Link } from "@mui/material";
import PageTabs from "../templates/PageTabs";
import Icon from "../atoms/Icon";
import ToggleSidebarButton from "../organisms/ToggleSidebarButton";

const PageHeader = ({ pageHeader, pageTabs = true, titleHeader = true }) => {
  return (
    <>
      <Box
        sx={{
          display: { xs: "flex", lg: titleHeader ? "flex" : "none" },
          alignItems: "center",
          px: 0.5,
          pl: { xs: 0.5, lg: 1.5 },
          py: 1,
          background: "white",
        }}
        component="header"
      >
        <ToggleSidebarButton />
        <Typography ml={1} variant="h6">
          {pageHeader}
        </Typography>
        <Link
          href="/"
          sx={{
            ml: "auto",
            height: 32,
            mr: 1.5,
            display: { lg: "none" },
            svg: {
              height: 32,
              width: "auto",
            },
          }}
        >
          <Icon name="logo" />
        </Link>
      </Box>
      {pageTabs ? <PageTabs /> : null}
    </>
  );
};

export default PageHeader;
