import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Tabs, Tab } from "@mui/material";
import { getParamFromUrl } from "../../util/helpers";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PageTabs = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const tab = getParamFromUrl(location.search, "tab");
  const urlQuery = getParamFromUrl(location.search, "query");

  const [value, setValue] = React.useState(tab || "builder");

  const hasLiveFeed =
    location.pathname.includes("blogs") || location.pathname.includes("news");

  useEffect(() => {
    if (!tab) {
      navigate("?tab=builder");
      setValue("builder");
    }
    setValue(tab);
  }, [location]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`?tab=${newValue}${urlQuery ? `&query=${urlQuery}` : ""}`);
  };

  return (
    <Box sx={{ width: "100%", background: "white", display: "flex" }}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="Query Builder" value="builder" {...a11yProps(0)} />
        <Tab
          label="Search API Docs"
          value="search_documentation"
          {...a11yProps(1)}
        />
        {hasLiveFeed && (
          <Tab
            label="LiveFeed API Docs"
            value="livefeed_documentation"
            {...a11yProps(2)}
          />
        )}
      </Tabs>
    </Box>
  );
};

export default PageTabs;
