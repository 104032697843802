import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import request from "../../util/request";
import {
  Button,
  IconButton,
  Box,
  Typography,
  Paper,
  Alert,
} from "@mui/material";
import Icon from "../atoms/Icon";
import TextInput from "../molecules/TextInput";

const ResetPasswordForm = () => {
  let navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState(false);

  const defaultValues = {
    email: "",
  };

  const formMethods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const { control, handleSubmit, reset } = formMethods;

  const onSubmit = (data) => {
    request({
      method: "post",
      url: "/api/v1/user_accounts/reset_password",
      data,
    }).then(() => {
      reset();
      setShowSuccess(true);
    });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        px: { xs: 2, lg: 8 },
        py: 6,
        borderRadius: "16px",
        maxWidth: 440,
      }}
    >
      <Box display="flex" alignItems="center" mb={6}>
        <IconButton onClick={() => navigate("/login")}>
          <Icon name="back" />
        </IconButton>
        <Typography ml={1.5} component="h1" variant="h6">
          Reset Password
        </Typography>
      </Box>
      {showSuccess && (
        <Alert sx={{ mb: 3 }} severity="success">
          The email with the password reset link has been sent to the specified
          email address. Follow the link to set a new password.
        </Alert>
      )}
      {!showSuccess && (
        <>
          <Alert sx={{ mb: 3 }} severity="info">
            Please enter your email address. We will send you an email with the
            password reset link.
          </Alert>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                inputAdornment="account"
                type="email"
                name="email"
                required
                label="Email"
              />
              <Box>
                <Button
                  sx={{ width: "100%" }}
                  color="primary"
                  variant="contained"
                  type="submit"
                  size="large"
                >
                  Reset my Password
                </Button>
              </Box>
            </form>
            <DevTool control={control} />{" "}
            {/* react-hook-form browser dev tool */}
          </FormProvider>
        </>
      )}
    </Paper>
  );
};

export default ResetPasswordForm;
