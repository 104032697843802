import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import Icon from "../atoms/Icon";
import AddRuleButton from "../organisms/AddRuleButton";

const QueryBuilderFormFooter = ({
  queryName,
  rules,
  addRule,
  apiData,
  queryExecuting,
  formFilled,
  openModal,
}) => {
  return (
    <Box
      sx={{
        pt: formFilled ? 2 : 0,
        borderTop: formFilled ? " 1px solid rgba(0, 0, 0, 0.1)" : "none",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <AddRuleButton
        openModal={openModal}
        rules={rules}
        addRule={addRule}
        queryName={queryName}
      />
      <Button
        data-testid="run-query"
        sx={{
          borderRadius: "8px",
          pr: 1.5,
          "&.Mui-disabled": {
            "svg path": {
              fill: "rgba(0, 0, 0, 0.26)",
            },
          },
          svg: {
            ml: queryExecuting ? 0 : 1,
          },
        }}
        disabled={!apiData.enabled}
        variant="contained"
        type="submit"
      >
        Run
        {queryExecuting ? (
          <Box
            component="span"
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: 24,
              height: 24,
              ml: 1,
            }}
          >
            <CircularProgress color="whiteColor" size={20} />
          </Box>
        ) : (
          <Icon name="play" />
        )}
      </Button>
    </Box>
  );
};

export default QueryBuilderFormFooter;
