import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import { Paper } from "@mui/material";
import QueryBuilderFormFooter from "../molecules/QueryBuilderFormFooter";

const QueryBuilderForm = ({
  queryName,
  rules,
  addRule,
  apiData,
  defaultValues,
  handleChange,
  handleSubmitQuery,
  removeRule,
  initialDefaultValues,
  queryExecuting,
  fields: Fields,
}) => {
  const formMethods = useForm({
    reValidateMode: "all",
    defaultValues: defaultValues,
  });

  const { control, watch, resetField, setValue, handleSubmit } = formMethods;

  const ruleExists = !!rules.length;

  const handleRemoveRule = (rulesToRemove) => {
    removeRule(rulesToRemove);
    rulesToRemove.forEach((item) => {
      resetField(item, { defaultValue: initialDefaultValues[item] });
    });
  };

  return (
    <Paper
      sx={{
        boxShadow: "0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
        border: ruleExists ? "4px solid rgba(114, 122, 0, 0.3)" : "",
        p: 2,
        pt: ruleExists ? 3 : 2,
        mx: ruleExists ? -0.5 : 0,
        my: ruleExists ? -0.5 : 0,
        borderRadius: "12px",
      }}
      elevation={0}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(handleSubmitQuery)}>
          {Fields && (
            <Fields
              watch={watch}
              setValue={setValue}
              handleChange={handleChange}
              rules={rules}
              removeRule={handleRemoveRule}
              apiData={apiData}
            />
          )}
          <QueryBuilderFormFooter
            queryName={queryName}
            rules={rules}
            addRule={addRule}
            apiData={apiData}
            queryExecuting={queryExecuting}
            formFilled={ruleExists}
          />
        </form>
      </FormProvider>
      <DevTool control={control} /> {/* react-hook-form browser dev tool */}
    </Paper>
  );
};

export default QueryBuilderForm;
