import React from "react";
import { Button, Tooltip } from "@mui/material";

const QueryTooltipButton = ({ tooltip, title }) => {
  return (
    <Tooltip title={tooltip}>
      <Button
        sx={{ px: 1.5, minWidth: "auto" }}
        size="small"
        variant="outlined"
      >
        {title}
      </Button>
    </Tooltip>
  );
};

export default QueryTooltipButton;
