import React from "react";
import { Box, CircularProgress, Fade } from "@mui/material";

const Progress = ({ isShown, color = "primary", layoutProps, ...props }) => {
  return isShown ? (
    <Fade
      timeout={1000}
      in={isShown}
      style={{
        transitionDelay: isShown ? "800ms" : "0ms",
      }}
      unmountOnExit
    >
      <Box {...props}>
        <Box sx={layoutProps}>
          <CircularProgress color={color} />
        </Box>
      </Box>
    </Fade>
  ) : null;
};

export default Progress;
