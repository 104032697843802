import React from "react";
import { Box, Typography, Link } from "@mui/material";
import FilterMultipleText from "../../molecules/FilterMultipleText";
import FilterTextInput from "../../molecules/FilterTextInput";
import FilterSelectAutocomplete from "../../molecules/FilterSelectAutocomplete";
import FilterToggle from "../../molecules/FilterToggle";
import FilterDateInput from "../../molecules/FilterDateInput";
import DeleteRuleButton from "../../molecules/DeleteRuleButton";
import newsSearchSettings from "../../../constants/queryBuilder/newsSearch";

const NewsSearchFields = ({
  watch,
  rules,
  handleChange,
  removeRule,
  apiData,
}) => {
  const siteIdExists = watch("site_id");

  return (
    <>
      {rules.includes("all") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="all"
              label="Match All"
            />
          </Box>
          <DeleteRuleButton removeRule={removeRule} rulesToRemove={["all"]} />
        </Box>
      )}
      {rules.includes("any") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="any"
              label="Match Any"
            />
          </Box>
          <DeleteRuleButton removeRule={removeRule} rulesToRemove={["any"]} />
        </Box>
      )}
      {rules.includes("none") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterMultipleText
              autoFocus
              handleInputChange={handleChange}
              name="none"
              label="Match None"
            />
          </Box>
          <DeleteRuleButton removeRule={removeRule} rulesToRemove={["none"]} />
        </Box>
      )}
      {rules.includes("url") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterTextInput
              autoFocus
              handleInputChange={handleChange}
              name="url"
              label="Search in URL"
            />
          </Box>
          <DeleteRuleButton removeRule={removeRule} rulesToRemove={["url"]} />
        </Box>
      )}
      {rules.includes("languages") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterSelectAutocomplete
              autoFocus
              handleInputChange={handleChange}
              options={newsSearchSettings.languages}
              name="languages"
              label="Languages"
              enabledOptions={apiData.enabledLanguages}
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["languages"]}
          />
        </Box>
      )}
      {rules.includes("locations") && (
        <Box display="flex" alignItems="flex-start">
          <Box flex="1">
            <FilterSelectAutocomplete
              autoFocus
              handleInputChange={handleChange}
              options={newsSearchSettings.locations}
              name="locations"
              label="Locations"
              enabledOptions={apiData.enabledLocations}
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["locations"]}
          />
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Box
          gap={{ xs: 0, md: 2 }}
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          {rules.includes("since") && (
            <Box sx={{ flex: { md: "0 1 240px" } }}>
              <FilterDateInput
                handleInputChange={handleChange}
                label="Results Since"
                name="since"
              />
            </Box>
          )}
          {rules.includes("until") && (
            <Box sx={{ flex: { md: "0 1 240px" } }}>
              <FilterDateInput
                handleInputChange={handleChange}
                minDate={new Date(watch("since"))}
                label="Results Until"
                name="until"
              />
            </Box>
          )}
        </Box>
        {(rules.includes("since") || rules.includes("until")) && (
          <DeleteRuleButton
            forTwoFields
            removeRule={removeRule}
            rulesToRemove={["since", "until"]}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "center", md: "flex-start" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: { xs: "column", md: "row" },
          }}
          gap={{ xs: 0, md: 2 }}
        >
          {(rules.includes("site_id") || rules.includes("article_ids")) && (
            <Box sx={{ flex: 1 }}>
              <FilterTextInput
                autoFocus
                type="number"
                handleInputChange={handleChange}
                label="Site id"
                name="site_id"
              />
            </Box>
          )}
          {(rules.includes("site_id") || rules.includes("article_ids")) && (
            <Box sx={{ flex: 4 }}>
              <FilterMultipleText
                handleInputChange={handleChange}
                disabled={!siteIdExists}
                type="number"
                label="Article id"
                name="article_ids"
              />
            </Box>
          )}
        </Box>
        {(rules.includes("site_id") || rules.includes("article_ids")) && (
          <DeleteRuleButton
            forTwoFields
            removeRule={removeRule}
            rulesToRemove={["site_id", "article_ids"]}
          />
        )}
      </Box>
      <Box
        gap={{ xs: 0, md: 2 }}
        sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
      >
        {rules.includes("article_readership") && (
          <Box
            display="flex"
            alignItems="flex-start"
            flexBasis={{ xs: 1, md: "calc(50% - 8px)" }}
          >
            <FilterToggle
              handleInputChange={handleChange}
              name="article_readership"
              label="Number of Readers"
              hintText={
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    a: {
                      color: "#becc00",
                    },
                  }}
                  color="inherit"
                >
                  The Readership feature must be enabled for your API key for
                  this parameter to work. Contact{" "}
                  <Link href={"mailto:sales@twingly.com"}>
                    sales@twingly.com
                  </Link>{" "}
                  to learn more.
                </Typography>
              }
            />
            <Box>
              <DeleteRuleButton
                removeRule={removeRule}
                rulesToRemove={["article_readership"]}
                sx={{ mt: 2.25 }}
              />
            </Box>
          </Box>
        )}
        {rules.includes("article_topics_and_entities") && (
          <Box
            display="flex"
            alignItems="flex-start"
            flexBasis={{ xs: 1, md: "calc(50% - 8px)" }}
          >
            <FilterToggle
              handleInputChange={handleChange}
              name="article_topics_and_entities"
              label="Article Topics and Entities"
              hintText={
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    a: {
                      color: "#becc00",
                    },
                  }}
                  color="inherit"
                >
                  The topics and entities feature must be enabled for your API
                  key for this parameter to work. Contact{" "}
                  <Link href={"mailto:sales@twingly.com"}>
                    sales@twingly.com
                  </Link>{" "}
                  to learn more.
                </Typography>
              }
            />
            <Box>
              <DeleteRuleButton
                removeRule={removeRule}
                rulesToRemove={["article_topics_and_entities"]}
                sx={{ mt: 2.25 }}
              />
            </Box>
          </Box>
        )}
      </Box>
      {rules.includes("size") && (
        <Box display="flex" alignItems="flex-start">
          <Box sx={{ width: 130, pt: "9px" }}>
            <FilterTextInput
              autoFocus
              handleInputChange={handleChange}
              name="size"
              type="number"
              label="Number of Results"
              maxNumberInput={250}
            />
          </Box>
          <DeleteRuleButton
            removeRule={removeRule}
            rulesToRemove={["size"]}
            sx={{ mt: 2.25 }}
          />
        </Box>
      )}
    </>
  );
};

export default NewsSearchFields;
