import React from "react";
import {
  Box,
  Link,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  Button,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/esm/languages/hljs/bash";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import python from "react-syntax-highlighter/dist/esm/languages/hljs/python";
import ruby from "react-syntax-highlighter/dist/esm/languages/hljs/ruby";
import shell from "react-syntax-highlighter/dist/esm/languages/hljs/shell";
import { atelierEstuaryLight } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import { generateId } from "../../util/helpers";

SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("json", json);
SyntaxHighlighter.registerLanguage("python", python);
SyntaxHighlighter.registerLanguage("ruby", ruby);
SyntaxHighlighter.registerLanguage("shell", shell);

const Wrapper = styled(Box)(({ theme }) => ({
  ".inline-code": {
    wordWrap: "break-word",
    color: "rgb(125, 151, 38)",
    padding: "2px 4px",
    backgroundColor: "rgb(244, 243, 236)",
    borderRadius: "4px",
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
      overflow: "auto",
      display: "inline-flex",
    },
  },
  code: {
    fontSize: 14,
  },
  ".hidden": {
    display: "none",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd):not(:only-child)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const MarkdownParser = ({ docs }) => {
  const handleToggleVisibility = () => {
    const target = document.getElementById("visibility-target");

    target.style.display === "none"
      ? (target.style.display = "block")
      : (target.style.display = "none");
  };

  return (
    <Wrapper>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        components={{
          h1: (props) => {
            const id = generateId(props.children);
            return <h1 id={id}>{props.children}</h1>;
          },
          h2: (props) => {
            const id = generateId(props.children);
            return <h2 id={id}>{props.children}</h2>;
          },
          h3: (props) => {
            const id = generateId(props.children);

            return <h3 id={id}>{props.children}</h3>;
          },
          h4: (props) => {
            const id = generateId(props.children);
            return <h4 id={id}>{props.children}</h4>;
          },
          h5: (props) => {
            const id = generateId(props.children);
            return <h5 id={id}>{props.children}</h5>;
          },
          h6: (props) => {
            const id = generateId(props.children);
            return <h6 id={id}>{props.children}</h6>;
          },
          a: (props) => {
            if (props.href === "#toggle-button") {
              return (
                <Button
                  onClick={handleToggleVisibility}
                  id="toggle-button"
                  variant="contained"
                >
                  {props.children}
                </Button>
              );
            } else if (props.href.startsWith("#")) {
              return (
                <Link underline="hover" href={props.href}>
                  {props.children}
                </Link>
              );
            } else if (
              props.href.startsWith("./") ||
              props.href.startsWith("../")
            ) {
              return (
                <Link
                  style={{ underline: "hover" }}
                  component={RouterLink}
                  to={props.href}
                >
                  {props.children}
                </Link>
              );
            } else {
              return (
                <Link target="_blank" underline="hover" href={props.href}>
                  {props.children}
                </Link>
              );
            }
          },
          hr: () => {
            return <Divider sx={{ my: 3 }} />;
          },
          table: (props) => {
            const dense = props.id === "dense";
            return (
              <Box sx={{ overflow: "auto" }}>
                <Table size={dense ? "small" : "medium"}>
                  {props.children}
                </Table>
              </Box>
            );
          },
          thead: (props) => {
            return <TableHead>{props.children}</TableHead>;
          },
          tbody: (props) => {
            return <TableBody>{props.children}</TableBody>;
          },
          td: (props) => {
            return <TableCell>{props.children}</TableCell>;
          },
          th: (props) => {
            return <TableCell>{props.children}</TableCell>;
          },
          tr: (props) => {
            return <StyledTableRow>{props.children}</StyledTableRow>;
          },
          code: ({ inline, className, children, ...props }) => {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <SyntaxHighlighter
                style={atelierEstuaryLight}
                language={match[1]}
                {...props}
              >
                {String(children).replace(/\n$/, "")}
              </SyntaxHighlighter>
            ) : (
              <code
                className={`${className ? className : ""} inline-code`}
                {...props}
              >
                {children}
              </code>
            );
          },
        }}
      >
        {docs}
      </ReactMarkdown>
    </Wrapper>
  );
};

export default MarkdownParser;
