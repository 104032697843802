import React, { useState } from "react";
import {
  Paper,
  Box,
  Typography,
  Collapse,
  IconButton,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import Icon from "../atoms/Icon";
import Progress from "../atoms/Progress";
import ResultsPreview from "./ResultsPreview";
import ResultsNoAccess from "../molecules/ResultsNoAccess";
import ResultsNoResults from "../molecules/ResultsNoResults";
import ResultsError from "../molecules/ResultsError";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import { atelierEstuaryLight } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import Papa from "papaparse";

SyntaxHighlighter.registerLanguage("json", json);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const QueryResults = ({
  apiData,
  setApiData,
  rawResults,
  queryExecuted,
  resultId,
  error,
  apiName,
  queryExecuting,
}) => {
  const [open, setOpen] = useState(true);
  const [tab, setTab] = useState("preview");

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };

  const ResultsView = () => {
    return error ? (
      <ResultsError error={error} />
    ) : !apiData.enabled ? (
      <ResultsNoAccess apiName={apiName} setApiData={setApiData} />
    ) : !queryExecuted ? (
      <ResultsNoResults />
    ) : null;
  };

  const handleCsv = () => {
    const sourceJson = rawResults.documents;

    sourceJson.map((item) => {
      Object.entries(item).map(([key, value]) => {
        if (typeof value === "object") {
          return (item[key] = JSON.stringify(value));
        }
      });
    });

    const csv = Papa.unparse(sourceJson);

    const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvURL = window.URL.createObjectURL(csvData);

    const tempLink = document.createElement("a");
    tempLink.href = csvURL;
    tempLink.setAttribute("download", "download.csv");
    tempLink.click();
  };

  return (
    <Paper
      sx={{
        py: 0.5,
        pb: open ? 0 : 0.5,
        borderRadius: 2.5,
        overflow: "hidden",
        position: "relative",
      }}
      elevation={0}
      id={resultId}
    >
      {queryExecuting && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            background: "rgba(0, 0, 0, 0.05)",
          }}
        >
          <Progress
            sx={{
              zIndex: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            isShown
          />
        </Box>
      )}

      <Box
        sx={{
          pl: 2,
          pr: 1.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          svg: {
            transform: open ? "rotate(90deg)" : "rotate(180deg)",
          },
        }}
      >
        <Typography variant="h6">Results</Typography>
        <IconButton onClick={toggleOpen}>
          <Icon name="arrowRight" />
        </IconButton>
      </Box>
      <Collapse in={open}>
        <Box
          sx={{
            width: "100%",
            background: "white",
            borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
          }}
        >
          <Tabs value={tab} onChange={handleChange} aria-label="results-tabs">
            <Tab
              sx={{ width: { xs: "auto", lg: 160 } }}
              label="Preview"
              value="preview"
              {...a11yProps(0)}
            />

            <Tab
              sx={{ width: { xs: "auto", lg: 160 } }}
              label="JSON"
              value="json"
              data-testid="json-results-tab"
              {...a11yProps(1)}
            />

            <Tab
              sx={{ width: { xs: "auto", lg: 160 } }}
              label="CSV"
              value="csv"
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <ResultsView />
        {tab === "preview" && (
          <ResultsPreview
            queryExecuted={queryExecuted}
            rawResults={rawResults}
            apiName={apiName}
          />
        )}
        {tab === "json" && (
          <>
            {queryExecuted && (
              <Box
                sx={{
                  py: 3,
                  px: 2,
                  fontSize: 14,
                  pre: {
                    margin: 0,
                  },
                }}
              >
                <SyntaxHighlighter
                  id="json-block"
                  data-testid="json-results"
                  language="json"
                  style={atelierEstuaryLight}
                >
                  {JSON.stringify(rawResults, null, 2)}
                </SyntaxHighlighter>
              </Box>
            )}
          </>
        )}
        {tab === "csv" && (
          <>
            {queryExecuted && (
              <Box px={2} py={3}>
                <Button variant="outlined" onClick={handleCsv}>
                  Download
                </Button>
              </Box>
            )}
          </>
        )}
      </Collapse>
    </Paper>
  );
};

export default QueryResults;
