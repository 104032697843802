import React from "react";
import { Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import RegisterForm from "../components/templates/RegisterForm";
import AuthPageLayout from "../components/templates/AuthPageLayout";
import PageTitleUpdater from "../components/_functional/PageTitleUpdater";

const Register = () => {
  return (
    <AuthPageLayout form={RegisterForm}>
      <PageTitleUpdater pageTitle="Register" />
      <Typography
        fontWeight={700}
        component="h2"
        variant="h2"
        color="white"
        mb={4}
      >
        Social Data With Passion
      </Typography>
      <Typography mb={4} variant="h6" color="white">
        Are you a blogger? No need to Sign Up, just
        <br />
        <Link
          color="#bfcc01"
          to="/public-docs/ping"
          underline="none"
          component={RouterLink}
        >
          Submit Your Blog
        </Link>
        {" or "}
        <Link
          color="#bfcc01"
          to="/public-docs"
          underline="none"
          component={RouterLink}
        >
          view the docs for our public services
        </Link>
        .
      </Typography>
      <Typography variant="h6" color="white">
        Once signed up you will get an API key,
        <br />
        <Link
          color="#bfcc01"
          href="https://www.twingly.com/"
          target="_blank"
          underline="none"
        >
          Read More about Our Data
        </Link>
        .
      </Typography>
    </AuthPageLayout>
  );
};

export default Register;
