import React, { createContext, useReducer } from "react";

const initialState = {
  sidebarOpen: false,
};

const reducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case "HIDE_SIDEBAR":
      return { ...state, sidebarOpen: false };
    case "SHOW_SIDEBAR":
      return { ...state, sidebarOpen: true };
    default:
      return state;
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => null,
});

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
