import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import EnabledAPICard from "../organisms/EnabledAPICard";
import DisabledAPICard from "../organisms/DisabledAPICard";

const DashboardCards = ({
  activeApis,
  setActiveApis,
  disabledApis,
  setDisabledApis,
}) => {
  return (
    <>
      {!!activeApis.length && (
        <Box mb={2}>
          <Typography mb={2}>Your Active APIs</Typography>
          <Grid container spacing={2}>
            {activeApis.map((item) => (
              <EnabledAPICard key={item.apiName} item={item} />
            ))}
          </Grid>
        </Box>
      )}
      {!!disabledApis.length && (
        <Box>
          <Typography mb={2}>Available APIs</Typography>
          <Grid container spacing={2}>
            {disabledApis.map((item) => (
              <DisabledAPICard
                key={item.apiName}
                item={item}
                setActiveApis={setActiveApis}
                setDisabledApis={setDisabledApis}
              />
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default DashboardCards;
