import React, { useEffect } from "react";

const PageTitleUpdater = (props) => {
  useEffect(() => {
    document.title = `${props.pageTitle} - Twingly`;
  });

  return <>{props.children}</>;
};

export default PageTitleUpdater;
