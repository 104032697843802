import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  Collapse,
  IconButton,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import Icon from "../atoms/Icon";
import CopyCodeButton from "../molecules/CopyCodeButton";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import json from "react-syntax-highlighter/dist/esm/languages/hljs/json";
import { atelierEstuaryLight } from "react-syntax-highlighter/dist/cjs/styles/hljs";
import {
  generateCurl,
  generateValues,
  generateRawQueryLanguage,
} from "../../util/helpers";
import { getCookieByName } from "../../util/cookieProvider";

SyntaxHighlighter.registerLanguage("json", json);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const RawQuery = ({
  formValues,
  rules,
  querySettings,
  curlEndpoint,
  isBlogSearchApi,
}) => {
  const [open, setOpen] = useState(false);
  const [showFullJson, setShowFullJson] = useState(false);
  const [showFullJsonButton, setShowFullJsonButton] = useState(false);
  const [tab, setTab] = useState(isBlogSearchApi ? "curl" : "json");
  const [rawQueryJson, setRawQueryJson] = useState("");
  const [rawQueryCurl, setRawQueryCurl] = useState("");

  const apiKey = getCookieByName("api_key");

  useEffect(() => {
    if (isBlogSearchApi) {
      const queryLanguageRaw = generateRawQueryLanguage(formValues);
      setRawQueryCurl(encodeURI(queryLanguageRaw));
    } else {
      const newValues = generateValues(formValues, rules, querySettings);
      setRawQueryCurl(JSON.stringify(newValues));
      setRawQueryJson(JSON.stringify(newValues, null, 2));
    }
  }, [formValues, rules]);

  useLayoutEffect(() => {
    const jsonBlock = document.getElementById("json-block");
    jsonBlock && jsonBlock.offsetHeight > 200
      ? setShowFullJsonButton(true)
      : setShowFullJsonButton(false);
  });

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  const toggleOpen = () => {
    setOpen(!open);
  };
  const toggleOpenJson = () => {
    setShowFullJson(!showFullJson);
  };

  const handleCopyJson = () => {
    navigator.clipboard.writeText(rawQueryJson);
  };

  const curl = generateCurl(
    apiKey,
    rawQueryCurl.trim(),
    curlEndpoint,
    isBlogSearchApi
  );

  const handleCopyCurl = () => {
    navigator.clipboard.writeText(curl);
  };

  return (
    <Paper
      sx={{
        py: 0.5,
        borderRadius: 2.5,
      }}
      elevation={0}
    >
      <Box
        sx={{
          pl: 2,
          pr: 1.5,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          svg: {
            transform: open ? "rotate(90deg)" : "rotate(180deg)",
          },
        }}
      >
        <Typography variant="h6">Raw Query</Typography>
        <IconButton data-testid="toggle-raw-query" onClick={toggleOpen}>
          <Icon name="arrowRight" />
        </IconButton>
      </Box>
      <Collapse in={open}>
        <Box
          sx={{
            width: "100%",
            background: "white",
            borderBottom: "1px solid rgba(0, 0, 0, 0.08)",
          }}
        >
          <Tabs
            value={tab}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {!isBlogSearchApi && (
              <Tab
                sx={{ width: { xs: "auto", lg: 160 } }}
                label="JSON"
                data-testid="json-raw-tab"
                value="json"
                {...a11yProps(0)}
              />
            )}

            <Tab
              sx={{ width: { xs: "auto", lg: 160 } }}
              label="CURL"
              data-testid="curl-raw-tab"
              value="curl"
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        {tab === "json" && (
          <Box
            sx={{
              py: 3,
              px: 2,
              fontSize: 14,
              pre: {
                margin: 0,
              },
            }}
          >
            <Box
              sx={{
                maxHeight: showFullJson ? "auto" : 200,
                overflow: "hidden",
              }}
            >
              <SyntaxHighlighter
                id="json-block"
                data-testid="json-block"
                language="json"
                style={atelierEstuaryLight}
              >
                {rawQueryJson}
              </SyntaxHighlighter>
            </Box>
            {showFullJsonButton && (
              <Box>
                <Button onClick={toggleOpenJson}>
                  {showFullJson ? "Hide Full Code" : "Show Full Code"}
                </Button>
              </Box>
            )}
            <CopyCodeButton handleCopy={handleCopyJson} title="Copy Code" />
          </Box>
        )}
        {tab === "curl" && (
          <Box
            sx={{
              py: 3,
              px: 2,
              fontSize: 14,
              pre: {
                margin: 0,
                code: {
                  whiteSpace: isBlogSearchApi ? "initial!important" : "inherit",
                },
              },
            }}
          >
            <Box>
              <SyntaxHighlighter
                data-testid="curl-block"
                language="curl"
                style={atelierEstuaryLight}
              >
                {curl}
              </SyntaxHighlighter>
            </Box>
            <CopyCodeButton handleCopy={handleCopyCurl} title="Copy Code" />
          </Box>
        )}
      </Collapse>
    </Paper>
  );
};

export default RawQuery;
