import React from "react";
import { Typography, Box } from "@mui/material";
import Icon from "../atoms/Icon";
import { styled } from "@mui/material/styles";
import getApiMetadata from "../../constants/nameConstants";

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(3),
  "svg > *": {
    fill: "#000",
  },
}));

const ApiCardHeader = ({ name }) => {
  return (
    <StyledHeader>
      <Icon name={name} />
      <Typography ml={1} variant="h6">
        {getApiMetadata(name).productName}
      </Typography>
    </StyledHeader>
  );
};

export default ApiCardHeader;
