class SearchResult {
  constructor({
    author,
    date,
    from,
    fromUrl,
    id,
    idLabel,
    language,
    location,
    summary,
    text,
    title,
    titleLink,
    type,
  }) {
    this.author = author;
    this.date = date;
    this.from = from;
    this.fromUrl = fromUrl;
    this.id = id;
    this.idLabel = idLabel;
    this.language = language;
    this.location = location;
    this.summary = summary;
    this.text = text;
    this.title = title;
    this.titleLink = titleLink;
    this.type = type;
  }
}

const formatBlogSearchResults = (item) => {
  return new SearchResult({
    author: item.author,
    date: item.published_at,
    from: item.blog_name,
    fromUrl: item.blog_url,
    id: item.id,
    idLabel: "ID",
    language: item.language_code,
    location: item.location_code,
    text: item.text,
    title: item.title || "Untitled",
    titleLink: item.url,
  });
};

const formatNewsBSearchhResults = (item) => {
  return new SearchResult({
    author: item.author,
    date: item.timestamp,
    from: item.site_name,
    fromUrl: item.site_url,
    id: item.article_id,
    idLabel: "Article ID",
    language: item.language_code,
    location: item.location_code,
    summary: item.summary,
    text: item.text,
    title: item.title || "Untitled",
    titleLink: item.url,
  });
};

const formatForumCSearchhResults = (item) => {
  return new SearchResult({
    author: item.author_name,
    date: item.post_published_at,
    from: item.site_title,
    fromUrl: item.site_url,
    id: item.post_id,
    idLabel: "Post ID",
    language: item.post_language,
    location: item.forum_country,
    text: item.post_text,
    title: item.post_title || "Untitled",
    titleLink: item.post_url_with_anchor,
  });
};

const formatVkCSearchResults = (item) => {
  return new SearchResult({
    date: item.published_at,
    id: item.id,
    idLabel: "ID",
    language: item.language,
    text: item.text,
    title: item.title || "Untitled",
    titleLink: item.url,
    type: item.type,
    fromUrl: item.url,
  });
};

const formatDarkWebASearchResults = (item) => {
  return new SearchResult({
    author: item.authors,
    date: item.published_at,
    from: item.site_name || item.site_domain,
    fromUrl: item.thread_url || item.url,
    id: item.uuid,
    idLabel: "UUID",
    language: item.language,
    text: item.text,
    title: item.title || item.thread_title || "Untitled",
    titleLink: item.url,
    type: item.site_type,
  });
};

const formatReviewCSearchResults = (item) => {
  return new SearchResult({
    author: item.author_username,
    date: item.review_published_at,
    from: item.site_name,
    fromUrl: item.site_url,
    id: item.review_id,
    idLabel: "Review ID",
    language: item.review_language,
    location: item.site_country,
    text: item.review_text,
    title: item.review_title || item.item_name || "Untitled",
    titleLink: item.review_url,
  });
};

const formatResultBasedOnApi = (apiName, item) => {
  switch (apiName) {
    case "blog_search":
      return formatBlogSearchResults(item);
    case "news_b_search":
      return formatNewsBSearchhResults(item);
    case "forum_c_search":
      return formatForumCSearchhResults(item);
    case "vk_c_search":
      return formatVkCSearchResults(item);
    case "dark_web_a_search":
      return formatDarkWebASearchResults(item);
    case "review_c_search":
      return formatReviewCSearchResults(item);
  }
};

export default formatResultBasedOnApi;
