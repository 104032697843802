import React from "react";
import { TextField, Box } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { localToUTC, makeLocalAppearUTC } from "../../util/helpers";

const StyledTextField = styled(TextField)(() => ({
  ".MuiInputLabel-root": {
    fontWeight: 500,
    color: "rgba(0, 0, 0, 0.87)",
  },
  ".MuiOutlinedInput-input": {
    fontSize: 14,
  },

  ".MuiOutlinedInput-root": {
    borderRadius: 12,
  },

  "&:not(.solo)": {
    ".MuiOutlinedInput-root": {
      "&:not(.Mui-focused):not(:hover) .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent",
      },
    },
  },
}));

const FilterDateInput = ({
  handleInputChange,
  required,
  label,
  name,
  minDate,
  soloInput,
  timestamp,
}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: required,
      }}
      render={({ field: { onChange, ...field } }) => {
        return (
          <Box data-testid={`${name}-input`}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <MobileDateTimePicker
                {...field}
                className={soloInput ? "solo" : ""}
                value={field.value ? makeLocalAppearUTC(field.value) : null}
                onChange={(newValue) => {
                  if (newValue) {
                    onChange(localToUTC(newValue));
                    handleInputChange(name, localToUTC(newValue));
                  } else {
                    onChange(null);
                    handleInputChange(name, null);
                  }
                }}
                label={label}
                ampm={false}
                showToolbar={false}
                hideTabs={false}
                minDate={minDate}
                inputFormat={
                  timestamp ? "T" : "yyyy-MM-dd, 'T'HH:mm:ss, 'UTC0'"
                }
                views={["year", "month", "day", "hours"]}
                renderInput={(params) => (
                  <StyledTextField
                    sx={{ mb: 2, fontSize: 14 }}
                    fullWidth
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
        );
      }}
    />
  );
};
export default FilterDateInput;
