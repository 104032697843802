import React from "react";
import { Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import LoginForm from "../components/templates/LoginForm";
import AuthPageLayout from "../components/templates/AuthPageLayout";
import PageTitleUpdater from "../components/_functional/PageTitleUpdater";

const Login = () => {
  return (
    <AuthPageLayout form={LoginForm}>
      <PageTitleUpdater pageTitle="Login" />
      <Typography fontWeight={700} component="h2" variant="h2" color="white">
        Social Data With Passion
      </Typography>
      <Typography mb={4} variant="h6" color="white">
        Are you a blogger? No need to Log In, just
        <br />
        <Link
          color="#bfcc01"
          to="/public-docs/ping"
          underline="none"
          component={RouterLink}
        >
          Submit Your Blog
        </Link>
        {" or "}
        <Link
          color="#bfcc01"
          to="/public-docs"
          underline="none"
          component={RouterLink}
        >
          view the docs for our public services
        </Link>
        .
      </Typography>
    </AuthPageLayout>
  );
};

export default Login;
