import React from "react";
import { Paper, Box, Typography } from "@mui/material";
import PageLayout from "../components/templates/PageLayout";
import ContactSalesForm from "../components/templates/ContactSalesForm";
import PageHeader from "../components/templates/PageHeader";
import PageTitleUpdater from "../components/_functional/PageTitleUpdater";

const ContactSales = () => {
  return (
    <PageLayout>
      <PageHeader pageHeader={"Menu"} pageTabs={false} titleHeader={false} />
      <PageTitleUpdater pageTitle="Contact Sales" />

      <Box
        sx={{
          maxWidth: 760,
          m: { xs: 1, md: 3 },
        }}
      >
        <Paper elevation={0} sx={{ mt: 2, p: 3, borderRadius: "8px" }}>
          <h2>Contact Sales</h2>
          <Typography sx={{ mb: 3 }} paragraph>
            Please contact us if you have any questions or if you would like
            more information about our products or services.
          </Typography>
          <ContactSalesForm />
        </Paper>
      </Box>
    </PageLayout>
  );
};

export default ContactSales;
