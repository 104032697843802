import React from "react";
import { Typography } from "@mui/material";
import ResetPasswordForm from "../components/templates/ResetPasswordForm";
import AuthPageLayout from "../components/templates/AuthPageLayout";
import PageTitleUpdater from "../components/_functional/PageTitleUpdater";

const ResetPassword = () => {
  return (
    <AuthPageLayout form={ResetPasswordForm}>
      <PageTitleUpdater pageTitle="Reset Password" />
      <Typography fontWeight={700} component="h2" variant="h2" color="white">
        Social Data With Passion
      </Typography>
    </AuthPageLayout>
  );
};

export default ResetPassword;
